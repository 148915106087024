import React from 'react'
import { Field } from 'redux-form';

export const SmartSelect = (props) => {
    const {
        input: { name },
        label,
        type,
        className,
        options,
        column,
        row,
        cssFix
    } = props;
    
    return (
        <div className="submit-field">
            <h5>{label}</h5>
            <div className={`account-type ${typeof cssFix !==  'undefined' ? cssFix : ''}`}>
                {options.map((radioData, index) => {
                    return (
                        <div>
                            <Field
                                id={radioData.value}
                                component="input"
                                name={name}
                                type={type}
                                value={radioData.value}
                                className={className}
                            />
                            <label key={index} htmlFor={radioData.value} className="ripple-effect-dark">
                                <i className={radioData.icon} /> {radioData.label}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SmartSelect
