import React, { useEffect, Fragment } from 'react'
import { Link } from 'gatsby'
import { selectReceiverProfile } from '../../reducers/interest'
import Modal from '../../components/common/Modal'
import UserProfilesForModel from './UserProfilesForModel'
import StartNew from '../../components/common/StartNew'
import PhotoGallery from '../../components/common/PhotoGallery'
import { fetchCountry } from '../../lib/resolver'
import PopUpNotification from '../../components/common/PopUpNotification'
import moment from 'moment'
import CountryWithFlag from '../../components/common/CountryWithFlag'
import { useShortlist, useRequestAndInterest } from '../../hooks/useHooks'
import Loading from '../../components/common/Loading'

const List = profile => {
    const {
        dispatch,
        createOrDeleteShortlist,
        id,
        reference,
        avatar,
        created_at,
        basic_information,
        status_properties,
        number_of_photos,
        is_horoscope_added,
        selectReceiverProfileHandler
    } = profile;
    return (
        <li>
            <div className="freelancer-overview manage-candidates">
                <div className="freelancer-overview-inner">
                    <div>
                        <PhotoGallery profile={profile} profilePhotoUrl={avatar} />
                    </div>
                    <div className="freelancer-name">
                        <h3>
                            <Link to={`/app/profile/${id}/${reference}`}>
                                {basic_information.first_name} {basic_information.last_name} |{' '}
                                {reference} <CountryWithFlag {...basic_information} />
                            </Link>
                        </h3>
                        <span className="freelancer-detail-item">
                            Creaated by {basic_information.profile_created_by}{' '}
                            {moment(created_at).fromNow()}
                        </span>
                        <br />
                        <span className="freelancer-detail-item">
                            {basic_information.gender === 'male' ? (
                                <Fragment>
                                    <i className="icon-line-awesome-male" /> Male
                                </Fragment>
                            ) : (
                                    <Fragment>
                                        <i className="icon-line-awesome-female" /> Female
                                    </Fragment>
                                )}
                        </span>
                        <span className="freelancer-detail-item">
                            <i className="icon-material-outline-business" />{' '}
                            {basic_information.living_city}
                        </span>
                        <span className="freelancer-detail-item">
                            <i className="icon-material-outline-business" />{' '}
                            {fetchCountry(basic_information.living_country).name}
                        </span>
                        <br />
                        <span className="freelancer-detail-item">
                            <span className="badge info">
                                <i className="icon-feather-image" /> {number_of_photos}{' '}
                                {number_of_photos > 1 ? 'Photos' : 'Photo'} Available{' '}
                            </span>
                        </span>
                        {is_horoscope_added === 'true' && (
                            <span className="freelancer-detail-item">
                                <span className="badge info">
                                    <i className="icon-material-baseline-star-border" /> Horoscope
                                    Available
                                </span>
                            </span>
                        )}
                        <span className={`badge ${status_properties.badge}`}>
                            <i className={status_properties.icon} />{' '}
                            {status_properties.message}
                        </span>
                        <div className="buttons-to-right always-visible margin-top-10 margin-bottom-5">
                            <button
                                className="button ripple-effect"
                                onClick={event => {
                                    event.preventDefault()
                                    selectReceiverProfileHandler(profile, null);
                                }}
                            >
                                Send Interest <i className="iicon-feather-send" />
                            </button>

                            <button
                                className="button red ripple-effect ico"
                                onClick={event => {
                                    event.preventDefault()
                                    dispatch(createOrDeleteShortlist(profile, 'delete'))
                                }}
                            >
                                <i className="icon-feather-trash-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default () => {
    const useShortListHooks = useShortlist();
    const { selectReceiverProfileHandler } = useRequestAndInterest();
    const { dispatch, loading, shortlist, fetchUserShortlist, createOrDeleteShortlist } = useShortListHooks;

    useEffect(() => {
        dispatch(fetchUserShortlist());
    },[])

    if(loading) {
        return(
            <center>
                <Loading/>
            </center>
        );
    };

    return (
        <Fragment>
            <Modal modalOwner="userDoesntHaveAnyProfile">
                <PopUpNotification
                    title="You don't have any profiles."
                    message="Pleace create a profile to send interest."
                    redirectTo="/app/dashboard/profiles"
                />
            </Modal>

            <Modal modalOwner="shortList">
                <UserProfilesForModel parent="SEND_INTEREST"/>
            </Modal>

            <div className="row">
                <div className="col-xl-12">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-supervisor-account" />
                                {shortlist.length} Profiles(s)
                            </h3>
                        </div>
                        <div className="content">
                            <ul className="dashboard-box-list">
                                {shortlist.map((data, index) => {
                                    return <List key={index} {...data.profile} selectReceiverProfileHandler={selectReceiverProfileHandler} {...useShortListHooks}/>
                                })}
                            </ul>
                            {shortlist.length === 0 && (
                                <StartNew path="/app/search" text="Start Shortlist Profiles " />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
