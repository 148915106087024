
import React, { Fragment } from 'react'
import moment from 'moment'
import CountryWithFlag from '../../../components/common/CountryWithFlag'
import { connect } from 'react-redux'
import { setProfileEditValue } from '../../../reducers/profile'
import Modal from '../../../components/common/Modal'
import { setModalVisibility } from '../../../reducers/controls'
import { currentAge } from '../../../lib/calculations'
import StepOneProfileBasicDetails from '../../../components/Registration/StepOneProfileBasicDetails'
import { fetchCountry } from '../../../lib/resolver'

const ModelWindow = () => {
    return(
        <Modal modalOwner="basicInformations">
            <div className="mfp-content">
                <div
                    id="small-dialog-2"
                    className="zoom-anim-dialog dialog-with-tabs dialog-extended"
                >
                    <StepOneProfileBasicDetails />
                </div>
            </div>
        </Modal>
    )
}

const BasicInformations = ({
    profile_steps,
    basic_information,
    setModalVisibility,
    setProfileEditValue
}) => {

    if (profile_steps.basic_information === 'false') {
        return (
            <Fragment>
                <ModelWindow/>
                <div className="col-xl-6">
                    <div className="dashboard-box child-box-in-row">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-note-add" /> Family Information
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <button
                                className="popup-with-zoom-anim button full-width button-sliding-icon"
                                onClick={event => {
                                    event.preventDefault()
                                    setModalVisibility({
                                        isOpen: true,
                                        modalTriggerOwner: 'basicInformations',
                                    })
                                    setProfileEditValue({
                                        data: basic_information,
                                        key: 'basic_information',
                                    })
                                }}
                            >
                                Add Family Informations <i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    

    const {
        profile_created_by,
        first_name,
        last_name,
        dob,
        gender,
        religion,
        chevvai_dosham,
        marital_status,
        caste,
        living_city,
        living_state,
        living_country,
        nationality,
        residence_status,
        country_of_birth,
        mother_tongue,
        primary_language,
        children,
        number_of_children,
        created_at,
    } = basic_information;

    return (
        <Fragment>
            <ModelWindow/>
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-fingerprint" />
                            Basic Informations
                        </h3>
                        <button
                            className="mark-as-read ico dark"
                            data-tippy-placement="left"
                            data-tippy
                            data-original-title="Mark all as read"
                            onClick={event => {
                                event.preventDefault()
                                setModalVisibility({
                                    isOpen: true,
                                    modalTriggerOwner: "basicInformations"
                                })
                                setProfileEditValue({
                                    data: basic_information,
                                    key: 'basic_informations',
                                })
                            }}
                        >
                            <i className="icon-feather-edit" />
                        </button>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list remove-padding">
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>
                                        Who created this profile ?
                                    </strong>
                                    <strong>{profile_created_by}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your Name ? </strong>
                                    <strong>
                                        {first_name} {last_name}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>How old are you ?</strong>
                                    <strong>{currentAge(dob)}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your sex ?</strong>
                                    <strong>{gender}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Which religion do you practice? </strong>
                                    <strong>{religion}</strong>
                                </span>
                            </li>
                            {chevvai_dosham && (
                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>Do you have chevaai dosham ?</strong>
                                        <strong>{chevvai_dosham ? "Yes" : "No"}</strong>
                                    </span>
                                </li>
                            )}

                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>How many children do you have ?</strong>
                                    <strong>{marital_status}</strong>
                                </span>
                            </li>

                            {marital_status !== "unmarried" && (
                            <Fragment>
                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>What is your marital status ?</strong>
                                        <strong>{children}</strong>
                                    </span>
                                </li>

                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>Do you have children ?</strong>
                                        <strong>{number_of_children}</strong>
                                    </span>
                                </li>
                            </Fragment>
                            )}
                           
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What communities do you belong to?</strong>
                                    <strong>{caste}</strong>
                                </span>
                            </li>

                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where do you live (city) ?</strong>
                                    <strong>{living_city}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where do you live (state) ?</strong>
                                    <strong>{living_state}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Which country do you live in ?</strong>
                                    <strong>
                                        <CountryWithFlag living_country={living_country} /> {fetchCountry(living_country).name}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your nationality ?</strong>
                                    <strong>{nationality}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your current residence status ?</strong>
                                    <strong>{residence_status}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where are you from ?</strong>
                                    <strong> 
                                        <CountryWithFlag living_country={country_of_birth} /> {fetchCountry(country_of_birth).name}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your native language ?</strong>
                                    <strong>{mother_tongue}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your primary language ?</strong>
                                    <strong>{primary_language}</strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
        
    )
};

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
})

const mapDispatchToProps = {
    setProfileEditValue,
    setModalVisibility
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BasicInformations)
