import React, { useState } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker2'

// https://mberneti.github.io/react-datepicker2/#example-10
const DatePickerLatest = props => {
    const {
        label,
        meta: { touched, error, warning},
        input: { onChange },
        className,
        help,
        info,
    } = props

    const [value, setValue] = useState(moment().subtract(18, 'years'))

    const onChangeHandler = (selectedDate) => {
        setValue(selectedDate);
        onChange(moment.utc(selectedDate).format())
    }

    return (
        <div className="col-xl-4">
            <div className="submit-field">
                <h5>
                    {label}
                    {help && (
                        <span className="tool" data-tip={`${info}`}>
                            <i className="help-icon" data-tippy-placement="right" />
                        </span>
                    )}
                </h5>
                <DatePicker
                    ranges={[
                        { 
                          disabled: true, 
                          start:moment().subtract(18, 'years'),
                          end:moment()
                        }
                    ]}
                    timePicker={false}
                    isGregorian={true}
                    className={className}
                    value={value}
                    onChange={onChangeHandler}
                    dateFormat={'DD/MM/YYYY'}
                />
                {touched &&
                    ((error && <span>{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
}

export default DatePickerLatest
