import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../InputFields/Input'
import { resendEmailVerification, verifyForgotEmail, verifyEmailAddress } from '../../reducers/email'
import { required, number } from '../../lib/validationAndWarning'
import Loading from '../common/LoadingOverlay'

const VerifyEmailAddress = props => {
    const {
        authLocationHistory: { isLoaded, history },
        handleSubmit,
        verifyEmailAddress,
        verifyForgotEmail,
        resendEmailVerification
    } = props

    if (!isLoaded) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <article>
            <form
                onSubmit={handleSubmit(
                    typeof history.verificationToken !== 'undefined'
                        ? verifyForgotEmail
                        : verifyEmailAddress
                )}
            >
                <div className="col-md-8 offset-md-2">
                    <div id="test1" className="dashboard-box">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-email" /> Verify Email
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <div className="row">
                                <div className="col-lg-12 welcome-text">
                                    <h3>Verify your email address</h3>
                                    <span>We have sent a confirmation code to your e-mail</span>
                                </div>
                            </div>

                            <div className="verification-box">
                                <img src="https://lankanwedding.s3.eu-west-3.amazonaws.com/public/verification-email.jpg" />
                            </div>

                            <div className="row">
                                <Field
                                    id="emailVerificationCode"
                                    name="emailVerificationCode"
                                    type="text"
                                    component={inputField}
                                    label="Enter the verification code."
                                    className="with-border"
                                    placeholder="Enter the verification code."
                                    column="col-md-6 offset-md-3"
                                    validate={[required, number]}
                                />

                                <div class="col-md-6 offset-md-3">
                                    <button
                                        type="submit"
                                        className="button full-width ripple-effect"
                                    >
                                        Verify <i className="icon-material-outline-lock-open" />
                                    </button>
                                </div>
                                <div class="col-md-6 offset-md-3" style={{ textAlign: 'center', marginTop: 20}}>
                                    <a  
                                        href="#"
                                        onClick={resendEmailVerification}
                                    >
                                        Resend Verification Code
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </article>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'emailVerificationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(VerifyEmailAddress)

const mapStateToProps = state => ({
    email: state.email,
    authLocationHistory: state.controls.authLocationHistory,
})

const mapDispatchToProps = {
    verifyEmailAddress,
    resendEmailVerification,
    verifyForgotEmail,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
