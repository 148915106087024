import React from 'react';

const ButtonSelect = ({
    input: {
        value,
        onChange
    },
    assignedValue,
    onClickCallBack
}) => {
    return (
        <div>
            <button
                onClick={(event) => {
                    event.preventDefault();
                    onClickCallBack();
                    onChange(assignedValue);
                }}
                type="button"
                className="button full-width margin-top-20"
            >
                Select
            </button>
        </div>
    );
};

export default ButtonSelect;