import React, { useState, useEffect } from 'react'
import { Link } from "@reach/router"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import ReCAPTCHA from "react-google-recaptcha";
import inputField from '../InputFields/Input'
import SmartSelectField from '../InputFields/SmartSelectField'
import Countries from '../../helpers/Countries'
import { createUser } from '../../reducers/auth'
import {
    required,
    maxLength15,
    minLength2,
    email,
    string,
    // phoneNumber,
    passwordMinLength,
    // matchPassword,
} from '../../lib/validationAndWarning'
import { setModalVisibility } from '../../reducers/controls';

const StepOneUserRegistration = ({
    handleSubmit,
    closeModalWindow,
    pristine,
    createUser,
}) => {
    const recaptchaRef = React.createRef();
    const [reCAPTCHA, setReCAPTCHA] = useState();

    useEffect(() => {
        closeModalWindow();
    }, [])

    const submitHandler = () => {
        if (reCAPTCHA) {
            createUser(reCAPTCHA);
            recaptchaRef.current.reset();
        } else {
            alert("Recaptcha hasn't been checked")
        }
    }

    const onChange = (value) => {
        if (value) {
            setReCAPTCHA(value)
        }
    }

    const onRecaptchaError = (error) => {
        console.error('error =>', error)
    }

    return (

            <form onSubmit={handleSubmit(submitHandler)}>
                    <div className="col-md-8 offset-md-2">
                        <div className="dashboard-box margin-top-0">
                            <div className="headline">
                                <h3>
                                    <i className="icon-feather-user-check" /> Sign Up
                                </h3>
                            </div>

                            <div className="content with-padding padding-bottom-10">
                                <div className="row">
                                        <div className="col-lg-12 welcome-text">
                                            <h3>Setup your free account</h3>
                                            <span>
                                                Setup your free account once and create unlimited profiles.
                                                <Link
                                                    to={'/app/login'}
                                                    className="register-tab"
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    Sign in
                                                </Link>
                                            </span>
                                        </div>
                                </div>

                                <div className="row">
                                    <Field
                                        id="username"
                                        name="username"
                                        type="text"
                                        component={inputField}
                                        label="Username"
                                        className="with-border"
                                        placeholder="Enter a username"
                                        column="col-xl-6"
                                        validate={[required, maxLength15, minLength2]}
                                    />

                                    <Field
                                        id="email"
                                        name="email"
                                        type="email"
                                        component={inputField}
                                        label="Email"
                                        className="with-border"
                                        placeholder="Enter your Email"
                                        column="col-xl-6"
                                        validate={[required, email]}
                                    />

                                    <Field
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        component={inputField}
                                        label="First Name"
                                        className="with-border"
                                        placeholder="Enter your first name"
                                        column="col-xl-6"
                                        validate={[required, string]}
                                    />

                                    <Field
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        component={inputField}
                                        label="Last Name"
                                        className="with-border"
                                        placeholder="Enter your last name"
                                        column="col-xl-6"
                                        validate={[required, string]}
                                    />

                                    <Field
                                        id="country"
                                        name="country"
                                        type="text"
                                        component={SmartSelectField}
                                        dataSource={Countries}
                                        label="Country"
                                        className="with-border"
                                        placeholder="Enter your country"
                                        column="col-xl-6"
                                        validate={[required]}
                                    />
                               

                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        component={inputField}
                                        label="New Password"
                                        className="with-border"
                                        placeholder="Enter your New Password"
                                        column="col-xl-6"
                                        validate={[required, passwordMinLength]}
                                    />

                                    {/* <Field
                                        id="repeatPassword"
                                        name="repeatPassword"
                                        type="password"
                                        component={inputField}
                                        label="Repeat New Password"
                                        className="with-border"
                                        placeholder="Enter your New Password again"
                                        column="col-xl-6"
                                        validate={[required, matchPassword]}
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row margin-top-30">
                        <div class="col-md-6 offset-md-3" align="center">
                            <ReCAPTCHA
                                sitekey={process.env.RECAPTCHA_SITE_KEY}
                                onChange={onChange}
                                onErrored={onRecaptchaError}
                                ref={recaptchaRef}
                            />
                        </div>
                    </div>

                    <div class="row margin-top-20">
                        <div class="col-md-6 offset-md-3">
                            <button
                                type="submit"
                                className="button full-width button-sliding-icon  margin-top-10"
                            >

                                Register And Process<i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>

                    <div className="row margin-top-20">
                        <div className="col-md-12" align="center">
                            <div className="clearfix">
                                <p>
                                    By signing up, you agree to Lankanwedding's <Link to="/app/terms-and-conditions">Terms and Conditions </Link> & <Link to="/app/privacy">Privacy Policy</Link>
                                </p>
                            </div>
                        </div>
                    </div>
            </form>

    )
}

const InitializeFromStateForm = reduxForm({
    form: 'user',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StepOneUserRegistration)

const mapStateToProps = state => ({
    initialValues: state.user,
    forms: state.form,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ 
        createUser,
        closeModalWindow: () => dispatch(setModalVisibility({
            isOpen: false,
            modalTriggerOwner: 'login',
        })),
    }, 
    dispatch)
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
