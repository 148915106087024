import React from 'react'
import { Fragment } from 'react'
import LankanWeddingLogo from '../assets/images/logo/lankanwdding_logo.png'
import Meta from '../components/common/Meta'

export default () => {
    return (
        <Fragment>
            <Meta
                title={'About Us - Lankanwedding.com'}
            />
            <div
                className="single-page-header"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                    <div style={{ marginRight: 20 }}>
                                        <img
                                            src={LankanWeddingLogo}
                                            alt="Lankan wedding Logo"
                                            width="140"
                                            height="140"
                                        />
                                    </div>
                                    <div className="header-details">
                                        <h3>
                                            Lankanwedding <span>Matrimonial Service</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8 content-right-offset">
                        <div className="single-page-section border-box" style={{ textAlign: "justify" }}>
                            <h3 className="margin-bottom-25">About Company</h3>
                            <p>
                                <b>Lankanwedding</b> is a dedicated matrimonial service provider
                                for Sri Lankans all over the world. Today, we are the
                                most trusted Matrimony website by Sri Lankan Tamil, Sinhala,
                                Muslim and Burgher communities. We provide facilities to find a
                                soul mate, one with whom you can share the rest of your life
                                sometimes can be frustrating and disappointing.
                            </p>

                            <p>
                                <b>Lankanwedding</b> is the 1st group to offer 100% mobile verified
                                profiles, reinforcing the trust that members have on us. We have
                                also pioneered the highly personalized matchmaking services.
                            </p>
                        </div>
                        {/* <div className="boxed-list margin-bottom-60">
              <div className="boxed-list-headline">
                <h3>
                  <i className="icon-material-outline-thumb-up" /> Reviews
                </h3>
              </div>
              <ul className="boxed-list-ul">
                <li>
                  <div className="boxed-list-item">
                    <div className="item-content">
                      <h4>
                        Doing things the right way{' '}
                        <span>Anonymous Employee</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating={5.0} />
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range" />{' '}
                          August 2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          Great company and especially ideal for the
                          career-minded individual. The company is large enough
                          to offer a variety of jobs in all kinds of interesting
                          locations. Even if you never change roles, your job
                          changes and evolves as the company grows, keeping
                          things fresh.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="boxed-list-item">
                    <div className="item-content">
                      <h4>
                        Outstanding Work Environment{' '}
                        <span>Anonymous Employee</span>
                      </h4>
                      <div className="item-details margin-top-10">
                        <div className="star-rating" data-rating={5.0} />
                        <div className="detail-item">
                          <i className="icon-material-outline-date-range" /> May
                          2019
                        </div>
                      </div>
                      <div className="item-description">
                        <p>
                          They do business with integrity and rational thinking.
                          Overall, it's an excellent place to work, with
                          products that are winning in the marketplace.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="centered-button margin-top-35">
                <a
                  href="#small-dialog"
                  className="popup-with-zoom-anim button button-sliding-icon"
                >
                  Leave a Review{' '}
                  <i className="icon-material-outline-arrow-right-alt" />
                </a>
              </div>
            </div> */}
                    </div>
                    {/* Sidebar */}
                    <div className="col-xl-4 col-lg-4">
                        <div className="sidebar-container border-box">
                            {/* Location */}
                            {/* <div className="sidebar-widget">
                <h3>Location</h3>
                <div id="single-job-map-container">
                  <div
                    id="singleListingMap"
                    data-latitude="52.520007"
                    data-longitude="13.404954"
                    data-map-icon="im im-icon-Hamburger"
                  />
                  <a href="#" id="streetView">
                    Street View
                  </a>
                </div>
              </div> */}
                            {/* Widget */}
                            <div className="sidebar-widget">
                                <h3>Social Profiles</h3>
                                <div className="freelancer-socials margin-top-25">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/lankanweddingmatrimony"
                                                data-tippy-placement="top"
                                                data-tippy
                                                data-original-title="facebook"
                                            >
                                                <i className="icon-feather-facebook" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com/lankanwedding"
                                                data-tippy-placement="top"
                                                data-tippy
                                                data-original-title="Twitter"
                                            >
                                                <i className="icon-brand-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/lankanwedding"
                                                data-tippy-placement="top"
                                                data-tippy
                                                data-original-title="instagram"
                                            >
                                                <i className="icon-feather-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.youtube.com/channel/UCVgn_INVz1SsKLLsu7VcLXw"
                                                data-tippy-placement="top"
                                                data-tippy
                                                data-original-title="youtube"
                                            >
                                                <i className="icon-feather-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
