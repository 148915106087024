import React from 'react'
import { Link } from '@reach/router'
import { currentAge } from '../../lib/calculations';
import CountryWithFlag from '../common/CountryWithFlag';
import moment from 'moment';
import ShortListButton from '../common/ShortListButton';
import { fetchCountry } from '../../lib/resolver';
import useAuth from '../../hooks/useAuth';
import { useControls } from '../../hooks/useHooks';

export default (props) => {
    const { isAuthenticated } = useAuth();
    const { showLoginModalWindow } = useControls()

    const {
        key,
        lastSearchResultRef,
        profile: {
            id,
            reference,
            avatar,
            created_at,
            profile_photo,
            basic_information: {
                profile_created_by,
                living_country,
                dob,
                mother_tongue,
            },
            education_and_profession: {
                occupation
            },
            lifestyle_and_appearance: {
                height
            }
        }
    } = props;

    return (
        <div key={key} className="freelancer">
            <div className="freelancer-overview">
                <div className="freelancer-overview-inner">
                    {isAuthenticated && <ShortListButton isLargeButton={false} profile={props.profile} />}
                    <div className="freelancer-avatar" style={{ display: 'inline-block' }}>
                        <img src={profile_photo !== "null" ? profile_photo : avatar} />
                    </div>
                    <div className="freelancer-name">
                        <div className="verified-container">
                            {reference} <span className="verified-badge"></span>
                        </div>
                        <span>{occupation}</span>
                        <p>
                            <span> <CountryWithFlag {...props.profile.basic_information} /> {fetchCountry(living_country).name}</span>
                        </p>
                        <p>
                            <span>
                                Profile Created by {profile_created_by}{' '} {moment(created_at).fromNow()}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="freelancer-details">
                <div className="freelancer-details-list">
                    <ul>
                        <li>
                            Age <strong>{currentAge(dob)}</strong>
                        </li>
                        <li>
                            Height <strong>{height}</strong>
                        </li>
                        <li>
                            Mother Tongue<strong>{mother_tongue}</strong>
                        </li>
                        <li>
                            Location
                                        <strong>
                                <i className="icon-material-outline-location-on" />
                                {fetchCountry(living_country).name}
                            </strong>
                        </li>

                    </ul>
                </div>
                {
                    !isAuthenticated ? <button
                        className="button full-width button-sliding-icon ripple-effect"
                        onClick={showLoginModalWindow}
                    >
                         View profile
                                <i className="icon-material-outline-arrow-right-alt" />
                    </button> :
                    <Link
                        to={`/app/profile/${id}/${reference}`}
                        className="button button-sliding-icon ripple-effect"
                    >
                        View profile
                                    <i className="icon-material-outline-arrow-right-alt" />
                    </Link>
                }                
            </div>
        </div>
    )
};