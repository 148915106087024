import React, { Fragment } from 'react'
import { Router } from "@reach/router"
import Layout from '../Layout/MainLayout';
import Login from '../containers/Login'
import ForgotPassword from '../components/Auth/ForgotPassword';
import UserRegistration from '../containers/UserRegistration'
import DashboardLayout from '../Layout/DashboardLayout'
import PhoneVerification from '../containers/PhoneVerification'
import EmailVerification from '../containers/EmailVerification'
import ProfileRegistration from '../containers/UserDashboard/ProfileRegistration'
import SearchPage from '../containers/SearchPage'
import Profile from '../containers/Profile'
import Dashboard from '../containers/UserDashboard/Dashboard'
import Plan from '../containers/UserDashboard/Plan/CurrentPlan'
import BuyNewPlan from '../containers/UserDashboard/Plan/BuyNewPlan'
import Profiles from '../containers/UserDashboard/Profiles'
import UserProfile from '../containers/UserDashboard/Profile'
import Shortlist from '../containers/UserDashboard/Shortlist'
import Messages from '../containers/UserDashboard/Messages'
import Settings from '../containers/UserDashboard/Settings'
import NetworkError from '../containers/NetworkError'
import ContactUs from '../containers/ContactUs';
import Privacy from '../containers/Privacy';
import TermsAndConditions from '../containers/TermsAndConditions';
import CreateSuccessMessage from '../components/common/SuccessMessage';
import AboutUs from '../containers/AboutUs';
import PublicSearchPage from '../containers/PublicSearchPage';

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <DashboardLayout {...rest}>
        <Fragment>
            <Component {...rest} />
        </Fragment>
    </DashboardLayout>
)

const PublicRoute = ({ component: Component, ...rest }) => (
    <Layout {...rest}>
        <Fragment>
            <Component {...rest} />
        </Fragment>
    </Layout>
);

const App = (props) => (
    <Router>
        <PublicRoute path="/app/login" component={Login} />
        <PublicRoute path="/app/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/app/user/forgot-password-verify-email/:verificationToken" component={EmailVerification} />
        <PublicRoute path="/app/network-error" component={NetworkError} />
        <PublicRoute path="/app/signup" component={UserRegistration} />
        <ProtectedRoute path="/app/search" component={SearchPage} />
        <PublicRoute path="/app/public/search" component={PublicSearchPage} />
        <PublicRoute path="/app/contact-us" component={ContactUs} />
        <PublicRoute path="/app/about-us" component={AboutUs} />
        <PublicRoute path="/app/terms-and-conditions" component={TermsAndConditions} />
        <PublicRoute path="/app/privacy" component={Privacy} />
        <ProtectedRoute path="/app/profile/:id/:reference" component={Profile} />
        <ProtectedRoute path="/app/user/verify-email" component={EmailVerification} />
        <ProtectedRoute path="/app/user/verify-phone-number" component={PhoneVerification} />
        <ProtectedRoute path="/app/dashboard" component={Dashboard} />
        <ProtectedRoute path="/app/dashboard/messages" component={Messages} />
        <ProtectedRoute path="/app/dashboard/profiles" component={Profiles} />
        <ProtectedRoute path="/app/dashboard/profile/:id/:method/:tab" component={UserProfile} />
        <ProtectedRoute path="/app/dashboard/shortlist" component={Shortlist} />
        <ProtectedRoute path="/app/dashboard/settings" component={Settings} />
        <ProtectedRoute path="/app/profile/:step/:details/:method/:id" component={ProfileRegistration} />
        <ProtectedRoute path="/app/profile/completed/:id" component={CreateSuccessMessage} />
        <ProtectedRoute path="/app/dashboard/plan" component={Plan} />
        <ProtectedRoute path="/app/dashboard/buy-new-plan" component={BuyNewPlan} />
    </Router>
)

export default App

