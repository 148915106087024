import React, { Fragment, useState, useEffect } from 'react'
import { formValueSelector } from 'redux-form'
import StripeCheckout from 'react-stripe-checkout'
import { connect } from 'react-redux'
import { makePaymentHandler } from '../../../reducers/payment'
import { currentUserResolver } from '../../../lib/resolver'

const Payment = ({
    selectedPlan,
    makePaymentHandler,
    termsAndConditions,
    currentUser,
}) => {
    const [plan, setPlan] = useState(selectedPlan)

    const handleToken = async (token, addresses) => {
        makePaymentHandler(token);
    }

    useEffect(() => {
        setPlan(selectedPlan)
    }, [selectedPlan])

    return (
        <Fragment>
            <StripeCheckout
                stripeKey={process.env.STRIPE_PUBLISHABLE_KEY}
                token={handleToken}
                amount={plan.plan_price * 100}
                name="Lankanwedding"
                email={currentUser.email}
                currency="EUR"
                image="https://lankanwedding.s3.eu-west-3.amazonaws.com/public/lankanwdiing_logo_1000x1000.png"
                // panelLabel="Go Premium" //Submit button in modal
                description={`Upgrade to ${plan.name}  account today.`}
                billingAddress
                shippingAddress
            >
                <div className="stripe-payment">
                    <button
                        className="stripe-payment-button"
                        onClick={(event) => {
                            event.preventDefault();
                            if(termsAndConditions === false) {
                                    event.stopPropagation();
                                    alert('Accept the terms and conditions')
                            }
                        }}
                    >
                        Submit Payment
                    </button>
                </div>
            </StripeCheckout>
        </Fragment>
    )
}

const selector = formValueSelector('updatePlanForm')

const mapStateToProps = state => ({
    termsAndConditions: selector(state, 'termsAndConditions'),
    currentUser: currentUserResolver(state.auth.currentUser),
})

const mapDispatchToProps = {
    makePaymentHandler,
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
