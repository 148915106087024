import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../InputFields/Input'
import { verifyPhoneNumber } from '../../reducers/phone'
import { required, number } from '../../lib/validationAndWarning'

const StepOneVerifyPhoneNumber = ({ handleSubmit, verifyPhoneNumber, phone }) => {
    return (
        phone.codeMessage && <article>
            <form onSubmit={handleSubmit(verifyPhoneNumber)}>
                <div className="col-md-8 offset-md-2">
                    <div id="test1" className="dashboard-box">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-email" /> Verify Phone Number
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <div className="row">
                                <div className="col-lg-12 welcome-text">
                                    <h3>Verify your phone number</h3>
                                    <span>Enter the code what you have received via SMS</span>
                                </div>
                            </div>

                            <div className="verification-box">
                                <img src="https://lankanwedding.s3.eu-west-3.amazonaws.com/public/verification-email.jpg" />
                            </div>

                            <div className="row">
                                <Field
                                    id="phoneVerificationCode"
                                    name="phoneVerificationCode"
                                    type="text"
                                    component={inputField}
                                    label="Enter the verification code."
                                    className="with-border"
                                    placeholder="Enter the verification code."
                                    column="col-md-6 offset-md-3"
                                    validate={[required]}
                                />

                                <div class="col-md-6 offset-md-3">
                                    <button
                                        type="submit"
                                        className="button full-width ripple-effect"
                                    >
                                        Verify <i className="icon-material-outline-lock-open" />
                                    </button>
                                </div>

                                <div className="col-xl-12" align="center">
                                    {phone.codeMessage && (
                                        <label htmlFor="two-step">
                                            <span className="checkbox-icon" /> {phone.codeMessage}
                                        </label>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </article>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'phoneVerificationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StepOneVerifyPhoneNumber)

const mapStateToProps = state => ({
    phone: state.phone
})

const mapDispatchToProps = {
    verifyPhoneNumber,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
