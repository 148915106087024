import React, { useState, useEffect, useCallback, Fragment } from 'react'
import { connect } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Cropper from 'react-easy-crop'
import getCroppedImg from './CropImage'
import { imageUploadHandler } from '../../../reducers/upload'
import { getProfilePhotos, removeImage } from '../../../reducers/photo'
import './styles.css'
import PrivacyHandler from '../../../components/common/PrivacyHandler'

// https://ricardo-ch.github.io/react-easy-crop/
const Photo = props => {
    const {
        id,
        reference,
        imageUploadHandler,
        getProfilePhotos,
        photoPrivacyValue,
    } = props

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [imageSrc, setImageSrc] = useState(null)
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [open, setOpen] = useState(false)
    const [isProfilePhoto, setIsProfilePhoto] = useState(false)

    useEffect(() => {
        getProfilePhotos({ id })
    }, [])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            imageUploadHandler(
                {
                    profileId: id,
                    reference,
                    isProfilePhoto,
                },
                croppedImage
            )

            handleClose()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation, isProfilePhoto])

    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    const readFile = file => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onFileChange = async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)
            setImageSrc(imageDataUrl)
        }
    }

    const handleToggleChange = event => {
        setIsProfilePhoto(event.target.checked)
    }

    const cropperCompo = () => {
        return (
            <div className="App">
                <div className="crop-container">
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={3 / 4}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <PrivacyHandler
                privacyData={{
                    id,
                    privacyType: 'photo_privacy',
                    label: 'Photo Privacy',
                }}
            />

            <Divider variant="middle" style={{ margin: '15px 0 30px 0' }} />

            { !props.profilePhotos.isUploadLimited ? <div align="center">
                <div className="uploadButton margin-top-0">
                    <input
                        className="uploadButton-input"
                        type="file"
                        accept="image/*f"
                        id="upload"
                        multiple
                        onChange={values => {
                            handleClickOpen()
                            onFileChange(values)
                        }}
                    />
                    <label className="uploadButton-button ripple-effect" htmlFor="upload">
                        Upload Files
                    </label>
                    <span className="uploadButton-file-name">
                        Maximum file size: 2 MB
                    </span>
                </div>
            </div> :

            <div className="row">
                <div className="col-xl-12">
                    <div className="notification warning closeable">
                    <strong><i className="icon-line-awesome-warning" />{props.profilePhotos.message}</strong>
                    </div>
                </div>
            </div>

            }

            <Divider variant="middle" style={{ margin: '30px 0 30px 0' }} />

            <div className="row">
                <ExistingPhotos {...props} />
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="alert-dialog-slide-title" onClose={handleClose}>
                    Crop Your Photo
        </DialogTitle>

                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-slide-description">
                        {cropperCompo()}
                        <div className="row">
                            <div className="col-md-6">
                                <Typography id="discrete-slider" gutterBottom>
                                    Zoom
                                </Typography>

                                <Slider
                                    label="Zoom"
                                    defaultValue={zoom}
                                    // getAriaValueText={valuetext}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    step={0.5}
                                    marks
                                    min={1}
                                    max={10}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormControlLabel
                                    label="Set As Profile Picture"
                                    labelPlacement="end"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={isProfilePhoto}
                                            onChange={handleToggleChange}
                                            value={false}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={showCroppedImage} color="primary">
                        Crop And Upload
          </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

const ExistingPhotos = ({ profilePhotos, removeImage }) => {
    return profilePhotos.photos.map((file, index) => {
        return (
            <div key={index} className="col-xl-3 col-md-6">
                <div className="blog-compact-item-container">
                    <div className="blog-compact-item">
                        <img src={file.src} alt />
                        <span className="blog-item-tag">{file.status}</span>
                        <div className="blog-compact-item-content">
                            {/* <ul className="blog-post-tags">
                                    <li>{file.createdAt}</li>
                                </ul> */}
                            {/* <h3>16 Ridiculously Easy Ways to Find &amp; Keep a Remote Job</h3> */}
                            <p>
                                <button
                                    style={{ backgroundColor: '#dc3139 !important' }}
                                    className="mark-as-read ico red"
                                    onClick={() => removeImage(profilePhotos.photos.length, file)}
                                >
                                    <i className="icon-feather-trash-2"></i>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
}

const mapStateToProps = state => ({
    profilePhotos: state.photo.allProfilePhotos,
})

const mapDispatchToProps = {
    getProfilePhotos,
    imageUploadHandler,
    removeImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(Photo)
