import React from 'react'
import Loading from '../../../components/common/Loading'
import BuyNewPlan from './BuyNewPlan'
import CreditStatus from './CreditStatus'
import { useUserSettings } from '../../../hooks/useHooks'

export default (props) => {
    const { loading, currentUserData } = useUserSettings();

    if (loading) {
        return (
            <center>
                <Loading/>
            </center>
        )
    }

    return (
        <div>
            <CreditStatus currentUser={currentUserData} />
            <div className="col-xl-12">
                <div id="test1" className="dashboard-box remove-bg-color">
                    <div className="headline">
                        <h3><i className="icon-line-awesome-flag" /> Your current plan and benefits</h3>
                    </div>
                    <div className="content with-padding">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <strong>Current Plan</strong>
                                    <h4>{currentUserData.subscription.plan.name}</h4>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <strong>Features of Standard Plan</strong>
                                <ul className="list-2 ">
                                    {currentUserData.subscription.plan.capabilities.map(info =>  <li>{info.description}</li>)}
                                </ul>
                            </div>
                            <div className="col-xl-4">
                                <div className="submit-field">
                                    <h5>Amount</h5>
                                       {currentUserData.subscription.plan.price_description}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  
            <div className="col-xl-12">
                <div id="test1" className="dashboard-box remove-bg-color">
                    <div className="headline">
                        <h3><i className="icon-line-awesome-diamond" /> Update Your Plan</h3>
                    </div>
                    <div className="content with-padding">
                        <div className="row">
                            <BuyNewPlan {...props} allPlans={currentUserData.allPlans}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

