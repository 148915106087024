import React from 'react';

const NetworkError = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <section id="not-found" className="center margin-top-50 margin-bottom-25">
                        <h2>500 <i className="icon-line-awesome-question-circle" /></h2>
                        <p>Internal Server Error</p>
                    </section>
                </div>
            </div>
        </div>

    );
};

export default NetworkError;