import React, { Fragment } from 'react'

export const Checkbox = ({
    input,
    type,
    meta: { touched, error, warning },
    className,
    value,
    checked,
}) => {
    return (
        <Fragment>
            <input {...input} type={type} checked={checked} id="two-step" />
            <label htmlFor="two-step">
                <span className={className}/>
            </label>
        </Fragment>
    )
}

export default Checkbox
