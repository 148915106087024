import React from 'react';
import Profile from '../../components/UserDashboard/Settings/Profile';
import PasswordAndSecurity from '../../components/UserDashboard/Settings/PasswordAndSecurity';

const Settings = () => {
    return (
        <div>
            <Profile/>
            <PasswordAndSecurity/>
        </div>
    );
};

export default Settings;