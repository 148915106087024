import React, { useState } from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { logout } from '../../../reducers/auth'


const SideBar = ({ auth, logout }) => {

    const [isMobileNativeAvtive, setIsMobileNativeAvtive] = useState(false);
    
    return (
        <div className="dashboard-sidebar">
            <div className="dashboard-sidebar-inner" data-simplebar="init">
                <div
                    className="simplebar-track vertical"
                    style={{ visibility: 'visible' }}
                >
                    <div
                        className="simplebar-scrollbar"
                        style={{ visibility: 'visible', top: 0, height: 1058 }}
                    />
                </div>
                <div
                    className="simplebar-track horizontal"
                    style={{ visibility: 'visible' }}
                >
                    <div
                        className="simplebar-scrollbar"
                        style={{ visibility: 'visible', left: 0, width: 25 }}
                    />
                </div>
                <div
                    className="simplebar-scroll-content"
                    style={{ paddingRight: 15, marginBottom: '-30px' }}
                >
                    <div
                        className="simplebar-content"
                        // style={{ paddingBottom: 15, marginRight: '-15px' }}
                    >
                        <div className="dashboard-nav-container">
                            <a href="#" className={`dashboard-responsive-nav-trigger ${isMobileNativeAvtive && 'active'}`}>
                                <span className={`hamburger hamburger--collapse ${isMobileNativeAvtive && 'active'}`}>
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner" onClick={() => setIsMobileNativeAvtive(isMobileNativeAvtive ? false : true )}/>
                                    </span>
                                </span>
                                <span className="trigger-title">
                                    Dashboard Navigation
                                </span>
                            </a>
                            <div className={`dashboard-nav ${isMobileNativeAvtive && 'active'}`}>
                                <div className="dashboard-nav-inner">
                                    <ul data-submenu-title="Start">
                                        <li>
                                            <Link to="/app/dashboard" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-material-outline-dashboard" />
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/app/dashboard/profiles" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-material-outline-supervisor-account" />
                                                    My Profiles
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/app/dashboard/shortlist" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-material-outline-bookmarks" />
                                                    Shortlist
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/app/dashboard/messages" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-feather-message-square" />
                                                    Messages
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/app/search" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-material-outline-search" />
                                                    Search
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/app/dashboard/plan" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-feather-bookmark" />
                                                    Your Plan
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul data-submenu-title="Account">
                                        <li>
                                            <Link to="/app/dashboard/settings" onClick={() => setIsMobileNativeAvtive(false)}>
                                                <i className="icon-material-outline-settings" />
                                                Settings
                                            </Link>
                                        </li>
                                        <li>
                                            <a onClick={(event) => {
                                                event.preventDefault();
                                                logout();
                                            }}>
                                                <i className="icon-material-outline-power-settings-new" />
                                                Logout
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};



const mapDispatchToProps = {
    logout
}

export default connect(null, mapDispatchToProps)(SideBar)
