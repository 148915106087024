export default String.prototype.toChangeCase = function (type) {
    switch (type) {
        case 'upper-first':
            return this.charAt(0).toUpperCase() + this.substr(1).toLowerCase();
        case 'upper-each':
            return this.split(' ').map(word => {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            }).join(' ');
        case 'readable-upper-each':
            return this.split('_').map(word => {
                return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
            }).join(' ');
        default:
            throw Error(`In order to get the output pass a value 'upper-first', 'upper-each'`);
    }
}
