import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../InputFields/Input'
import { sendPhoneVerificationCode } from '../../reducers/phone'
import {
    required,
    number
} from '../../lib/validationAndWarning'

const StepOneVerifyPhoneNumber = ({ handleSubmit,  sendPhoneVerificationCode }) => {

    return (
        <article>
        <form onSubmit={handleSubmit(sendPhoneVerificationCode)}>

            <div className="col-md-8 offset-md-2">
                    <div id="test1" className="dashboard-box">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-email" />Verify your phone number
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <div className="row">
                                <div className="col-lg-12 welcome-text">
                                    <h3>Enter your phone number</h3>
                                    <span>A text message with a 6-digit verification code will be sent to your phone.</span>
                                </div>
                            </div>

                            <div className="row">
                                <Field
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    component={inputField}
                                    label="Phone Number"
                                    className="with-border"
                                    placeholder="Enter your phone number. Ex +447411944689"
                                    help={true}
                                    info="Please enter your phone number in the requested format Ex: +447411944689"
                                    column="col-md-6 offset-md-3"
                                    validate={[required, number]}
                                />

                                <div class="col-md-6 offset-md-3">
                                    <button
                                        type="submit"
                                        className="button full-width ripple-effect"
                                    >
                                        <i class="icon-feather-send"></i> Send Verification Code
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </article>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'sendPhoneVerificationCodeForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StepOneVerifyPhoneNumber)

const mapStateToProps = state => ({
    auth: state.auth,
    forms: state.form,
})

const mapDispatchToProps = {
    sendPhoneVerificationCode,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
