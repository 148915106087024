import React from 'react'
import { connect } from 'react-redux'
import Loading from './LoadingOverlay'

const ContactNumber = ({ contactDetails, reference }) => {

    if(contactDetails === null) {
        return(
            <center>
                <Loading/>
            </center>
        );
    }
    return (
        <form>
            <div id="small-dialog-2" className="zoom-anim-dialog dialog-with-tabs">
                <div className="sign-in-form">
                    <ul className="popup-tabs-nav" style={{ pointerEvents: 'none' }}></ul>
                    <div className="popup-tabs-container">
                        <div className="popup-tab-content">
                            <div className="welcome-text">
                                <h3>Contact details of {`${reference}`}</h3>
                                <span>
                                    We have just sent an email with contact details.
                                </span>
                                {/* <span>(Available Soon)</span> */}
                                <br/>
                                <h2>{contactDetails.user.fullName}</h2>
                                <h2>{contactDetails.user.phone}</h2>
                            </div>


                            {/* <button
                                className="button full-width button-sliding-icon ripple-effect"
                                type="submit"
                                form="leave-review-form"
                            >
                                Send
                                <i className="icon-material-outline-arrow-right-alt" />
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const mapStateToProps = state => ({
    contactDetails: state.profile.prefoleContactDetails
})

const mapDispatchToProps = { }

export default connect(mapStateToProps, mapDispatchToProps)(ContactNumber)
