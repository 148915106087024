import React, { Fragment } from 'react'
import { fetchCountry } from '../../lib/resolver'
import { numberOfSiblings } from '../../helpers/SelectOptions'

const readableName = (value) => {
    return numberOfSiblings.find(resp => resp.value === Number(value)).label
}

export default ({ family_information: {
    father_status,
    father_native_place,
    mother_status,
    mother_native_place,
    married_male_siblings,
    unmarried_male_siblings,
    unmarried_female_siblings,
    married_female_siblings,
    family_status,
    family_living_country,
    about_family
} }) => {
    return (
        <Fragment>
            <div className="col-xl-4 col-lg-4">
                <div className="sidebar-widget">
                    <div className="job-overview">
                        <div className="job-overview-headline">Family Information</div>
                        <div className="job-overview-inner">
                            <ul>
                                <li>
                                    <i className="material-icons">account_circle</i>
                                    <span>Father's Status</span>
                                    <h5>{father_status}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_ind</i>
                                    <span>Father's Native Place</span>
                                    <h5>{father_native_place}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">account_circle</i>
                                    <span>Mother's Status</span>
                                    <h5>{mother_status}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_ind</i>
                                    <span>Mother's Native Place</span>
                                    <h5>{mother_native_place}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">supervised_user_circle</i>
                                    <span>Married Male Siblings</span>
                                    <h5>{readableName(married_male_siblings)}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">supervised_user_circle</i>
                                    <span>Unmarried Male Siblings</span>
                                    <h5>{readableName(unmarried_male_siblings)}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">supervised_user_circle</i>
                                    <span>Unmarried Female Siblings</span>
                                    <h5>{readableName(unmarried_female_siblings)}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">supervised_user_circle</i>
                                    <span>Married Female Siblings</span>
                                    <h5>{readableName(married_female_siblings)}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_late</i>
                                    <span>Family Status</span>
                                    <h5>{family_status}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_late</i>
                                    <span>Family Living Country</span>
                                    <h5> {
                                            fetchCountry(
                                                family_living_country
                                            ).name
                                        }</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_late</i>
                                    <span>About My Family</span>
                                    <h5> {about_family} </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
