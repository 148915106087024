import React, { Fragment, useState } from 'react'
import Meta from '../components/common/Meta'

const TermsOfUse = () => {
    return (
        <Fragment>
            <Meta
                title={'Terms and conditions - Lankanwedding.com'}
            />
            <h1>Terms of Use / Service Agreement</h1>

            <p>
                Welcome to <b>Lankanwedding.com</b>, your personal matchmaking platform.
                This Agreement sets out the legally binding terms for your use of the
                Site and membership. This Agreement may be modified by{' '}
                        <b>Lankanwedding.com</b> from time to time. The membership and rights of
                admissions are reserved.
            </p>
            <p>
                This Terms of Use is published in accordance with the provisions of Rule
                3 (1) of the Information Technology (Intermediaries Guidelines) Rules,
                2011.
            </p>
            <p>
                <b>Lankanwedding.com</b> is an advertising platform providing targeted
                advertising services for matchmaking alliances and replaces the
                traditional newspaper classified. The Site is for the personal use of
                individual members to advertise and promote their profiles for the
                purpose of finding relevant matches and cannot be used in connection
                with any commercial endeavors.
            </p>
            <p>
                The Site is a serious matchmaking platform to seek a life partner and is
                not a casual dating site.
            </p>
            <p>
                Access to the <b>Lankanwedding.com</b> website is free. However, we
                offer Premium Memberships wherein one can promote his/her Profile on
                various <b>Lankanwedding.com</b> properties such as Premium Interest
                Wall, Inbox, Search Results, Emails, SMS, etc
            </p>
            <p>
                If you do not understand any of the terms of this Agreement, please
                contact us before using the Services.
            </p>
        </Fragment>
    )
}

const AcceptTermsOfUse = () => {
    return (
        <Fragment>
            <h1>Acceptance of Terms of Use Agreement</h1>

            <p>
                In order to use the <b>Lankanwedding.com</b> Service you must register
                as a member on the Website/ community sites/mobile applications.
                <b>Lankanwedding.com</b> is intermediary as defined under sub-clause (w)
                of Section 2 of the Information Technology Act, 2000. This Agreement is
                an electronic contract that establishes the legally binding terms you
                must accept to use the Site and to become a "Member" For purposes of
                this Agreement, the term "Member" means a person who voluntarily submits
                information to the Site whether such person uses the Service as a free
                member or a paid member.
            </p>

            <p>
                By using the Site, you agree to be bound by these Terms of Use
                ("Agreement"). If you wish to become a Member and promote/ advertise
                your profile to other Members and make use of the{' '}
                        <b>Lankanwedding.com</b> service ("Service"), read these Terms of Use
                and follow the instructions in the registration process. By using the
                Service, you consent to submit your personal identifiable information,
                to collect, process, display and use the said information to provide the
                Service.
            </p>

            <p>
                To withdraw this consent, you must discontinue using our Service and
                delete your account.
      </p>
        </Fragment>
    )
}

const Eligibility = () => {
    return (
        <Fragment>
            <h1>Eligibility</h1>

            <p>We use the information collected in the following ways</p>
            <p>
                <ol>
                    <li>
                        Anyone who has mobile number can become member of{' '}
                        <b>Lankanwedding.com</b> but register a profile of{' '}
                        <b>Lankanwedding.com</b> or use this Site, The profile must be
                        legally competent and of legal marriageable age as per the laws of
                        Sri Lanka. (In Sri Lanka the legal marriage age is 18, but under a
                        decades-old community law, much younger Muslim girls can get
                        married).
                    </li>
                    <li>
                        The <b>Lankanwedding.com</b> site is only to facilitate personal
                        advertisement for lawful marriage alliance between persons who are
                        legally competent to enter into matrimony under the laws to which
                        they are subject. Membership in the Service is void where
                        prohibited.
                    </li>
                    <li>
                        By using this Site, you represent and warrant that you have the
                        right, consent, authority, and legal capacity to enter into this
                        Agreement; submit relevant information to us; and that you are not
                        prohibited or prevented by any applicable law for the time being in
                        force or any order or decree or injunction from any court, tribunal
                        or any such competent authority restraining you from entering into
                        matrimony.
                    </li>
                    <li>
                        You further confirm that you, profile that you register intent to
                        seek a life partner and that you shall not use this site for casual
                        dating. You also agree to abide by all of the terms and conditions
                        of this Agreement. If at any time <b>Lankanwedding.com</b> is of the
                        opinion (in its sole discretion) or has any reason to believe that
                        you are not eligible to become a member or that you have made any
                        misrepresentation, <b>Lankanwedding.com</b>
                        reserves the right to forthwith terminate your membership and / or
                        your right to use the Service without any refund to you of any of
                        your unutilized subscription fee.
                    </li>
                </ol>
            </p>
        </Fragment>
    )
}

const AccountSecurity = () => {
    return (
        <Fragment>
            <h1>Account Security</h1>

            <p>
                You are responsible for maintaining the confidentiality of your login
                credentials you designate during the registration process, and you are
                solely responsible for all activities that occur under your account. You
                agree to immediately notify us of any disclosure or unauthorized use of
                your account or any other breach of security, and ensure that you log
                out from your account at the end of each session.
      </p>
        </Fragment>
    )
}

const TermAndTermination = () => {
    return (
        <Fragment>
            <h1>Term and Termination</h1>

            <p>
                <ol>
                    <li>
                        This Agreement will be effective, valid and subsisting as long as
                        you use our Site.
          </li>
                    <li>
                        You may terminate your membership at any time, for any reason by
            writing to <b>Lankanwedding.com</b>. In the event you terminate your
            membership, you will not be entitled to a refund of any unutilized
            subscription fees, if any, paid by you, except where otherwise
            guaranteed in writing.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> may terminate your access to the Site
            and/or your membership for any reason including but not limited to
            breach of the terms of use, using the service for commercial
            purpose, engaging in prohibited or inappropriate communication or
            activity, by sending notice to you at the email address as provided
            by you in your application for membership or such other email
            address as you may later provide to <b>Lankanwedding.com</b>. If
            <b>Lankanwedding.com</b> terminates your membership for breach of
            terms of this Agreement, you will not be entitled to any refund of
            any Subscription fees, if any, paid by you.
          </li>
                    <li>
                        Term for the paid Membership would be in accordance with the type of
                        membership undertaken.
          </li>
                    <li>
                        In the event of business discontinuity, the Company won't refund the
                        unused membership fees paid by you.
          </li>
                </ol>
            </p>
        </Fragment>
    )
}

const OtherTermsOfUseForMembers = () => {
    return (
        <Fragment>
            <h1>Other Terms of Use for Members.</h1>

            <p>
                <ol>
                    <li>
                        <b>Lankanwedding.com</b> reserves the right in it's sole discretion
            to review the activity and status of each account and block access
            to the member based on such review.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> reserve the right at its sole discretion to
            restrict/suspend your free access to its website based on review of
            the member's activity, status or any such criteria{' '}
                        <b>Lankanwedding.com</b> may deems fit and proper with due
            intimation to you.
          </li>
                    <li>
                        You hereby confirm that as on date of this registration, you do not
                        have any objection to receive emails, SMS/WhatsApp messages and
            calls from <b>Lankanwedding.com</b> and members of{' '}
                        <b>Lankanwedding.com</b> as long as you are a registered member of{' '}
                        <b>Lankanwedding.com</b> including SMS permission for authenticating
            mobile verification wand transactions via OTP, sent by the Payment
            Gateway.
          </li>
                    <li>Multiple profiles of the same person are allowed on our Site.</li>
                    <li>
                        You confirm that the Content, information including the personal
                        information provided by you is correct and accurate.
          </li>
                    <li>
                        Your profile will be activated if you upload photo for the specific
                        profile, You are strongly advised to upload photos for each profile
                        that you create.
          </li>
                    <li>
                        Except for the purpose of promoting/advertising your profile for
                        matchmaking purposes, you cannot engage in advertising to, or
                        solicitation of, other Members to buy or sell any products or
                        services through the Service.
          </li>
                    <li>
                        You will not transmit any chain letters or junk email to other
            <b>Lankanwedding.com</b> Members. Although <b>Lankanwedding.com</b>{' '}
            cannot monitor the conduct of its Members off the{' '}
                        <b>Lankanwedding.com</b> Site, it is also a violation of these rules
            to use any information obtained from other members in order to
            harass, abuse, or harm another person, or in order to contact,
            advertise to, solicit, or sell to any Member without their prior
            explicit consent.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> reserves the right to take appropriate
            steps to protect
            <b>Lankanwedding.com</b> and/or its Members from any abuse/misuse as
            it deems appropriate in its sole discretion.
          </li>
                    <li>
                        You cannot use any automated processes, including IRC Bots, EXE's,
                        CGI or any other programs/scripts to view content on or communicate
            / contact / respond / interact with <b>Lankanwedding.com</b> and/or
            its members.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> reserves the right to screen
            communications/advertisements that you may send to other Member(s)
            and also regulate the same by deleting unwarranted/obscene
            communications/advertisements at any time at its sole discretion
            without prior notice to any Member.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> Members are expected to exercise simple
            precautions for their privacy and safety. A suggestive guideline of
            such precautions is enlisted under security tips. By becoming a
            Member you hereby agree to read, abide and adhere to the said Tips.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> Members who subscribe to the{' '}
                        <b>Lankanwedding.com</b> Select Service hereby, unconditionally and
            irrevocably confirm that you have read terms and conditions and
            agree to abide by them.
          </li>
                </ol>
            </p>
        </Fragment>
    )
}

const ProprietaryRights = () => {
    return (
        <Fragment>
            <h1>Proprietary Rights in the Content on Lankanwedding.com</h1>
            <p>
                <b>Lankanwedding.com</b> owns and retains all proprietary rights in the{' '}
                <b>Lankanwedding.com</b> Site and the <b>Lankanwedding.com</b> Service.
        The Site contains the copyrighted material, trademarks, and other
        proprietary information of <b>Lankanwedding.com</b>, and its licensors.
        Except for that information which is in the public domain such as member
        profile or for which permission has been obtained from the user, you
        cannot copy, modify, publish, transmit, distribute, perform, display, or
        sell any such proprietary information. Any such act or an attempted act
        on your part shall constitute a violation of this Agreement and your
        membership is liable to be terminated forthwith by{' '}
                <b>Lankanwedding.com</b> without refund of any of your unused
        Subscription fees. <b>Lankanwedding.com</b> reserves right to take legal
        action (civil and/or criminal) wherever applicable for any violations.
      </p>
        </Fragment>
    )
}

const ContentPostedOnTheSite = () => {
    return (
        <Fragment>
            <h1>Content Posted on the Site.</h1>

            <p>
                <ol>
                    <li>
                        You understand and agree that <b>Lankanwedding.com</b> may delete
            any listing, content, communication, photos or profiles
            (collectively, "Content") that in the sole judgment of{' '}
                        <b>Lankanwedding.com</b> violate this Agreement or which might be
            offensive, illegal, or that might violate the rights, harm, or
            threaten the safety of either <b>Lankanwedding.com</b> and/or its
            Members.
          </li>
                    <li>
                        With respect to Content you submit or make available for inclusion
                        on publicly accessible areas of the Site including but not limited
                        to your contact details, you hereby unconditionally and irrevocably
            grant to <b>Lankanwedding.com</b> the consent and license to use,
            store, distribute, reproduce, prepare derivative works of, modify,
            adapt, publicly perform and publicly display such Content on the
            Site and to the members of <b>Lankanwedding.com</b> community
            site(s) from time to time.
          </li>
                    <li>
                        By submitting your success story you hereby expressly authorize
            <b>Lankanwedding.com</b> to use, publish, display your photographs
            and testimony on the Site.
          </li>
                    <li>
                        You understand and hereby agree that all information, data, text,
                        photographs, graphics, communications, tags, or other Content
                        whether publicly posted or privately transmitted or otherwise made
            available to <b>Lankanwedding.com</b> are the sole responsibility of
            the person from whom such Content originated and shall be at the
            member's/person's sole risks and consequences. This means that you
            (and not <b>Lankanwedding.com</b>) are solely responsible for all
            Content that you upload, post, email, transmit or otherwise make
            available via the Service. <b>Lankanwedding.com</b> does not control
            the Content posted via the Service and, as such, does not guarantee
            the accuracy, integrity or quality of such Content. We advise all
            User/s to independently verify the content /information posted by
            the other members before acting upon the same and under no
            circumstances will <b>Lankanwedding.com</b> be liable in any way for
            any Content, including, but not limited to, any errors or omissions
            in any Content, or any loss or damage of any kind incurred as a
            result of the use of any Content posted, emailed, transmitted or
            otherwise made available via the Service.{' '}
                    </li>
                    <li>
                        <b>Lankanwedding.com</b> reserves the right to verify the
            authenticity of Content posted on the Site. In exercising this
            right, <b>Lankanwedding.com</b> may ask you to provide any
            documentary or other form of evidence supporting the Content you
            post on the Site. If you fail to produce such evidence to the
            satisfaction of <b>Lankanwedding.com</b>, <b>Lankanwedding.com</b>{' '}
            may, in its sole discretion, terminate your Membership without a
            refund.
          </li>
                    <li>
                        The following is an indicative list of the kind of Content that is
            illegal or prohibited on the Site. <b>Lankanwedding.com</b> will
            investigate and take appropriate legal action in its sole discretion
            against anyone who violates this Agreement, including without
            limitation, removing the offending communication/Content from the
            Service and terminating the Membership of such violators without a
            refund. Illegal and prohibitive Content includes Content which:
            <ul>
                            <li>
                                is blatantly offensive to the community, such as Content that
                                promotes racism, bigotry, hatred or physical harm of any kind
                                against any group or individual;
              </li>
                            <li>harasses or advocates harassment of another person.</li>
                            <li>
                                involves the transmission of "junk mail", "chain letters," or
                                unsolicited mass mailing or "spamming";
              </li>
                            <li>
                                promotes information that you know is false, misleading or
                                promotes illegal activities or conduct that is abusive,
                                threatening, obscene, defamatory or libelous;
              </li>
                            <li>
                                promotes an illegal or unauthorized copy of another person's
                                copyrighted work, such as providing pirated computer programs or
                                links to them, providing information to circumvent
                                manufacture-installed copy-protect devices, or providing pirated
                                music or links to pirated music files;
              </li>
                            <li>
                                displays pornographic or sexually explicit material of any kind;
              </li>
                            <li>
                                provides material that exploits people under the age of 18 in a
                                sexual or violent manner, or solicits personal information from
                                anyone under 18;
              </li>
                            <li>
                                provides instructional information about illegal activities such
                                as making or buying illegal weapons, violating someone's
                                privacy, or providing or creating computer viruses;
              </li>
                            <li>
                                solicits passwords or personal identifying information for
                                commercial or unlawful purposes from other users; and
              </li>
                            <li>
                                engages in commercial activities and/or sales without our prior
                                written consent such as contests, sweepstakes, barter,
                                advertising, and pyramid schemes
              </li>
                        </ul>
                    </li>
                    <li>
                        You must use the <b>Lankanwedding.com</b> Service in a manner
            consistent with any and all applicable local, state, and federal
            laws, rules and regulations.
          </li>
                    <li>
                        You cannot include in your Member profile, visible to other members,
                        any telephone numbers, street addresses, URL's, and email addresses.
          </li>
                </ol>
            </p>
        </Fragment>
    )
}

const Privacy = () => {
    return (
        <Fragment>
            <h1>Privacy</h1>
            <p>
                Use of the <b>Lankanwedding.com</b> Site and/or the{' '}
                <b>Lankanwedding.com</b> Service is governed by our Privacy Policy and
        follow guidelines to protect your privacy.
      </p>
        </Fragment>
    )
}

const Disclaimers = () => {
    return (
        <Fragment>
            <h1>Disclaimers</h1>
            <p>
                <ol>
                    <li>
                        <b>Lankanwedding.com</b> is not responsible for any incorrect or
            inaccurate Content/listing posted on the Site or in connection with
            the
            <b>Lankanwedding.com</b> Service, whether caused by Users, Members
            or by any of the equipment or programming associated with or
            utilized in the Service, nor for the conduct of any User and/or
            Member of the <b>Lankanwedding.com</b> Service whether online or
            offline.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> assumes no responsibility for any error,
            omission, interruption, deletion, defect, delay in operation or
            transmission, communications line failure, theft or destruction or
            unauthorized access to, or alteration of, User and/or Member
            communications or any communications by <b>Lankanwedding.com</b> to
            its Members.
          </li>
                    <li>
                        Under no circumstances will <b>Lankanwedding.com</b> be responsible
            for any loss or damage resulting from anyone's use of the Site or
            the Service and/or any Content posted on the{' '}
                        <b>Lankanwedding.com</b> Site or transmitted to
            <b>Lankanwedding.com</b> Members. You should not provide your
            financial information (for example, your credit card or bank account
            information), or wire or otherwise send money, to other Members.
          </li>
                    <li>
                        The exchange of matchmaking profile(s) through or by "
            <b>Lankanwedding.com</b>" is not a matrimonial offer and/or
            recommendation from/by <b>Lankanwedding.com</b>.
            <b>Lankanwedding.com</b> shall not be responsible for any loss or
            damage to any individual arising out of, or subsequent to, any
            relations (including but not limited to matrimonial relations)
            established pursuant to the use of <b>Lankanwedding.com</b> service.
          </li>
                    <li>
                        The Site and the Service are provided "AS-IS" and{' '}
                        <b>Lankanwedding.com</b>
            expressly disclaims any warranty of fitness for a particular purpose
            or non-infringement. <b>Lankanwedding.com</b> cannot guarantee and
            does not promise any specific results from use of the Site and/or
            the
            <b>Lankanwedding.com</b> Service. You understand that{' '}
                        <b>Lankanwedding.com</b> makes no guarantees, either express or
            implied, regarding compatibility with individuals you meet through
            the Service.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> expressly disclaims any liability or
            responsibility whatsoever and howsoever arising as a result of any
            Content/listing posted on the Site / made available to{' '}
                        <b>Lankanwedding.com</b> by any Members or the users of the Site or
            any third party.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> does not assume any responsibility or
            liability for any illegal Content posted on the Site by any Members,
            users or any third party.
          </li>
                    <li>
                        All liability, whether civil or criminal arising out of any Content
                        that is Posted on the Site will be of that Member / user / third
            party who has Posted such Content and <b>Lankanwedding.com</b>{' '}
            reserves its right to claim damages from such Member/ user / third
            party that it may suffer as a result of such Content Posted on the
            Site. <b>Lankanwedding.com</b>
            does not claim ownership of Content you submit or make available for
            inclusion on the Service.
          </li>
                </ol>
            </p>
        </Fragment>
    )
}

const Jurisdiction = () => {
    return (
        <Fragment>
            <h1>Jurisdiction</h1>
            <p>
                If there is any dispute about or involving the Site and/or the Service,
                by using the Site, you unconditionally agree that all such disputes
                and/or differences will be governed by the laws of European Union.
      </p>
        </Fragment>
    )
}

const PaymentTerms = () => {
    return (
        <Fragment>
            <h1>Payment Terms</h1>
            <p>
                <ol>
                    <li>
                        <b>Lankanwedding.com</b> is using <b>Stripe</b>. It is a third party
            payment system to maintain our all subscription and payment
            instruments. Stripe is an American technology company that allows
            individuals and businesses to make and receive payments over the
            Internet.
          </li>
                    <li>
                        Payment Terms are terms that apply to the use of Payment Methods.
                        You can decide whether or not to use a Payment Method. However, if
                        you use a Payment Method, you accept and agree to the terms
                        applicable to that Payment Method, which will form part of your
                        agreement with Stripe.
          </li>
                    <p>
                        If you have any question about how secure is you payment with{' '}
                        <b>Lankanwedding.com</b> please read this document which is provided
            by Strip
            <a href="https://stripe.com/docs/security/stripe" target="_blank">
                            Security at Stripe
            </a>
                    </p>
                </ol>
            </p>
        </Fragment>
    )
}

const RefundAndAssignment = () => {
    return (
        <Fragment>
            <h1>Privacy</h1>
            <p>
                <ol>
                    <li>
                        The payments made by any <b>Lankanwedding.com</b> member for their membership /
            renewal fee / auto renewal are treated as non-refundable.
          </li>
                    <li>
                        <b>Lankanwedding.com</b> member shall not assign or transfer your <b>Lankanwedding.com</b> membership to any
            other person or entity, and such an act shall be treated as
            violation of terms and conditions and <b>Lankanwedding.com</b> shall retain their right
            under clause 2 (b) of this terms and conditions.
          </li>
                    <li>
                        Payment once made for <b>Lankanwedding.com</b> services cannot be assigned to any
            person/party or adjusted towards any other product or packages
            provided by the Company.
          </li>
                </ol>
            </p>
        </Fragment>
    )
}

const Other = () => {
    return (
        <Fragment>
            <h1>Other</h1>
            <p>
                <ol>
                    <li>
                        By becoming a Member of the <b>Lankanwedding.com</b> service, you
            agree to receive certain specific email from{' '}
                        <b>Lankanwedding.com</b> and/or its group sites. You can unsubscribe
            at any time for any reason quickly and easily by clicking on the
            unsubscribe link enclosed in the mail. You can also manage your
            subscription to these emails using the My Alert Manager option.
          </li>
                    <li>
                        This Agreement, accepted upon use of the Site and further affirmed
            by becoming a Member of the <b>Lankanwedding.com</b> service,
            contains the entire agreement between you and{' '}
                        <b>Lankanwedding.com</b> regarding the use of the Site and/or the
            Service. If any provision of this Agreement is held invalid, the
            remainder of this Agreement shall continue in full force and effect.
          </li>
                </ol>
                <p>
                    By becoming a member of <b>Lankanwedding.com</b> and/or using the
          Services of the Site, you unconditionally and irrevocably confirm that
          you have read and understood the above provisions and agree to abide
          by them. If you are not agreeing to any of the above terms and
          conditions please refrain yourself from registering on the Site.
        </p>

                <p>
                    This Agreement is an electronic document in terms of the Information
                    Technology Act, 2000 and Rules there under pertaining to electronic
                    records as applicable and amended. This electronic record is generated
                    by a computer system and does not require any physical or digital
                    signatures.
        </p>
            </p>
        </Fragment>
    )
}

export default () => {
    const [currentChild, setCurrentChild] = useState('TERMS_OF_USE')

    const activeContent = component => {
        switch (component) {
            case 'TERMS_OF_USE':
                return <TermsOfUse />
            case 'ACCEPT_TERMS_OF_USE':
                return <AcceptTermsOfUse />
            case 'ELIGIBILITY':
                return <Eligibility />
            case 'ACCOUNT_SECURITY':
                return <AccountSecurity />
            case 'TERMS_AND_TERMINATION':
                return <TermAndTermination />
            case 'OTHER_TERMS_OF_USE_FOR_MEMBERS':
                return <OtherTermsOfUseForMembers />
            case 'PROPRIETARY_RIGHTS':
                return <ProprietaryRights />
            case 'CONTENT_POSTED_ON_THE_SITE':
                return <ContentPostedOnTheSite />
            case 'PRIVACY':
                return <Privacy />
            case 'DISCLAIMERS':
                return <Disclaimers />
            case 'JURISDICTION':
                return <Jurisdiction />
            case 'PAYMENT_TERMS':
                return <PaymentTerms />
            case 'REFUND_AND_ASSIGNMENT':
                return <RefundAndAssignment />
            case 'OTHER':
                return <Other />
            default:
                break
        }
    }

    const topic = ({ action, title }) => {
        return (
            <li
                className={`list-group-item ${currentChild === action && 'active'}`}
                onClick={() => setCurrentChild(action)}
            >
                {title}
            </li>
        )
    }

    return (
        <div className="container lw-privacy-policy">
            <div className="row">
                <div className="col-md-4">
                    <ul className="list-group">
                        {[
                            {
                                action: 'TERMS_OF_USE',
                                title: 'Terms of Use / Service Agreement',
                            },
                            {
                                action: 'ACCEPT_TERMS_OF_USE',
                                title: 'Acceptance of Terms of Use Agreement',
                            },
                            {
                                action: 'ELIGIBILITY',
                                title: 'Eligibility',
                            },
                            {
                                action: 'ACCOUNT_SECURITY',
                                title: 'Account Security',
                            },
                            {
                                action: 'TERMS_AND_TERMINATION',
                                title: 'Term and Termination',
                            },
                            {
                                action: 'OTHER_TERMS_OF_USE_FOR_MEMBERS',
                                title: 'Other Terms of Use for Members',
                            },
                            {
                                action: 'PROPRIETARY_RIGHTS',
                                title: 'Proprietary Rights',
                            },
                            {
                                action: 'CONTENT_POSTED_ON_THE_SITE',
                                title: 'Content Posted on the Site',
                            },
                            {
                                action: 'PRIVACY',
                                title: 'Privacy',
                            },
                            {
                                action: 'DISCLAIMERS',
                                title: 'Disclaimers',
                            },
                            {
                                action: 'JURISDICTION',
                                title: 'Jurisdiction',
                            },
                            {
                                action: 'PAYMENT_TERMS',
                                title: 'Payment Terms',
                            },
                            {
                                action: 'REFUND_AND_ASSIGNMENT',
                                title: 'Refund and Assignment',
                            },
                            {
                                action: 'OTHER',
                                title: 'Other',
                            },
                        ].map(value => topic(value))}
                    </ul>
                </div>

                <div className="col-md-8">
                    <div className="content">{activeContent(currentChild)}</div>
                </div>
            </div>
        </div>
    )
}
