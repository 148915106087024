import React from 'react'

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

export const SmartSelect = ({
    input,
    options,
}) => {
    return (
        <div className="tags-container">
            {options.map((option, index) => {
                return (
                    <div className="tag" key={index}>
                        <input
                            id={`tag-${option.value}-${index}`}
                            name={`tag-${option.value}-${index}`}
                            value={option.value}
                            type="checkbox"
                            checked={input.value.indexOf(option.value) !== -1}
                            onChange={event => {
                                const newValue = [...input.value]
                                if (event.target.checked) {
                                    newValue.push(option.value)
                                } else {
                                    newValue.splice(newValue.indexOf(option.value), 1)
                                }

                                return input.onChange(newValue)
                            }}
                        />
                        <label htmlFor={`tag-${option.value}-${index}`}>
                            {option.label.capitalize()}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export default SmartSelect
