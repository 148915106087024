export const profileCreatedBy = [
    "Myself",
    "Parent",
    "Grandparent",
    "Brother",
    "Sister",
    "Friend",
    "Relative",
    "Uncle",
    "Aunt",
    "Broker/Agent"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const yesOrNoExtra = [
    {
        value: 'yes',
        label: 'Yes'
    },
    {
        value: 'no',
        label: 'No'
    },
    {
        value: 'occasionally',
        label: 'Occasionally'
}]

export const yesOrNo = [{
    value: 'yes',
    label: 'Yes'
},
{
    value: 'no',
    label: 'No'
}]

export const yesNoWithDontKnow = [{
    value: 'yes',
    label: 'Yes'
},
{
    value: 'no',
    label: 'No'
},
{
    value: 'dontKnow',
    label: "Don't Know"
}]

export const religion = [
    {
        value: "hindu",
        label: "Hindu"
    },
    {
        value: "christian rc",
        label: "Christian(RC)"
    },
    {
        value: "christian non rc",
        label: "Christian(Non RC)"
    },
    {
        value: "buddhist",
        label: "Buddhist"
    },
    {
        value: "muslim",
        label: "Muslim"
    }
];

export const motherTongue = [
    {
        value: "tamil",
        label: "Tamil"
    },
    {
        value: "english",
        label: "English"
    },
    {
        value: "sinhala",
        label: "Sinhala"
    }
];

export const bodyType = [
    "Slim",
    "Athletic",
    "Average",
    "A Few Extra Kilos",
    "Large"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const skinTone = [
    "Very Fair",
    "Fair",
    "Tan",
    "Dark"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const residenceStatus = [
    {
        value: "Asylum Seeker",
        label: "Asylum Seeker"
    },
    {
        value: "Citizen",
        label: "Citizen"
    },
    {
        value: "Permanent Resident",
        label: "Permanent Resident"
    },
    {
        value: "Student Visa",
        label: "Student Visa"
    },
    {
        value: "Refugee Visa",
        label: "Refugee Visa"
    },
    {
        value: "Temporary Visa",
        label: "Temporary Visa"
    },
    {
        value: "No Visa",
        label: "No Visa"
    },
    {
        value: "Work Permit",
        label: "Work Permit"
    }
];

export const maritialStatus = [
    {
        value: "unmarried",
        label: "Unmarried"
    },
    {
        value: "divorced",
        label: "Divorced"
    },
    {
        value: "awaiting divorce",
        label: "Awaiting Divorce"
    },
    {
        value: "separated",
        label: "Separated"
    },
    {
        value: "widowed",
        label: "Widowed"
    }
];

export const community = [
    "Never mind",
    "I don't like to mention my cast",
    "Ambattar",
    "Brahman",
    "Chalckiliyar",
    "Chetty Vellala",
    "Collar",
    "Kadaiyar",
    "Kammalar",
    "Kannar",
    "Karaiyar",
    "Koviyar",
    "Kusavar",
    "Muchavar",
    "Nadduvar",
    "Nalava",
    "Pallar",
    "Paraiyar",
    "Seneer",
    "Sitpar",
    "Thachchar",
    "Thattar",
    "Thurumba",
    "Vannar",
    "Vellalar"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const familyStatus = [
    "Middle Class",
    "Upper Middle Class",
    "Rich",
    "Affluent"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const familyValue = [
    "Orthodox",
    "Traditional",
    "Moderate",
    "Liberal"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const educationLevel = [
    "Primary",
    "Secondary",
    "G.C.E Ordinary Level (O/L)",
    "G.C.E Advance Level (A/L)",
    "Certificate",
    "Diploma",
    "Bachelor's degree",
    "Master's degree",
    "Doctor of Philosophy (Ph.D)"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const educationField = [
    "Arts",
    "Accountancy",
    "Architecture",
    "Business Administration",
    "Business Management",
    "Civil",
    "Commerce",
    "Computer Science (hardware)",
    "Computer Science (software)",
    "Economics",
    "Education",
    "Engineering",
    "Information Technology",
    "Journalism",
    "Law",
    "Library Science",
    "Mathematics",
    "Medicine",
    "Mechanical",
    "Music",
    "Nursing/Health Science",
    "Pharmacy",
    "Public Administration",
    "Science",
    "Others"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const incomeCurrency = [
    ["Australian Dollar(AUD)", "AUD"],
    ["Canadian Dollar(CAD)", "CAD"],
    ["Euro(EUR)", "EUR"],
    ["Pound Sterling(GBP)", "GBP"],
    ["Singapore Dollar(SGD)", "SGD"],
    ["Swiss Franc(CHF)", "CHF"],
    ["U.S. Dollar(USD)", "USD"],
    ["Sri Lankan Rupee", "LKR"],
    ["Indian Rupee", "INR"]
].map(data => {
    return {
        value: data[1],
        label: data[0]
    };
});

export const annualIncome = [
    "1,000 - 10,000",
    "10,000 - 30,000",
    "30,000 - 50,000",
    "50,000 - 80,000",
    "80,000 - 100,000",
    "100,000 + "
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const foodHabits = [
    "Vegetarian",
    "Non-Vegetarian",
    "Pescatarian",
    "Flexitarian/Semi-vegetarian"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const occupation = [
    "Account Collectors",
    "Accountants",
    "Accounting Clerks",
    "Actors",
    "Actuaries",
    "Administrative Assistants",
    "Administrative Services Managers",
    "Adult Literacy Teachers",
    "Advertising Managers",
    "Advertising Sales Agents",
    "Aerospace Engineering and Operations Technicians",
    "Aerospace Engineers",
    "Agents and Business Managers of Artists, Performers, and Athletes",
    "Agricultural Engineers",
    "Agricultural Inspectors",
    "Agricultural Managers",
    "Agricultural Scientists",
    "Agricultural Technicians",
    "Agricultural Workers",
    "Air Conditioning and Heating Mechanics and Installers",
    "Air Traffic Controllers",
    "Aircraft Cargo Handling Supervisors",
    "Aircraft Equipment Mechanics and Technicians",
    "Airfield Operations Specialists",
    "Airline and Commercial Pilots",
    "Ambulance Dispatchers",
    "Ambulance Drivers and Attendants (Except Emergency Medical Technicians)",
    "Amusement and Recreation Attendants",
    "Animal Care and Service Workers",
    "Animal Control Workers",
    "Announcers",
    "Anthropologists",
    "Appraisers and Assessors of Real Estate",
    "Arbitrators",
    "Archeologists",
    "Architects",
    "Architectural Managers",
    "Archivists",
    "Art Directors",
    "Assemblers",
    "Astronomers",
    "Athletes and Sports Competitors",
    "Athletic Trainers",
    "Atmospheric Scientists",
    "Attorneys",
    "Avionics Equipment Mechanics and Technicians",
    "Audio and Video Technicians",
    "Audiologists",
    "Auditing Clerks",
    "Auditors",
    "Authors",
    "Automated Teller Machine (ATM) Repairers",
    "Automotive and Watercraft Service Attendants",
    "Automotive Body and Glass Repairers",
    "Automotive Service Technicians and Mechanics",
    "Baggage Porters and Bellhops",
    "Bailiffs",
    "Bakers",
    "Barbers",
    "Bartenders",
    "Behavioral Disorder Counselors",
    "Benefits Managers",
    "Bicycle Repairers",
    "Bill Collectors",
    "Biochemists and Biophysicists",
    "Biological Scientists (all other)",
    "Biological Technicians",
    "Biomedical Engineers",
    "Blockmasons",
    "Boiler Operators",
    "Boilermakers",
    "Bookkeeping Clerks",
    "Brazers",
    "Brickmasons",
    "Bridge and Lock Tenders",
    "Broadcast Engineering Technicians",
    "Budget Analysts",
    "Building Cleaners",
    "Building Cleaning Workers (all other)",
    "Building Inspectors",
    "Bus Drivers",
    "Business Operations Specialists (all other)",
    "Butchers",
    "Buyers",
    "Camera and Photographic Equipment Repairers",
    "Camera Operators",
    "Cardiovascular Technologists and Technicians",
    "Career Counselors",
    "Career and Technical Education Teachers",
    "Cargo and Freight Agents",
    "Carpenters",
    "Carpet Installers",
    "Cartographers",
    "Cashiers",
    "Ceiling Tile Installers",
    "Cement Masons",
    "Chauffeurs",
    "Chefs",
    "Chemical Engineers",
    "Chemical Plant and System Operators",
    "Chemical Technicians",
    "Chemists",
    "Childcare Center Directors",
    "Childcare Workers",
    "Chiropractors",
    "Choreographers",
    "Civil Engineering Technicians",
    "Civil Engineers",
    "Claims Adjusters, Appraisers, Examiners, and Investigators",
    "Clergy",
    "Clerks",
    "Clinical Laboratory Technologists and Technicians",
    "Coaches and Scouts",
    "Coating Workers",
    "Commercial Designers",
    "Commercial Divers",
    "Commodities Sales Agents",
    "Communications Equipment Operators (all other)",
    "Community and Social Service Specialists (all other)",
    "Community Association Managers",
    "Community Health Workers",
    "Community Service Managers",
    "Compensation, Benefits, and Job Analysis Specialists",
    "Compensation and Benefits Managers",
    "Compliance Officers",
    "Composers - Music",
    "Computer Control Programmers and Operators",
    "Computer Hardware Engineers",
    "Computer Managers",
    "Computer Network Architects",
    "Computer Occupations (all other)",
    "Computer Operators",
    "Computer Programmers",
    "Computer Repairers",
    "Computer Scientists",
    "Computer Software Engineers",
    "Computer Support Specialists",
    "Computer Systems Analysts",
    "Computer Systems Administrators",
    "Concierges",
    "Conciliators",
    "Conservation Scientists",
    "Conservation Technicians",
    "Construction and Related Workers (all other)",
    "Construction Equipment Operators",
    "Construction Inspectors",
    "Construction Laborers and Helpers",
    "Construction Managers",
    "Continuous Mining Machine Operators",
    "Control and Valve Installers and Repairers (Except Mechanical Door)",
    "Cooks",
    "Correctional Officers",
    "Correctional Treatment Specialists",
    "Correspondents",
    "Cosmetologists",
    "Cost Estimators",
    "Costume Attendants",
    "Counselors (all other)",
    "Counter and Rental Clerks",
    "Couriers and Messengers",
    "Court Reporters",
    "Craft Artists",
    "Credit Analysts",
    "Credit Counselors",
    "Crossing Guards",
    "Curators",
    "Customer Service Representatives",
    "Cutters and Trimmers (Hand)",
    "Dancers",
    "Data Entry Keyers",
    "Database Administrators",
    "Delivery Truck Drivers and Driver/Sales Workers",
    "Demonstrators and Product Promoters",
    "Dental Assistants",
    "Dental Hygienists",
    "Dental Laboratory Technicians",
    "Dentists",
    "Derrick Operators (Oil and Gas)",
    "Designers (all other)",
    "Desktop Publishers",
    "Development Managers",
    "Diagnostic Medical Sonographers",
    "Diesel Service Technicians and Mechanics",
    "Dietitians",
    "Directors - Music",
    "Directors - Film, Theater",
    "Dishwashers",
    "Dispatchers (Police, Fire, and Ambulance)",
    "Dispatchers (Except Police, Fire, and Ambulance)",
    "Doctor",
    "Door-to-Door Sales Workers, News and Street Vendors, and Related Workers",
    "Drafters",
    "Drywall Installers",
    "Earth Drillers (Except Oil and Gas)",
    "Economists",
    "Editors",
    "Education Administrators - Postsecondary",
    "Education Administrators - Elementary, Middle, and High School",
    "Education Administrators (all other)",
    "Education, Training, and Library Workers (all other)",
    "Electrical and Electronics Engineering Technicians",
    "Electrical and Electronics Engineers",
    "Electrical and Electronics Installers and Repairers",
    "Electricians",
    "Electro-mechanical Technicians",
    "Electronic Home Entertainment Equipment Installers and Repairers",
    "Elementary School Principals",
    "Elementary School Teachers",
    "Elevator Installers and Repairers",
    "Embalmers",
    "Emergency Management Directors",
    "Emergency Medical Technicians (EMTs)",
    "Emergency Response Dispatchers",
    "Engineering Managers",
    "Engineering Technicians, except Drafters (all other)",
    "Engineers (all other)",
    "Entertainers and Performers, Sports and Related Workers (all other)",
    "Entertainment Attendants and Related Workers (all other)",
    "Environmental Engineering Technicians",
    "Environmental Engineers",
    "Environmental Science and Protection Technicians",
    "Environmental Scientists and Specialists",
    "Epidemiologists",
    "Etchers and Engravers",
    "Exercise Physiologists",
    "Explosives Workers, Ordnance Handling Experts, and Blasters",
    "Extraction Worker Helpers",
    "Extraction Workers (all other)",
    "Fabric and Apparel Patternmakers",
    "Fabric Menders (Except Garment)",
    "Fabricators",
    "Family Therapists",
    "Farm Labor Contractors",
    "Farmers",
    "Fashion Designers",
    "Fence Erectors",
    "Film Editors",
    "Financial Analysts",
    "Financial Clerks",
    "Financial Clerks (all other)",
    "Financial Examiners",
    "Financial Managers",
    "Financial Services Sales Agents",
    "Financial Specialists (all other)",
    "Fine Artists",
    "Fire Inspectors and Investigators",
    "Firefighters",
    "First-Line Supervisors",
    "Fishing Workers",
    "Fitness Trainers and Instructors",
    "Flight Attendants",
    "Flight Engineers",
    "Floor Layers",
    "Floor Sanders and Finishers",
    "Floral Designers",
    "Food and Beverage Serving and Related Workers",
    "Food Preparation Workers",
    "Food Processing Workers",
    "Food Science Technicians",
    "Food Scientists",
    "Food Service Managers",
    "Forensic Science Technicians",
    "Forest and Conservation Technicians",
    "Forest and Conservation Workers",
    "Forest Fire Inspectors and Prevention Specialists",
    "Foresters",
    "Fundraisers",
    "Fundraising Managers",
    "Funeral Attendants",
    "Funeral Service Workers",
    "G.E.D. Teachers",
    "Gaming Change Persons and Booth Cashiers",
    "Gaming Services Workers",
    "Gaming Surveillance Officers",
    "Gas Compressor and Gas Pumping Station Operators",
    "Gas Plant Operators",
    "General Maintenance and Repair Workers",
    "General Office Clerks",
    "Genetic Counselors",
    "Geographers",
    "Geological Engineers",
    "Geological Technicians",
    "Geoscientists",
    "Glaziers",
    "Graders and Sorters (Agricultural Products)",
    "Graduate Teaching Assistants",
    "Graphic Designers",
    "Grinding and Polishing Workers (Hand)",
    "Grounds Maintenance Workers",
    "Hairstylists",
    "Hand Laborers",
    "Hazardous Materials Removal Workers",
    "Head Cooks",
    "Health and Safety Engineers",
    "Health Diagnosing and Treating Practitioners (all other)",
    "Health Educators and Community Health Workers",
    "Health Information Technicians",
    "Health Services Managers",
    "Health Technologists and Technicians (all other)",
    "Healthcare Practitioners and Technical Workers (all other)",
    "Healthcare Support Workers (all other)",
    "Hearing Officers",
    "Heating and Air Conditioning Mechanics and Installers",
    "Heavy and Tractor-trailer Truck Drivers",
    "Heavy Vehicle Service Technicians",
    "High School Equivalency Diploma Teachers",
    "High School Principals",
    "High School Teachers",
    "Highway Maintenance Workers",
    "Historians",
    "Home Appliance Repairers",
    "Home Health Aides",
    "Hotel Managers",
    "Housekeeping Cleaners",
    "Human Resources Managers",
    "Human Resources Specialists",
    "Human Service Assistants",
    "Hunting Workers",
    "Hydrologists",
    "Industrial Designers",
    "Industrial Engineering Technicians",
    "Industrial Engineers",
    "Industrial Machinery Mechanics and Maintenance Workers",
    "Industrial Production Managers",
    "Information Clerks",
    "Information Research Scientists",
    "Information Security Analysts",
    "Information Systems Managers",
    "Inspectors, Testers, Sorters, Samplers, and Weighers",
    "Installation, Maintenance, and Repair Worker Helpers",
    "Installation, Maintenance, and Repair Workers (all other)",
    "Instructional Coordinators",
    "Insulation Workers",
    "Insurance Sales Agents",
    "Insurance Underwriters",
    "Interior Designers",
    "Interpreters",
    "Iron Workers",
    "Janitors",
    "Jewelers",
    "Job Analysis Specialists",
    "Journalists",
    "Judges",
    "Kindergarten Teachers",
    "Labor Relations Specialists",
    "Landscape Architects",
    "Lawyers",
    "Layout Workers (Metal and Plastic)",
    "Legal Assistants",
    "Legal Support Workers (all other)",
    "Legislators",
    "Librarians",
    "Library Technicians and Assistants",
    "Licensed Practical and Licensed Vocational Nurses",
    "Life Scientists (all other)",
    "Life, Physical, and Social Science Technicians (all other)",
    "Lifeguards and Other Recreational Protective Service Workers",
    "Line Installers and Repairers",
    "Loan Officers",
    "Locker Room, Coatroom, and Dressing Room Attendants",
    "Locksmiths and Safe Repairers",
    "Lodging Managers",
    "Logging Workers",
    "Logisticians",
    "Machinists",
    "Maids",
    "Mail Clerks and Mail Machine Operators (Except Postal Service)",
    "Maintenance and Repair Workers, General",
    "Makeup Artists (Theatrical and Performance)",
    "Management Analysts",
    "Managers (all other)",
    "Manicurists",
    "Manufactured Building and Mobile Home Installers",
    "Manufacturing Engineers",
    "Manufacturing Sales Representatives",
    "Mapping Technicians",
    "Marble Setters",
    "Marine Mechanics",
    "Marine Engineers",
    "Market Research Analysts",
    "Marketing Managers",
    "Marriage and Family Therapists",
    "Masons: Brick, Block, Stone, and Cement",
    "Massage Therapists",
    "Material Movers",
    "Material Moving Workers (all other)",
    "Material Moving Machine Operators",
    "Materials Engineers",
    "Mathematical Science Occupations (all other)",
    "Materials Scientists",
    "Mathematical Technicians",
    "Mathematicians",
    "Meat, Poultry, and Fish Cutters and Trimmers",
    "Meat Packers",
    "Mechanical Door Repairers",
    "Mechanical Engineering Technicians",
    "Mechanical Engineers",
    "Mechanics - Automotive",
    "Mechanics - Diesel",
    "Mechanics - Heating, Air Conditioning, and Refrigeration",
    "Mechanics - Industrial Machinery",
    "Mechanics - Small Engine",
    "Media and Communication Equipment Workers (all other)",
    "Media and Communication Workers (all other)",
    "Mediators",
    "Medical Appliance Technicians",
    "Medical Assistants",
    "Medical Billing and Coding",
    "Medical Doctor",
    "Medical Equipment Repairers",
    "Medical Laboratory Technologists and Technicians",
    "Medical Records Technicians",
    "Medical Scientists",
    "Medical Services Managers",
    "Medical Transcriptionists",
    "Meeting, Convention, and Event Planners",
    "Mental Health Counselors",
    "Merchandise Displayers and Window Trimmers",
    "Metal Machine Workers",
    "Metal Workers",
    "Metal Workers and Plastic Workers (all other)",
    "Meteorologists",
    "Meter Readers (Utilities)",
    "Microbiologists",
    "Middle School Principals",
    "Middle School Teachers",
    "Millwrights",
    "Mine Cutting and Channeling Machine Operators",
    "Mine Shuttle Car Operators",
    "Mining Engineers",
    "Mining Machine Operators (all other)",
    "Mining Safety Engineers",
    "Mobile Equipment Service Technicians",
    "Models",
    "Model Makers (Wood)",
    "Molders, Shapers, and Casters (Except Metal and Plastic)",
    "Morticians",
    "Motel Managers",
    "Motion Picture Projectionists",
    "Motor Vehicle Operators (all other)",
    "Motorcycle Mechanics",
    "MRI Technologists",
    "Multimedia Artists and Animators",
    "Museum Technicians",
    "Music Directors and Composers",
    "Musical Instrument Repairers and Tuners",
    "Musicians",
    "Nail Technicians",
    "Natural Sciences Managers",
    "Naval Architects",
    "Network Architects",
    "Network Systems Administrators",
    "News Analysts",
    "Nuclear Engineers",
    "Nuclear Medicine Technologists",
    "Nuclear Technicians",
    "Nurses",
    "Nurse Anesthetists, Nurse Midwives, and Nurse Practitioners",
    "Nursing Assistants",
    "Nutritionists",
    "Office and Administrative Support Workers (all other)",
    "Office Clerks",
    "Office Machine Operators (Except Computer)",
    "Occupational Health and Safety Specialists",
    "Occupational Health and Safety Technicians",
    "Occupational Therapists",
    "Occupational Therapy Assistants and Aides",
    "Operations Research Analysts",
    "Ophthalmic Medical Technicians",
    "Opticians, Dispensing",
    "Optometrists",
    "Orderlies",
    "Orthotists",
    "Painters (Construction and Maintenance)",
    "Painting Workers",
    "Paperhangers",
    "Paralegals",
    "Paramedics",
    "Parking Enforcement Workers",
    "Parking Lot Attendants",
    "Patternmakers (Wood)",
    "Payroll Clerks",
    "Pedicurists",
    "Personal Care Aides",
    "Personal Care and Service Workers (all other)",
    "Personal Financial Advisors",
    "Pest Control Workers",
    "Petroleum Engineers",
    "Petroleum Pump System Operators, Refinery Operators, and Gaugers",
    "Petroleum Technicians",
    "Pharmacists",
    "Pharmacy Technicians",
    "Phlebotomists",
    "Photogrammetrists",
    "Photographers",
    "Physical Scientists (all other)",
    "Physical Therapist Assistants and Aides",
    "Physical Therapists",
    "Physician Assistants",
    "Physicians",
    "Physicists",
    "Pipefitters",
    "Pipelayers",
    "Plant and System Operators (all other)",
    "Plasterers and Stucco Masons",
    "Plastic Machine Workers",
    "Plumbers",
    "Podiatrists",
    "Police and Detectives",
    "Police Dispatchers",
    "Political Scientists",
    "Postal Service Workers",
    "Postmasters and Mail Superintendents",
    "Postsecondary Education Administrators",
    "Postsecondary Teachers",
    "Postsecondary Teachers (all other)",
    "Power Plant Operators, Distributors, and Dispatchers",
    "Practical Nurses, Licensed",
    "Precious Stone and Metal Workers",
    "Precision Instrument and Equipment Repairers (all other)",
    "Prepress Technicians and Workers",
    "Preschool Directors",
    "Preschool Teachers",
    "Pressers (Textile, Garment, and Related Materials)",
    "Print Binding and Finishing Workers",
    "Printing Press Operators",
    "Private Detectives and Investigators",
    "Probation Officers",
    "Producers - Film, Theater",
    "Production Managers",
    "Production Worker Helpers",
    "Production Workers (all other)",
    "Promotions Managers",
    "Proofreaders and Copy Markers",
    "Property Managers",
    "Prosthetists",
    "Protective Service Workers (all other)",
    "Psychiatric Technicians and Aides",
    "Psychologists",
    "Public Relations Managers",
    "Public Relations Specialists",
    "Pump Operators",
    "Purchasing Agents",
    "Purchasing Managers",
    "Quality Control Inspectors",
    "Quarry Rock Splitters",
    "Radiation Therapists",
    "Radio Operators",
    "Radio, Cellular, and Tower Equipment Installers and Repairers",
    "Radiologic Technologists",
    "Rail Transportation Workers (all other)",
    "Rail-Track Laying and Maintenance Equipment Operators",
    "Railroad Occupations",
    "Ranchers",
    "Real Estate Brokers and Sales Agents",
    "Real Estate Managers",
    "Receptionists",
    "Recreation Workers",
    "Recreational Therapists",
    "Recreational Vehicle Service Technicians",
    "Referees, Umpires, and Other Sports Officials",
    "Refractory Materials Repairers (Except Brickmasons)",
    "Refrigeration Mechanics and Installers",
    "Registered Nurses",
    "Rehabilitation Counselors",
    "Reinforcing Iron and Rebar Workers",
    "Religious Activities and Education Directors",
    "Religious Workers (all other)",
    "Repair and Maintenance Workers, General",
    "Reporters",
    "Residential Advisors",
    "Respiratory Therapists",
    "Retail Sales Workers",
    "Riggers",
    "Roof Bolters (Mining)",
    "Roofers",
    "Rotary Drill Operators (Oil and Gas)",
    "Roustabouts (Oil and Gas)",
    "Sales and Related Workers (all other)",
    "Sales Engineers",
    "Sales Managers",
    "Sales Representatives, Services (all other)",
    "School Counselors",
    "School Principals - Elementary, Middle, and High",
    "Science Technicians",
    "Secretaries",
    "Securities Sales Agents",
    "Security and Fire Alarm Systems Installers",
    "Security Guards",
    "Segmental Pavers",
    "Semi Truck Drivers",
    "Septic Tank Servicers and Sewer Pipe Cleaners",
    "Service Unit Operators (Oil, Gas, and Mining)",
    "Set and Exhibit Designers",
    "Sewers (Hand)",
    "Shampooers",
    "Sheet Metal Workers",
    "Shipping, Receiving, and Traffic Clerks",
    "Shoe and Leather Workers and Repairers",
    "Signal and Track Switch Repairers",
    "Singers",
    "Ski Patrol and Other Recreational Protective Service Workers",
    "Skincare Specialists",
    "Slaughterers",
    "Small Engine Mechanics",
    "Social Science Research Assistants",
    "Social Scientists and Related Workers (all other)",
    "Social Service Assistants",
    "Social Service Managers",
    "Social Workers",
    "Sociologists",
    "Software Developers",
    "Solar Photovoltaic Installers",
    "Solderers",
    "Sound Engineering Technicians",
    "Special Education Teachers",
    "Special Education Teachers (all other)",
    "Speech-Language Pathologists",
    "Stationary Engineers",
    "Statistical Assistants",
    "Statisticians",
    "Steamfitters",
    "Steel Workers",
    "Stonemasons",
    "Substance Abuse Counselors",
    "Subway and Streetcar Operators",
    "Surgeons",
    "Surgical Technologists",
    "Survey Researchers",
    "Surveying Technicians",
    "Surveyors",
    "Switchboard Operators (Including Answering Service)",
    "Systems Analysts",
    "Tailors, Dressmakers, and Custom Sewers",
    "Tank Car, Truck, and Ship Loaders",
    "Tapers",
    "Tax Examiners and Collectors, and Revenue Agents",
    "Tax Preparers",
    "Taxi Drivers",
    "Teacher Assistants",
    "Teachers - Adult Literacy and High School Equivalency Diploma",
    "Teachers - Career and Technical Education Teachers",
    "Teachers - High School",
    "Teachers - Kindergarten and Elementary School",
    "Teachers - Middle School",
    "Teachers - Postsecondary",
    "Teachers - Preschool",
    "Teachers - Special Education",
    "Teachers and Instructors (all other)",
    "Technical Writers",
    "Telecommunications Equipment Installers and Repairers (Except Line Installers)",
    "Telemarketers",
    "Telephone Operators",
    "Television, Video, and Motion Picture Camera Operators and Editors",
    "Tellers",
    "Terrazzo Workers",
    "Textile Careers",
    "Textile, Apparel, and Furnishings Workers (all other)",
    "Therapists (all other)",
    "Tile Setters",
    "Timekeeping Clerks",
    "Tire Builders",
    "Tire Repairers and Changers",
    "Tobacco Processing Workers",
    "Tool and Die Makers",
    "Tool Grinders, Filers, and Sharpeners",
    "Top Executives",
    "Tour Guides and Escorts",
    "Traffic Technicians",
    "Training Managers",
    "Training and Development Specialists",
    "Translators",
    "Transportation Attendants",
    "Transportation Inspectors",
    "Transportation Security Screeners",
    "Transportation Workers (all other)",
    "Transportation, Storage, and Distribution Managers",
    "Travel Agents",
    "Travel Clerks",
    "Travel Guides",
    "Truck Drivers - Delivery and Sales Workers",
    "Truck Drivers - Heavy and Tractor-trailer",
    "Ultrasound Technician",
    "Umpires, Referees, and Other Sports Officials",
    "Upholsterers",
    "Urban and Regional Planners",
    "Ushers, Lobby Attendants, and Ticket Takers",
    "Vascular Technologists",
    "Vending, Coin, and Amusement Machine Servicers and Repairers",
    "Veterinarians",
    "Veterinary Assistants and Laboratory Animal Caretakers",
    "Veterinary Technologists and Technicians",
    "Video Editors",
    "Vocational Nurses, Licensed",
    "Waiters and Waitresses",
    "Watch Repairers",
    "Water and Wastewater Treatment Plant and System Operators",
    "Water Transportation Occupations",
    "Web Developers",
    "Weighers, Measurers, Checkers, and Samplers, Recordkeeping",
    "Welders and Cutters",
    "Wellhead Pumpers",
    "Wholesale Sales Representatives",
    "Wildlife Biologists",
    "Wind Turbine Technicians",
    "Word Processors and Typists",
    "Woodworkers",
    "Woodworkers (all other)",
    "Writers",
    "X-Ray Technologists",
    "Zoologists"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const humanHeight = [
    ["1m 21cm", "4 ft 0 in", "4"],
    ["1m 22cm", "4 ft 0 in", "4"],
    ["1m 23cm", "4 ft 0 in", "4"],
    ["1m 24cm", "4 ft 1 in", "4.08"],
    ["1m 25cm", "4 ft 1 in", "4.08"],
    ["1m 26cm", "4 ft 2 in", "4.17"],
    ["1m 27cm", "4 ft 2 in", "4.17"],
    ["1m 28cm", "4 ft 2 in", "4.17"],
    ["1m 29cm", "4 ft 3 in", "4.25"],
    ["1m 30cm", "4 ft 3 in", "4.25"],
    ["1m 31cm", "4 ft 4 in", "4.33"],
    ["1m 32cm", "4 ft 4 in", "4.33"],
    ["1m 33cm", "4 ft 4 in", "4.33"],
    ["1m 34cm", "4 ft 5 in", "4.42"],
    ["1m 35cm", "4 ft 5 in", "4.42"],
    ["1m 36cm", "4 ft 6 in", "4.5"],
    ["1m 37cm", "4 ft 6 in", "4.5"],
    ["1m 38cm", "4 ft 6 in", "4.5"],
    ["1m 39cm", "4 ft 7 in", "4.58"],
    ["1m 40cm", "4 ft 7 in", "4.58"],
    ["1m 41cm", "4 ft 8 in", "4.67"],
    ["1m 42cm", "4 ft 8 in", "4.67"],
    ["1m 43cm", "4 ft 8 in", "4.67"],
    ["1m 44cm", "4 ft 9 in", "4.75"],
    ["1m 45cm", "4 ft 9 in", "4.75"],
    ["1m 46cm", "4 ft 9 in", "4.75"],
    ["1m 47cm", "4 ft 10 in", "4.83"],
    ["1m 48cm", "4 ft 10 in", "4.83"],
    ["1m 49cm", "4 ft 11 in", "4.92"],
    ["1m 50cm", "4 ft 11 in", "4.92"],
    ["1m 51cm", "4 ft 11 in", "4.92"],
    ["1m 52cm", "5 ft 0 in", "5"],
    ["1m 53cm", "5 ft 0 in", "5"],
    ["1m 54cm", "5 ft 1 in", "5.08"],
    ["1m 55cm", "5 ft 1 in", "5.08"],
    ["1m 56cm", "5 ft 1 in", "5.08"],
    ["1m 57cm", "5 ft 2 in", "5.17"],
    ["1m 58cm", "5 ft 2 in", "5.17"],
    ["1m 59cm", "5 ft 3 in", "5.25"],
    ["1m 60cm", "5 ft 3 in", "5.25"],
    ["1m 61cm", "5 ft 3 in", "5.25"],
    ["1m 62cm", "5 ft 4 in", "5.33"],
    ["1m 63cm", "5 ft 4 in", "5.33"],
    ["1m 64cm", "5 ft 5 in", "5.42"],
    ["1m 65cm", "5 ft 5 in", "5.42"],
    ["1m 66cm", "5 ft 5 in", "5.42"],
    ["1m 67cm", "5 ft 6 in", "5.5"],
    ["1m 68cm", "5 ft 6 in", "5.5"],
    ["1m 69cm", "5 ft 7 in", "5.58"],
    ["1m 70cm", "5 ft 7 in", "5.58"],
    ["1m 71cm", "5 ft 7 in", "5.58"],
    ["1m 72cm", "5 ft 8 in", "5.67"],
    ["1m 73cm", "5 ft 8 in", "5.67"],
    ["1m 74cm", "5 ft 9 in", "5.75"],
    ["1m 75cm", "5 ft 9 in", "5.75"],
    ["1m 76cm", "5 ft 9 in", "5.75"],
    ["1m 77cm", "5 ft 10 in", "5.83"],
    ["1m 78cm", "5 ft 10 in", "5.83"],
    ["1m 79cm", "5 ft 10 in", "5.83"],
    ["1m 80cm", "5 ft 11 in", "5.92"],
    ["1m 81cm", "5 ft 11 in", "5.92"],
    ["1m 82cm", "6 ft 0 in", "6"],
    ["1m 83cm", "6 ft 0 in", "6"],
    ["1m 84cm", "6 ft 0 in", "6"],
    ["1m 85cm", "6 ft 1 in", "6.08"],
    ["1m 86cm", "6 ft 1 in", "6.08"],
    ["1m 87cm", "6 ft 2 in", "6.17"],
    ["1m 88cm", "6 ft 2 in", "6.17"],
    ["1m 89cm", "6 ft 2 in", "6.17"],
    ["1m 90cm", "6 ft 3 in", "6.25"],
    ["1m 91cm", "6 ft 3 in", "6.25"],
    ["1m 92cm", "6 ft 4 in", "6.33"],
    ["1m 93cm", "6 ft 4 in", "6.33"],
    ["1m 94cm", "6 ft 4 in", "6.33"],
    ["1m 95cm", "6 ft 5 in", "6.42"],
    ["1m 96cm", "6 ft 5 in", "6.42"],
    ["1m 97cm", "6 ft 6 in", "6.5"],
    ["1m 98cm", "6 ft 6 in", "6.5"],
    ["1m 99cm", "6 ft 6 in", "6.5"],
    ["2m 0cm", "6 ft 7 in", "6.58"],
    ["2m 1cm", "6 ft 7 in", "6.58"],
    ["2m 2cm", "6 ft 8 in", "6.67"],
    ["2m 3cm", "6 ft 8 in", "6.67"],
    ["2m 4cm", "6 ft 8 in", "6.67"],
    ["2m 5cm", "6 ft 9 in", "6.75"],
    ["2m 6cm", "6 ft 9 in", "6.75"],
    ["2m 7cm", "6 ft 9 in", "6.75"],
    ["2m 8cm", "6 ft 10 in", "6.83"],
    ["2m 9cm", "6 ft 10 in", "6.83"],
    ["2m 10cm", "6 ft 11 in", "6.92"],
    ["2m 11cm", "6 ft 11 in", "6.92"],
    ["2m 12cm", "6 ft 11 in", "6.92"],
    ["2m 13cm", "7 ft 0 in", "7"],
    ["2m 14cm", "7 ft 0 in", "7"],
    ["2m 15cm", "7 ft 1 in", "7.08"],
    ["2m 16cm", "7 ft 1 in", "7.08"],
    ["2m 17cm", "7 ft 1 in", "7.08"],
    ["2m 18cm", "7 ft 2 in", "7.17"],
    ["2m 19cm", "7 ft 2 in", "7.17"],
    ["2m 20cm", "7 ft 3 in", "7.25"],
    ["2m 21cm", "7 ft 3 in", "7.25"],
    ["2m 22cm", "7 ft 3 in", "7.25"],
    ["2m 23cm", "7 ft 4 in", "7.33"],
    ["2m 24cm", "7 ft 4 in", "7.33"],
    ["2m 25cm", "7 ft 5 in", "7.42"],
    ["2m 26cm", "7 ft 5 in", "7.42"],
    ["2m 27cm", "7 ft 5 in", "7.42"],
    ["2m 28cm", "7 ft 6 in", "7.5"],
    ["2m 29cm", "7 ft 6 in", "7.5"],
    ["2m 30cm", "7 ft 7 in", "7.58"],
    ["2m 31cm", "7 ft 7 in", "7.58"],
    ["2m 32cm", "7 ft 7 in", "7.58"],
    ["2m 33cm", "7 ft 8 in", "7.67"],
    ["2m 34cm", "7 ft 8 in", "7.67"],
    ["2m 35cm", "7 ft 9 in", "7.75"],
    ["2m 36cm", "7 ft 9 in", "7.75"],
    ["2m 37cm", "7 ft 9 in", "7.75"],
    ["2m 38cm", "7 ft 10 in", "7.83"],
    ["2m 39cm", "7 ft 10 in", "7.83"],
    ["2m 40cm", "7 ft 10 in", "7.83"],
    ["2m 41cm", "7 ft 11 in", "7.92"],
    ["2m 42cm", "7 ft 11 in", "7.92"],
    ["2m 43cm", "8 ft 0 in", "8"],
    ["2m 44cm", "8 ft 0 in", "8"]
].map(data => {
    return {
        label: `${data[1]} - ${data[0]}`,
        value: data[2]
    };
});

export const parentStatus = [
    "Employed",
    "Athletic",
    "Retried",
    "Not Employed",
    "Passed Away"
].map(data => {
    return {
        value: data,
        label: data
    };
});

export const numberOfSiblings = [
    {
        value: 0,
        label: "I don't have"
    },
    {
        value: 1,
        label: "One"
    },
    {
        value: 2,
        label: "Two"
    },
    {
        value: 3,
        label: "Three"
    },
    {
        value: 4,
        label: "Four"
    },
    {
        value: 5,
        label: "Five"
    },
    {
        value: 6,
        label: "Six"
    },
    {
        value: 7,
        label: "Seven"
    },
    {
        value: 8,
        label: "Eight"
    },
    {
        value: 9,
        label: "Nine"
    },
    {
        value: 10,
        label: "Ten"
    },
]