import React from 'react'
import UserRegistration from '../components/Auth/Registration'

const Registration = (props) => {

    // useEffect(() => {
    //     document.getElementById('footer').className = 'footer-fixed';
    // }, [])

    return (
        <div className="container">
            <div className="dashboard-content-inner">
                    <UserRegistration />
            </div>
        </div>
    )
}

export default Registration;