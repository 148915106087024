import React, { useState } from 'react';
import { Link } from '@reach/router'
import moment from 'moment'
import { useDashboard } from '../../hooks/useHooks';
import Loading from '../../components/common/Loading';



const NotificationTemplate = ({ notification_type, href, text_elements, created_at }) => {
    switch (notification_type) {
        case 'SEND_INTEREST':
            return  <>
                        <span className="notification-icon">
                            <img src={text_elements[text_elements.length - 1]} height="50" width="auto"/>
                        </span>
                        <span className="notification-text">
                            {text_elements[0]}{' '}
                            <Link to={href}> {text_elements[2]} </Link>
                            {text_elements[3]}{' '}
                            <Link to={`/app/profile/${text_elements[4]}/${text_elements[5]}`}> {text_elements[5]} </Link>. {text_elements[6]} {moment(created_at).fromNow()}
                        </span>
                    </>;
        case 'SEND_ACCESS_REQUEST':
            return  <>
                        <span className="notification-icon">
                            <img src={text_elements[text_elements.length - 1]} height="50" width="auto"/>
                        </span>
                        <span className="notification-text">
                            {text_elements[0]}{' '}
                            <Link to={href}> {text_elements[2]} </Link>
                            {text_elements[3]}{' '}
                            <Link to={`/app/profile/${text_elements[4]}/${text_elements[5]}`}> {text_elements[5]} </Link> {moment(created_at).fromNow()}
                        </span>
                    </>;
                    
        default:
            return <></>;
    }
}

export default (props) => {
    const { uri } = props; 
    const { loading, notifications, makeAsReadHandler } = useDashboard(uri);
    const [checkedItems, setCheckedItems] = useState({});

    if(loading) {
        return(
            <center>
                <Loading/>
            </center>
        );
    }

    const onChangeHandler = (event) => {
        setCheckedItems({...checkedItems, [event.target.value] : event.target.checked });
        makeAsReadHandler(event.target.value, event.target.checked)
    }
    
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3><i className="icon-material-baseline-notifications-none" /> Notifications</h3>
                        <button className="mark-as-read ripple-effect-dark" data-tippy-placement="left" data-tippy data-original-title="Mark all as read">
                            <i className="icon-feather-check-square" />
                        </button>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list">
                            {notifications.map((item, index) => {
                                return(
                                    <li>
                                        <NotificationTemplate {...item}/>
                                        
                                        <div style={{ marginRight: 40}}>
                                            <div className="switch-container">
                                                <label className="switch"><input value={`${item.id}`} type="checkbox" defaultChecked={item.is_checked} checked={checkedItems[item.id]} onChange={onChangeHandler}/><span className="switch-button" /></label>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                            
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
};