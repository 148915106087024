import React from 'react'
import VerifyPhoneNumber from '../components/Auth/VerifyPhoneNumber'
import SendPhoneVerification from '../components/Auth/sendPhoneVerification'

export default () => {    
    return (
        <div  className="container">
            <SendPhoneVerification />
            <VerifyPhoneNumber />
        </div>
    )
};
