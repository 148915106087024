import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../../assets/images/logo/lankanwedding_logo_vertical.png'
import { useDashboard } from '../../../hooks/useHooks'

const Header = () => {
    const { loading, notifications } = useDashboard();
    const notificationLength = notifications.filter(data => data.is_checked === false).length;
    return (
        <>
            <header id="header-container" className="fullwidth dashboard-header not-sticky">
                {/* Header */}
                <div id="header">
                    <div className="container">
                        {/* Left Side Content */}
                        <div className="left-side">
                            {/* Logo */}
                            <div id="logo">
                                <Link to="/">
                                    <img
                                        src={Logo}
                                        alt="Lankanwedding Logo"
                                        style={{ width: 'auto' }}
                                    />
                                </Link>
                            </div>
                            <div className="clearfix" />
                        </div>
                        <div className="right-side">
                            {/*  User Notifications */}
                            <div className="header-widget hide-on-mobile">
                                {/* Notifications */}
                                <div className="header-notifications">
                                    {notificationLength !== 0  && 
                                    <div className="header-notifications-trigger">
                                        <Link to="/app/dashboard"><i className="icon-feather-bell" />
                                            <span>
                                                {!loading && notificationLength}
                                            </span>
                                        </Link>
                                    </div> }
                                    {/* <div className="header-notifications-dropdown">
                                        <div className="header-notifications-headline">
                                            <h4>Notifications</h4>
                                            <button className="mark-as-read ripple-effect-dark" data-tippy-placement="left" data-tippy data-original-title="Mark all as read">
                                                <i className="icon-feather-check-square" />
                                            </button>
                                        </div>
                                        <div className="header-notifications-content">
                                            <div className="header-notifications-scroll" data-simplebar="init" style={{ height: 270 }}><div className="simplebar-track vertical" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ visibility: 'visible', top: 0, height: 192 }} /></div><div className="simplebar-track horizontal" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ visibility: 'visible', left: 0, width: 25 }} /></div><div className="simplebar-scroll-content" style={{ paddingRight: 15, marginBottom: '-30px' }}><div className="simplebar-content" style={{ paddingBottom: 15, marginRight: '-15px' }}>
                                                <ul>
                                                    <li className="notifications-not-read">
                                                        <a href="dashboard-manage-candidates.html">
                                                            <span className="notification-icon"><i className="icon-material-outline-group" /></span>
                                                            <span className="notification-text">
                                                                <strong>Michael Shannah</strong> applied for a job <span className="color">Full Stack Software Engineer</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="dashboard-manage-bidders.html">
                                                            <span className="notification-icon"><i className=" icon-material-outline-gavel" /></span>
                                                            <span className="notification-text">
                                                                <strong>Gilbert Allanis</strong> placed a bid on your <span className="color">iOS App Development</span> project
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="dashboard-manage-jobs.html">
                                                            <span className="notification-icon"><i className="icon-material-outline-autorenew" /></span>
                                                            <span className="notification-text">
                                                                Your job listing <span className="color">Full Stack PHP Developer</span> is expiring.
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="dashboard-manage-candidates.html">
                                                            <span className="notification-icon"><i className="icon-material-outline-group" /></span>
                                                            <span className="notification-text">
                                                                <strong>Sindy Forrest</strong> applied for a job <span className="color">Full Stack Software Engineer</span>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div></div></div>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div className="header-notifications">
                                    <div className="header-notifications-trigger">
                                        <a href="#"><i className="icon-feather-mail" /><span>3</span></a>
                                    </div>
                                    <div className="header-notifications-dropdown">
                                        <div className="header-notifications-headline">
                                            <h4>Messages</h4>
                                            <button className="mark-as-read ripple-effect-dark" data-tippy-placement="left" data-tippy data-original-title="Mark all as read">
                                                <i className="icon-feather-check-square" />
                                            </button>
                                        </div>
                                        <div className="header-notifications-content">
                                            <div className="header-notifications-scroll" data-simplebar="init" style={{ height: 288 }}><div className="simplebar-track vertical" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ visibility: 'visible', top: 0, height: 183 }} /></div><div className="simplebar-track horizontal" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ visibility: 'visible', left: 0, width: 25 }} /></div><div className="simplebar-scroll-content" style={{ paddingRight: 15, marginBottom: '-30px' }}><div className="simplebar-content" style={{ paddingBottom: 15, marginRight: '-15px' }}>
                                                <ul>
                                                    <li className="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span className="notification-avatar status-online"><img src="images/user-avatar-small-03.jpg" alt="" /></span>
                                                            <div className="notification-text">
                                                                <strong>David Peterson</strong>
                                                                <p className="notification-msg-text">Thanks for reaching out. I'm quite busy right now on many...</p>
                                                                <span className="color">4 hours ago</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span className="notification-avatar status-offline"><img src="images/user-avatar-small-02.jpg" alt="" /></span>
                                                            <div className="notification-text">
                                                                <strong>Sindy Forest</strong>
                                                                <p className="notification-msg-text">Hi Tom! Hate to break it to you, but I'm actually on vacation until...</p>
                                                                <span className="color">Yesterday</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="notifications-not-read">
                                                        <a href="dashboard-messages.html">
                                                            <span className="notification-avatar status-online"><img src="images/user-avatar-placeholder.png" alt="" /></span>
                                                            <div className="notification-text">
                                                                <strong>Marcin Kowalski</strong>
                                                                <p className="notification-msg-text">I received payment. Thanks for cooperation!</p>
                                                                <span className="color">Yesterday</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div></div></div>
                                        </div>
                                        <a href="dashboard-messages.html" className="header-notifications-button ripple-effect button-sliding-icon">View All Messages<i className="icon-material-outline-arrow-right-alt" /></a>
                                    </div>
                                </div> */}
                            </div>
                            <span className="mmenu-trigger">
                                <button className="hamburger hamburger--collapse" type="button">
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner" />
                                    </span>
                                </button>
                            </span>
                        </div>
                        {/* Right Side Content / End */}
                    </div>
                </div>
                {/* Header / End */}
            </header>

        </>
    )
}

export default Header
