import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { setProfileEditValue } from '../../../reducers/profile'
import Modal from '../../../components/common/Modal'
import { setModalVisibility } from '../../../reducers/controls'
import StepThreeLifeStyleInformations from '../../../components/Registration/StepThreeLifeStyleInformations'

const ModelWindow = () => {
    return(
        <Modal modalOwner="lifestyleAndAppearance">
            <div className="mfp-content">
                <div
                    id="small-dialog-2"
                    className="zoom-anim-dialog dialog-with-tabs dialog-extended"
                >
                    <StepThreeLifeStyleInformations />
                </div>
            </div>
        </Modal>
    )
}

const LifestyleAndAppearance = ({
    profile_steps,
    lifestyle_and_appearance,
    setModalVisibility,
    setProfileEditValue,
}) => {


    if (profile_steps.lifestyle_and_appearance === 'false') {
        return (
            <Fragment>
                <ModelWindow/>
                <div className="col-xl-6">
                    <div className="dashboard-box child-box-in-row">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-note-add" /> Lifestyle And Appearance
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <button
                                className="popup-with-zoom-anim button full-width button-sliding-icon"
                                onClick={event => {
                                    event.preventDefault()
                                    setModalVisibility({
                                        isOpen: true,
                                        modalTriggerOwner: 'lifestyleAndAppearance',
                                    })
                                    setProfileEditValue({
                                        data: lifestyle_and_appearance,
                                        key: 'lifestyle_and_appearance',
                                    })
                                }}
                            >
                                Lifestyle And Appearance <i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    const {
        about_yourself,
        body_type,
        contact_email,
        contact_number,
        drinking_habit,
        food_habit,
        height,
        physical_disablity,
        skin_tone,
        smoking_habit,
    } = lifestyle_and_appearance

    return (
        <Fragment>
           <ModelWindow/>
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-fingerprint" /> Lifestyle And Appearance
                        </h3>
                        <button
                            className="mark-as-read ico dark"
                            data-tippy-placement="left"
                            data-tippy
                            data-original-title="Mark all as read"
                            onClick={event => {
                                event.preventDefault()
                                setModalVisibility({
                                    isOpen: true,
                                    modalTriggerOwner: 'lifestyleAndAppearance',
                                })
                                setProfileEditValue({
                                    data: lifestyle_and_appearance,
                                    key: 'lifestyle_and_appearance',
                                })
                            }}
                        >
                            <i className="icon-feather-edit" />
                        </button>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list remove-padding">
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>
                                       What is your body type ?
                                    </strong>
                                    <strong>{body_type}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your height ? </strong>
                                    <strong>
                                        {height}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>what is your skin tone?</strong>
                                    <strong>{skin_tone}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Do you drink?</strong>
                                    <strong>{drinking_habit}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Do you smoke?</strong>
                                    <strong>{smoking_habit}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your food habit?</strong>
                                    <strong>{food_habit}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Do you have any physical disability?</strong>
                                    <strong>{physical_disablity}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>About your self</strong>
                                    <strong className="truncate-text truncate-250">{about_yourself}</strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
})

const mapDispatchToProps = {
    setProfileEditValue,
    setModalVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(LifestyleAndAppearance)
