import React from 'react'
import { Link, navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import {
    foodHabits,
    humanHeight,
    bodyType,
    skinTone,
    yesOrNoExtra,
} from '../../helpers/SelectOptions'

import urls from '../../helpers/urls'
import { createOrUpdateProfile } from '../../reducers/profile'
import SmartRadioSelect from '../InputFields/SmartRadioSelect'
import SmartSelectField from '../InputFields/SmartSelectField'
import inputField from '../InputFields/Input'
import TextArea from '../InputFields/TextArea'
import Loading from '../common/LoadingOverlay'
import {
    required,
    string
} from '../../lib/validationAndWarning'

const StepThreeLifeStyleInformations = ({
    authLocationHistory: { isLoaded, history },
    createOrUpdateProfile,
    handleSubmit
}) => {
    const createStepHandler = async event => {
        const data = {
            method: history.method,
            formName: 'lifestyle_and_appearance'
        }
        await createOrUpdateProfile(data, ({ status, profileId }) => {
            if (status === "OK" && history.method === "new") {
                navigate(urls.stepFour.to.new(profileId))
            }
        });
    }

    if (!isLoaded) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <form onSubmit={handleSubmit(createStepHandler)}>
            <div className="row">
                <div className="col-xl-12">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-book" /> Lifestyle
                                informations
                            </h3>
                        </div>
                        <div className="content with-padding padding-bottom-10">
                            <div className="row">
                                <Field
                                    name="physical_disablity"
                                    type="radio"
                                    component={SmartRadioSelect}
                                    label="Physical disability"
                                    className="account-type-radio"
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'Yes',
                                            icon: 'icon-material-outline-thumb-up',
                                        },
                                        {
                                            value: 'false',
                                            label: 'No',
                                            icon: 'icon-material-outline-thumb-down',
                                        },
                                    ]}
                                />

                                <Field
                                    name="height"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={humanHeight}
                                    validate={[required]}
                                />

                                <Field
                                    name="body_type"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={bodyType}
                                    validate={[required]}

                                />

                                <Field
                                    name="skin_tone"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={skinTone}
                                    validate={[required]}

                                />

                                <Field
                                    name="food_habit"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={foodHabits}
                                    validate={[required]}

                                />

                                <Field
                                    name="smoking_habit"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={yesOrNoExtra}
                                    validate={[required]}

                                />

                                <Field
                                    name="drinking_habit"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={yesOrNoExtra}
                                    validate={[required]}
                                />

                                <Field
                                    name="contact_number"
                                    type="text"
                                    component={inputField}
                                    label="Personal Contact Number (Optional)"
                                    className="with-border"
                                    placeholder="Enter your contact number."
                                />

                                <Field
                                    name="contact_email"
                                    type="email"
                                    component={inputField}
                                    label="Personal Contact email (Optional)"
                                    className="with-border"
                                    placeholder="Enter your email."
                                />

                                <Field
                                    name="about_yourself"
                                    type="text"
                                    component={TextArea}
                                    label="Introduce Yourself"
                                    className="with-border"
                                    placeholder="Introduce Yourself"
                                    alidate={[required]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <button
                        type="submit"
                        className="button ripple-effect big right margin-top-30"
                    >
                        {history.method === 'new' ? (
                            <>
                                Next <i className="icon-feather-arrow-right-circle" />{' '}
                            </>
                        ) : (
                                <>
                                    Update <i className="icon-feather-arrow-right-circle" />{' '}
                                </>
                            )}
                    </button>
                </div>
            </div>
        </form>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'lifestyle_and_appearance',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(StepThreeLifeStyleInformations)

const mapStateToProps = state => ({
    initialValues: state.profile.profileInitialData.lifestyle_and_appearance,
    authLocationHistory: state.controls.authLocationHistory,
})

const mapDispatchToProps = {
    createOrUpdateProfile
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)