import React from 'react'
import { Link } from '@reach/router'

export default props => {
    const { id } = props
    return (
        <div>
            <div className="col-md-12">
                <div className="order-confirmation-page">
                    <div className="col-xl-12">
                        <div className="breathing-icon">
                            <i className="icon-feather-check" />
                        </div>
                        <div className="notification success closeable">
                            <h2 className="margin-top-30">Thank You!</h2>
                            <br />
                            <h3><strong>Congratulations,You have successfully created your profile</strong></h3>
                            <br />
                            <h3>
                                You have to upload at least 3 photos in order to activate your profile. Note: Inappropriate photos will be removed by Admin.
                            </h3>
                        </div>
                    </div>

                    <Link
                        to={`/app/dashboard/profile/${id}/view/details`}
                        className="button ripple-effect-dark button-sliding-icon margin-top-30"
                    >
                        Continue and Upload Photo
                        <i className="icon-material-outline-arrow-right-alt" />
                    </Link>
                </div>
            </div>
        </div>
    )
}
