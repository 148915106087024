import React, { Fragment } from 'react'
import moment from 'moment'
import { fetchCountry } from '../../../lib/resolver'
import CountryWithFlag from '../../../components/common/CountryWithFlag'
import useRequestAndInterest from '../../../hooks/useRequestAndInterest'
import { statusProperties } from '../../../utils/statusProperties'
import { Link } from '@reach/router'
import iconFinder from '../../../lib/iconFinder'

export default ({ id, sent_requests, received_requests }) => {
    const  { acceptRequestHandler, acceptOrRejectRequestHandler  } = useRequestAndInterest('PROFILE_PHOTO_ACCESS_REQUESTS');
    return (
        <div className="row">
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-assignment" /> Sent
                        </h3>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list">
                            {sent_requests.map(({ profile, status, created_at }) => {
                                return (
                                    <li>
                                        <div className="invoice-list-item">
                                            <Link to={`/app/profile/${profile.id}/${profile.reference}`}>
                                                <strong>
                                                    {profile.reference} 
                                                </strong>
                                            </Link>
                                            <ul>
                                                <li>
                                                    <i className={iconFinder(profile.basic_information.gender)}/>{' '}
                                                    {profile.basic_information.gender}
                                                </li>
                                                <li>
                                                    <i className="icon-material-outline-business" />{' '}
                                                    {profile.basic_information.living_city}
                                                </li>
                                                <li>
                                                    <CountryWithFlag {...profile.basic_information} />
                                                    {
                                                        fetchCountry(
                                                            profile.basic_information.living_country
                                                        ).name
                                                    }
                                                </li>
                                                <li>{moment(created_at).fromNow()}</li>
                                                <li>
                                                    <span
                                                            className={`badge ${statusProperties[status].badge}`}
                                                        >
                                                        {statusProperties[status].message}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* Buttons */}
                                        {/* <div className="buttons-to-right">
                                            <a href="pages-checkout-page.html" className="button">
                                                Accept
                                                </a>
                                            <a href="pages-checkout-page.html" className="button">
                                                Reject
                                            </a>
                                        </div> */}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-assignment" /> Received
                        </h3>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list">
                            {received_requests.map(({ profile, status, created_at }) => {
                                return (
                                    <li>
                                        <div className="invoice-list-item">
                                            <Link to={`/app/profile/${profile.id}/${profile.reference}`}>
                                                <strong>
                                                    {profile.reference} 
                                                </strong>
                                            </Link>
                                            <ul>
                                                <li>
                                                <i className={iconFinder(profile.basic_information.gender)} />{' '}
                                                    {profile.basic_information.gender}
                                                </li>
                                                <li>
                                                    <i className="icon-material-outline-business" />{' '}
                                                    {profile.basic_information.living_city}
                                                </li>
                                                <li>
                                                    <CountryWithFlag {...profile.basic_information} />
                                                    {
                                                        fetchCountry(
                                                            profile.basic_information.living_country
                                                        ).name
                                                    }
                                                </li>
                                                <li>{moment(created_at).fromNow()}</li>
                                                <li>
                                                    <span
                                                            className={`badge ${statusProperties[status].badge}`}
                                                        >
                                                        {statusProperties[status].message}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* Buttons */}
                                        <div className="buttons-to-right">
                                            <button className="button" onClick={() => {
                                                acceptOrRejectRequestHandler({
                                                    id, 
                                                    profile, 
                                                    requestType: "PHOTO_VIEW",
                                                    status: status === 'IN_REVIEW' ? "APPROVED" : status ==='APPROVED' ?  'REJECTED' : 'APPROVED' 
                                                });
                                            }}>
                                                {status === 'IN_REVIEW' ? "Approve" : status ==='APPROVED' ?  'Revoke' : 'Approve' }
                                            </button>
                                            {/* <button  className="button">
                                                Block
                                            </button> */}
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
