import React, { Fragment } from 'react'
import urls from '../../helpers/urls'
import { navigate, Link } from '@reach/router'
import { hardReset } from '../../reducers/profile'
import CountryWithFlag from '../../components/common/CountryWithFlag'
import Loading from '../../components/common/Loading'
import PhotoGalleryPrivate from '../../components/common/PhotoGalleryPrivate'
import moment from 'moment'
import { fetchCountry } from '../../lib/resolver'
import { useUserProfiles } from '../../hooks/useHooks'
import StartNew from '../../components/common/StartNew'
import toChangeCase from '../../utils/prototype'
import iconFinder from '../../lib/iconFinder'

const List = profile => {
    const {
        id,
        reference,
        avatar,
        created_at,
        basic_information,
        status_properties,
        number_of_photos,
        is_horoscope_added,
        profile_steps,
    } = profile

    return (
        <li key={reference}>
            <div className="freelancer-overview manage-candidates">
                <div className="freelancer-overview-inner">
                    <div>
                        <PhotoGalleryPrivate profile={profile} profilePhotoUrl={avatar} />
                    </div>
                    <div className="freelancer-name">
                        <h3>
                            <Link to={`/app/dashboard/profile/${id}/view/details`}>
                                {basic_information.first_name} {basic_information.last_name} |{' '}
                                {reference} <CountryWithFlag {...basic_information} />
                            </Link>
                        </h3>
                        <span className="freelancer-detail-item">
                            Created by {basic_information.profile_created_by}{' '}
                            {moment(created_at).fromNow()}
                        </span>
                        <br />
                        <span className="freelancer-detail-item">
                            <i className={iconFinder(basic_information.gender)} />{' '}
                            {basic_information.gender.toChangeCase('readable-upper-each')}
                        </span>
                        <span className="freelancer-detail-item">
                            <i className="icon-material-outline-business" />{' '}
                            {basic_information.living_city}
                        </span>
                        <span className="freelancer-detail-item">
                            <i className="icon-material-outline-business" />{' '}
                            {fetchCountry(basic_information.living_country).name}
                        </span>
                        <br />
                        {Object.keys(profile_steps).map(key => {
                            return (
                                <span className="freelancer-detail-item">
                                    <span
                                        className={`badge ${
                                            profile_steps[key] === 'true' ? 'success' : 'danger'
                                            }`}
                                    >
                                        {profile_steps[key] === 'true' ? (
                                            <i className="icon-feather-check-circle" />
                                        ) : (
                                                <i className="icon-material-outline-highlight-off" />
                                            )}
                                        {key.toChangeCase('readable-upper-each')}
                                    </span>
                                </span>
                            )
                        })}
                        <br />
                        <span className="freelancer-detail-item">
                            <span className="badge info">
                                <i className="icon-feather-image" /> {number_of_photos}{' '}
                                {number_of_photos > 1 ? 'Photos' : 'Photo'} Available{' '}
                            </span>
                        </span>
                        {is_horoscope_added === 'true' && (
                            <span className="freelancer-detail-item">
                                <span className="badge info">
                                    <i className="icon-material-baseline-star-border" /> Horoscope
                                    Available
                                </span>
                            </span>
                        )}
                        <span className={`badge ${status_properties.badge}`}>
                            <i className={status_properties.icon} />{' '}
                            {status_properties.message}
                        </span>
                        <div className="buttons-to-right always-visible margin-top-25 margin-bottom-0">
                            <Link
                                to={`/app/profile/${id}/${reference}`}
                                className="button big button-sliding-icon ripple-effect"
                            >
                                Preview <i className="icon-material-outline-arrow-right-alt" />
                            </Link>
                            <Link
                                to={`/app/dashboard/profile/${id}/view/details`}
                                className="button big button-sliding-icon ripple-effect"
                            >
                                Edit <i className="icon-material-outline-arrow-right-alt" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default () => {
    const hookProps = useUserProfiles()
    const { dispatch, loading, userProfiles } = hookProps

    if (loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-xl-12">
                    <div className="float-right" style={{ marginBottom: 20 }}>
                        <button
                            onClick={event => {
                                event.preventDefault()
                                dispatch(hardReset())
                                navigate(urls.stepOne.to.new())
                            }}
                            className="button ripple-effect big margin-top-30 pull-right"
                        >
                            <i className="icon-feather-plus" /> New Profile
                        </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-12">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-supervisor-account" />
                                {userProfiles.length} Profiles(s)
                            </h3>
                        </div>
                        <div className="content">
                            <ul className="dashboard-box-list">
                                {userProfiles.map((profile, index) => {
                                    return <List key={index} {...profile} {...hookProps} />
                                })}
                            </ul>
                            {userProfiles.length === 0 && (
                                <StartNew
                                    path={urls.stepOne.to.new()}
                                    text="Create New Profile"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
