import React, { useEffect, Fragment }from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import SearchResults from '../components/Search/SearchResults'
import SmartRadioSelect from '../components/InputFields/SmartRadioSelectPure'
import SmartArraySelect from '../components/InputFields/SmartArraySelect'
import Loading from '../components/common/Loading'
import TwoThumbsRangeSlider from '../components/common/TwoThumbsRangeSlider'
import { fetchCountry } from '../lib/resolver'
import { useProfileSearch , useScroll, useShortlist} from '../hooks/useHooks'
import Meta from '../components/common/Meta'

// https://www.youtube.com/watch?v=NZKUirTtxcg
// https://github.com/redux-form/redux-form/issues/4317
// https://github.com/WebDevSimplified/React-Infinite-Scrolling

const SearchPage = () => {
    const { scrollState } = useScroll();
    const {
        loading,
        allProfiles
    } = useProfileSearch(scrollState);

    const { dispatch, fetchUserShortlist } = useShortlist();

    useEffect(() => {
        dispatch(fetchUserShortlist());
    }, [])

    if (loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <Fragment>
            <Meta
                title={'Search - Lankanwedding.com'}
            />
            <div className="margin-top-90"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 sticky-col">
                        <div className="sidebar-container">
                            <div className="sidebar-widget">
                                <h3>I'm looking for a</h3>
                                <Field
                                    name="gender"
                                    type="radio"
                                    component={SmartRadioSelect}
                                    className="account-type-radio"
                                    cssFix="remove-flex"
                                    column="full-width"
                                    options={[
                                        {
                                            value: 'null',
                                            label: 'All',
                                            icon: 'icon-line-awesome-transgender',
                                        },
                                        {
                                            value: 'male',
                                            label: 'Male',
                                            icon: 'icon-line-awesome-male',
                                        },
                                        {
                                            value: 'female',
                                            label: 'Female',
                                            icon: 'icon-line-awesome-female',
                                        },
                                    ]}
                                />
                            </div>
                            <div className="sidebar-widget">
                                <h3>Age Between</h3>
                                <div class="margin-top-55"></div>
                                <Field
                                    name="ageRange"
                                    type="text"
                                    component={TwoThumbsRangeSlider}
                                />
                            </div>

                            <div className="sidebar-widget">
                                <h3>Religion</h3>

                                <Field
                                    name="religion"
                                    type="checkbox"
                                    component={SmartArraySelect}
                                    options={allProfiles.profile_count_based_religion.map(
                                        value => ({
                                            value: value.religion,
                                            label: value.religion
                                        })
                                    )}
                                />
                                <div className="clearfix" />
                            </div>


                            <div className="sidebar-widget">
                                <h3>Country</h3>

                                <Field
                                    name="country"
                                    type="checkbox"
                                    component={SmartArraySelect}
                                    options={allProfiles.profile_count_based_country.map(
                                        value => ({
                                            value: value.living_country,
                                            label: fetchCountry(value.living_country).name,
                                        })
                                    )}

                                />
                                <div className="clearfix" />
                            </div>

                            <div className="sidebar-widget">
                                <h3>Marital Status</h3>
                                <Field
                                    name="maritalStatus"
                                    type="checkbox"
                                    component={SmartArraySelect}
                                    options={allProfiles.profile_count_based_status.map(
                                        value => ({
                                            value: value.marital_status,
                                            label: value.marital_status
                                        })
                                    )}
                                />
                                <div className="clearfix" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-9 col-lg-8 content-left-offset" id="flux">
                        <h3 className="page-title">Search Results</h3>
                        <div className="freelancers-container freelancers-grid-layout margin-top-35">
                            {allProfiles.profiles.map((profile, index) => {
                                return <SearchResults key={index} {...profile} />
                            })}
                        </div>
                        <div className="clearfix" />
                        <center>
                            <div>{loading && <Loading/>}</div>
                        </center>             
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const initialSearchParams = {
    country: null,
    gender: null,
    maritalStatus: null,
    religion: null,
    ageRange: [null, null],
}

const InitializeFromStateForm = reduxForm({
    form: 'profileSerchForm',
})(SearchPage)

const mapStateToProps = state => ({
    initialValues: initialSearchParams,
})

export default connect(
    mapStateToProps,
    null
)(InitializeFromStateForm)
