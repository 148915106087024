import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../../../components/InputFields/Input'
import SmartSelectField from '../../../components/InputFields/SmartSelectField'
import Countries from '../../../helpers/Countries'
import { required, email, number } from '../../../lib/validationAndWarning'
import { updateProfile } from '../../../reducers/auth'
import { currentUserResolver } from '../../../lib/resolver'   // import your preferred style
// import { CodeHighlight } from '../../common/CodeHighlight'


const Profile = ({
    handleSubmit,
    updateProfile,
    initialValues,
}) => {
    return (
        <article>
            {/* <CodeHighlight>
                {JSON.stringify(initialValues, null, 4)}
            </CodeHighlight> */}
            <form onSubmit={handleSubmit(updateProfile)}>
                <div className="col-xl-12">
                    <div id="test1" className="dashboard-box">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-lock" /> Password Security
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <div className="row">
                                <div className="col-auto">
                                    <div
                                        className="avatar-wrapper"
                                        data-tippy-placement="bottom"
                                        data-tippy
                                        data-original-title="Change Avatar"
                                    >
                                        <img className="profile-pic" src={initialValues.avatarUrl} alt="User" />
                                        <div className="upload-button" />
                                        <input
                                            className="file-upload"
                                            type="file"
                                            accept="image/*"
                                        />
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <Field
                                            id="username"
                                            name="username"
                                            type="text"
                                            component={inputField}
                                            label="Username"
                                            className="with-border"
                                            placeholder="Username"
                                            column="col-xl-6"
                                            validate={[required]}
                                            disabled="true"
                                            help={true}
                                            info="It’s not possible to change your username, but you don’t need to remember it. You can log in with your email address and password, "
                                        />
                                        <Field
                                            id="email"
                                            name="email"
                                            type="email"
                                            component={inputField}
                                            label="Email"
                                            className="with-border"
                                            placeholder="Email"
                                            column="col-xl-6"
                                            validate={[required, email]}
                                        />
                                        <Field
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            component={inputField}
                                            label="First Name"
                                            className="with-border"
                                            placeholder="First Name"
                                            column="col-xl-6"
                                            validate={[required]}
                                        />
                                        <Field
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            component={inputField}
                                            label="Last Name"
                                            className="with-border"
                                            placeholder="Last Name"
                                            column="col-xl-6"
                                            validate={[required]}
                                        />

                                        <Field
                                            name="country"
                                            type="text"
                                            component={SmartSelectField}
                                            className="with-border"
                                            dataSource={Countries}
                                            validate={[required]}
                                            column="col-xl-6"
                                        />

                                        <Field
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            type="text"
                                            component={inputField}
                                            label="Phone Number"
                                            className="with-border"
                                            placeholder="Phone"
                                            column="col-xl-6"
                                            validate={[required, number]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 ">
                    <div className="">
                        <button
                            type="submit"
                            className="button ripple-effect big margin-top-30"
                        >
                            Save the changes
                        </button>
                    </div>
                </div>
            </form>
        </article>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'profileForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Profile)

const mapStateToProps = state => ({
    initialValues: currentUserResolver(state.auth.currentUser),
})

const mapDispatchToProps = {
    updateProfile,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
