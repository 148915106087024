import React from 'react'
import VerifyEmailAddress from '../components/Auth/verifyEmailAddress'

const EmailVerification = (props) => {

    // useEffect(() => {
    //     document.getElementById('footer').className = 'footer-fixed';
    // }, [])
    
    return (
        <div  className="container">
            <VerifyEmailAddress />
        </div>
    )
}

export default EmailVerification;