import React, { Fragment } from 'react';
import moment from 'moment'

export default ({ currentUser }) => {
    return (
        <Fragment>
            <div className="col-md-12">
                <h2>{currentUser.subscription.plan.name}</h2>
                <span>Current Plan {typeof currentUser.subscription !== 'undefined' && `expires on ${moment(currentUser.subscription.ends_at).format('DD/MM/YYYY')}`}</span>

                <nav id="breadcrumbs" className="dark">
                    <ul>
                        <li>Your Pay as you go balance: {currentUser.accountBalance}</li>
                    </ul>
                </nav>
            </div>
            <br/>
            <div className="col-md-12">
                <div className="notification notice closeable">
                    <p>
                        1. Pay as you go members: {currentUser.subscription.plan.contact_price} credits will be debited from your pay as you go 
                        balance when you get a contact number.
                    </p>
                    <p>
                        2. Premium members: Premium plan benefits  
                    </p>
                </div>
            </div>
        </Fragment>
    );
};
