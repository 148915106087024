import React from 'react'
import Loading from '../../components/common/Loading'
import { fetchCountry } from '../../lib/resolver'
import { useRequestAndInterest } from '../../hooks/useHooks'

export default ({ parent }) => {

    const {
        loading,
        allUserProfiles,
        hasItBeenAlreadySent,
        sendRequestToAccessHandler
    } = useRequestAndInterest(parent);

    if (loading) {
        return <center> <Loading /></center>
    }

    const { profiles } = allUserProfiles;

    return (
        <div className="listings-container compact-list-layout margin-top-35" style={{ width: 650 }}>
            {profiles.map((profile, index) => {
                return (
                    <div
                        key={index}
                        className="job-listing with-apply-button"
                    >
                        <div className="job-listing-details">
                            <div className="job-listing-company-logo">
                                <img
                                    style={{ width: 50 }}
                                    src={profile.profile_photo !== "null" ? profile.profile_photo : profile.avatar}
                                    alt={profile.reference}
                                />
                            </div>
                            <div className="job-listing-description">
                                <h3 className="job-listing-title">
                                    {profile.reference} | {profile.basic_information.last_name} {profile.basic_information.first_name}
                                </h3>
                                <div className="job-listing-footer">
                                    <ul>
                                        <li>
                                            <i className="icon-material-outline-business" /> {profile.basic_information.living_city}

                                        </li>
                                        <li>
                                            <i className="icon-material-outline-location-on" /> {fetchCountry(profile.basic_information.living_country).name}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {profile.profile_status !== 'VERIFIED' ? <a>Not Verified</a> : hasItBeenAlreadySent(profile) ? <a>Already Sent</a> : <button className="list-apply-button ripple-effect" onClick={(event) => {
                                event.preventDefault();
                                sendRequestToAccessHandler(profile)
                            }}><i className="icon-feather-bell" /> Send Interest</button>
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}