import React, { Fragment, useState } from 'react'
import Meta from '../components/common/Meta'

const PrivacyPolicy = () => {
  return (
    <Fragment>
        <Meta
            title={'Privacy - Lankanwedding.com'}
        />
      <h1>Privacy Policy of Lankan Wedding</h1>

      <p>
        <b>Lankanwedding.com</b> is an online matrimonial portal endeavouring
        constantly to provide you with matrimonial services. This privacy
        statement is common to all the matrimonial Website/apps operated under
        <b>Lankanwedding.com</b> Since we are strongly committed to your right
        to privacy, we have drawn out a privacy statement with regard to the
        information we collect from you. You acknowledge that you are disclosing
        information voluntarily. By accessing /using the website/apps and/or by
        providing your information, you consent to the collection and use of the
        info you disclose on the website/apps in accordance with this Privacy
        Policy. If you do not agree for use of your information, please do not
        use or access this website/apps.
      </p>
    </Fragment>
  )
}

const WhatInformationWeCollect = () => {
  return (
    <Fragment>
      <h1>What information does Lankanwedding.com collect from you?</h1>

      <p>
        The information we gather from members and visitors who apply for the
        various services our website/apps offers includes, but may not be
        limited to, email address, name, date of birth, educational
        qualifications a user-specified password, mailing address, zip/pin code
        and telephone/mobile number or fax number.
      </p>

      <p>
        Wd don not store any of your credit card details in our server. All the
        subscription related activities are handled by Stripe. Stripe is an
        American technology company that allows individuals and businesses to
        make and receive payments over the Internet. Payment Terms are terms
        that apply to the use of Payment Methods. You can decide whether or not
        to use a Payment Method. However, if you use a Payment Method, you
        accept and agree to the terms applicable to that Payment Method, which
        will form part of your agreement with Stripe. If you have any question
        about how secure is you payment with <b>Lankanwedding.com</b> please
        read this document which is provided by Strip <a href="https://stripe.com/docs/security/stripe" target="_blank">
          Security at Stripe
        </a>
      </p>

      <p>
        The user information we collect depends on the context of your
        interactions with us and the website or Apps, the choices you make and
        the products and features you use. The User Information is used for
        authentication and account access, If a user registers using social
        networking platforms such as Facebook, Google, LinkedIn and others we
        may collect personal data you choose to allow us to access through their
        APIs. When the user accesses our websites or apps, data relating to
        device ID, log files ,Geographic Location, device
        Information/specification are also collected automatically.
      </p>

      <p>
        We may use also your personal information for analysis of data, usage
        trends and to evaluate and improve our site/App, marketing research ,
        preventing of frauds. In our efforts to continually improve our product
        and service offerings, we collect and analyse demographic and profile
        data about our users' activity on our website/apps. We identify and use
        your IP address to help diagnose problems with our server, and to
        administer our website/apps. Your IP address is also used to help
        identify you and to gather broad demographic information.
      </p>
    </Fragment>
  )
}

const HowOurWebsiteUserTheInformation = () => {
  return (
    <Fragment>
      <h1>How we use information we collect?</h1>

      <p>We use the information collected in the following ways</p>
      <p>
        <ol>
          <li>
            We use the information submitted by you to provide the Service.
          </li>
          <li>Manage your account</li>
          <li>Provide you with customer support</li>
          <li>
            Conduct research and analysis about your use of our services for
            providing better services
          </li>
          <li>
            Communicate with you by email, phone about services, promotions or
            offers(Optional)
          </li>
          <li>
            Recommend relevant matches to you and display your profile to other
            users.
          </li>
        </ol>
      </p>
    </Fragment>
  )
}

const WithWhomWeShareYourInformation = () => {
  return (
    <Fragment>
      <h1>With whom the website/app(s) shares the information it collects?</h1>

      <p>
        Except where you are expressly informed on the site or as described in
        this privacy policy we do not sell, rent, share, trade or give away any
        of your personal information.
      </p>
      <p>
        We publish the information shared by you with other users to provide the
        services. The information so published are provided by you and be
        cautious as what you share with other users. You can always control your
        privacy setting in the appropriate profile page.
      </p>
      <p>
        We may use third party service providers to provide website and
        application development, hosting, maintenance, backup, storage, payment
        processing, analysis and other services for us, which may require them
        to access or use information about you. If a service provider needs to
        access information about you to perform services on our behalf, they do
        so under close instruction from us, including policies and procedures
        designed to protect your information. All of our service providers and
        partners agree to strict confidentiality obligations.
      </p>

      <p>
        Where required or permitted by law, information may be provided to
        others, such as regulators and law enforcement agencies or to protect
        the rights ,property or personal safety of other members or the general
        public . We may voluntarily share your information with law enforcement
        agencies / Gateway service providers / anti-fraud solution provider(s)
        if we feel that the transaction is of suspicious nature.
      </p>

      <p>
        Our website/apps links to other website/apps that may collect personally
        identifiable information about you. We are not responsible for the
        privacy policy or the contents of those linked website/apps.
      </p>
    </Fragment>
  )
}

const ForEuropeanUnionMembers = () => {
  return (
    <Fragment>
      <h1>For European Union Members (EU)</h1>

      <p>
        If you are located in the EU, you will be asked to provide consent to
        the collection, processing, and sharing of your personal information.
        Personal information means any information related to an identified or
        identifiable natural person. You have the right to share and access your
        personal information and right to withdraw consent for sharing your
        personal information at any point of time and right to erase your
        personal information subject to applicable laws. for sharing your
        personal information at any point of time. You can withdraw your consent
        provided by contacting us. Your personal information may be stored in
        databases located outside of the EU including in India. Where we
        transfer personal data outside of EU, we either transfer personal
        information to countries that provide an adequate level of protection or
        we have appropriate safeguards in place. We may require proof of or need
        to verify your identity before we can give effect to these rights. To
        request to review, update, or delete your personal information, We may
        share your information with third parties who are an anti-fraud solution
        provider(s) located in UK. They help us to ensure we keep you safe from
        scammers and fraudster.
      </p>
    </Fragment>
  )
}

const HowLongWeKeepYourInformation = () => {
  return (
    <Fragment>
      <h1>How long we keep your information?</h1>

      <p>
        We keep your personal information only as long as you use our service
        and also as permitted/required by applicable law. In practice, we delete
        or anonymize your information upon deletion of your account, unless the
        same is required for to comply with legal obligations, fraud prevention,
        take actions we deem necessary to protect the integrity of our website
        or our users, to resolve disputes, to enforce our agreements, to support
        business operations, and to continue to develop and improve our
        Services. We retain information for better services, and we only use the
        information to analyse about the use of our Services, not to
        specifically analyse personal characteristics about you.
      </p>
    </Fragment>
  )
}

const SecurityPrecautions = () => {
  return (
    <Fragment>
      <h1>
        What are the Security Precautions in respect of your personal
        information?
      </h1>

      <p>
        We aim to protect your personal information through a system of
        organizational and technical security measures. We have implemented
        appropriate internal control measures designed to protect the security
        of any personal information we process. However, please also remember
        that we cannot guarantee that the internet itself is 100% secure. Once
        your information is in our possession, we adhere to security guidelines
        protecting it against unauthorised access.
      </p>
    </Fragment>
  )
}

const ChangePrivacyPolicy = () => {
  return (
    <Fragment>
      <h1>Change of Privacy Policy</h1>

      <p>
        We may change this Privacy Policy without notice from time to time
        without any notice to you. However, changes will be updated in the
        Privacy Policy page.
      </p>
    </Fragment>
  )
}

const Privacy = () => {
  const [currentChild, setCurrentChild] = useState('PRIVACY_POLICY')
  const activeContent = component => {
    switch (component) {
      case 'PRIVACY_POLICY':
        return <PrivacyPolicy />
      case 'WHAT_INFORMATION_WE_COLLECT':
        return <WhatInformationWeCollect />
      case 'HOW_WE_USE_THE_INFORMATION':
        return <HowOurWebsiteUserTheInformation />
      case 'WITH_WHOM_WE_SHARE_YOUR_INFORMATION':
        return <WithWhomWeShareYourInformation />
      case 'FOR_EUROPEAN_UNION_MEMBERS':
        return <ForEuropeanUnionMembers />
      case 'HOW_LONG_WE_KEEP_YOUR_INFORMATION':
        return <HowLongWeKeepYourInformation />
      case 'SECURITY_PRECAUTIONS':
        return <SecurityPrecautions />
      case 'CHANGE_PRIVACY_POLICY':
        return <ChangePrivacyPolicy />
      default:
        break
    }
  }

  const topic = ({ action, title }) => {
    return (
      <li
        className={`list-group-item ${currentChild === action && 'active'}`}
        onClick={() => setCurrentChild(action)}
      >
        {title}
      </li>
    )
  }

  return (
    <div className="container lw-privacy-policy">
      <div className="row">
        <div className="col-md-4">
          <ul className="list-group">
            {[
              {
                action: 'PRIVACY_POLICY',
                title: 'Privacy Policy of Lankan Wedding',
              },
              {
                action: 'WHAT_INFORMATION_WE_COLLECT',
                title: 'Information Collection and Use',
              },
              {
                action: 'HOW_WE_USE_THE_INFORMATION',
                title: 'How we use information we collect ?',
              },
              {
                action: 'WITH_WHOM_WE_SHARE_YOUR_INFORMATION',
                title: 'With whom we share your information ?',
              },
              {
                action: 'FOR_EUROPEAN_UNION_MEMBERS',
                title: 'For European Union Members (EU)',
              },
              {
                action: 'HOW_LONG_WE_KEEP_YOUR_INFORMATION',
                title: 'How long we keep your information ?',
              },
              {
                action: 'SECURITY_PRECAUTIONS',
                title: 'Security Precautions',
              },
              {
                action: 'CHANGE_PRIVACY_POLICY',
                title: 'Change of Privacy Policy',
              },
            ].map(value => topic(value))}
          </ul>
        </div>

        <div className="col-md-8">
          <div className="content">{activeContent(currentChild)}</div>
        </div>
      </div>
    </div>
  )
}

export default Privacy
