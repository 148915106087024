import React from 'react'
import $ from 'jquery'
import Select from 'react-select'
import { inputFieldProperties } from '../../helpers/InputFieldProperties';

const initialValue = (data, value) => data.find(val => val.value === value);

const customStyles = {
    input: styles => {
        return {
            ...styles,
            height: '40px'
        };
    },
    singleValue: styles => {
        return {
            ...styles,
            top: '62%'
        }
    },
    placeholder: styles => {
        return {
            ...styles,
            top: '55%'
        }
    }
}

const SmartSelect = (props) => {
    const {
        className,
        help,
        info,
        column,
        disabled = false,
        dataSource,
        defaultValue = [],
        input: { name, onChange },
        meta: { touched, error, warning, initial }
    } = props;

    if (touched && error) {
        $(`.${name}`).addClass('smart-select-input-error animated shake');
    }

    if (touched && !error) {
        $(`.${name}`).removeClass('smart-select-input-error animated shake');
    }

    const handleChagne = (response) => {
        onChange(response.value);
    }

    const onFocusHandler = () => {
        $(`.${name}`).removeClass('smart-select-input-error animated shake');
    }

    return (
        <div className={column || "col-xl-4"}>
            <div className="submit-field">
                {!touched && <h5 id="inputLabel">
                    {inputFieldProperties[name].label}
                    {help &&
                        <span className="tool" data-tip={`${info}`} >
                            <i class="help-icon" data-tippy-placement="right"></i>
                        </span>
                    }
                </h5>}

                {(touched && !error) && <h5 id="inputLabel">
                    {inputFieldProperties[name].label}
                    {help &&
                        <span className="tool" data-tip={`${info}`} >
                            <i class="help-icon" data-tippy-placement="right"></i>
                        </span>
                    }
                </h5>}

                {touched &&
                    ((error && <div className="text-error">{error}</div>) ||
                        (warning && <span>{warning}</span>))}

                <div className={name}>
                    <Select
                        styles={customStyles}
                        defaultValue={[initialValue(dataSource, initial)]}
                        options={dataSource}
                        className={className}
                        onChange={handleChagne}
                        disabled={disabled}
                        onFocus={onFocusHandler}
                    // classNamePrefix="select"
                    />
                </div>
            </div>
        </div>
    )
}

export default SmartSelect;
