import React from 'react'
import { Link, navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import inputField from '../InputFields/Input'
import SmartRadioSelect from '../InputFields/SmartRadioSelect'
import {
    religion,
    motherTongue,
    residenceStatus,
    maritialStatus,
    community,
    profileCreatedBy,
    yesNoWithDontKnow,
    yesOrNo,
    numberOfSiblings
} from '../../helpers/SelectOptions'
import Countries from '../../helpers/Countries'
import { nationalities } from '../../helpers/CountriesAndNationalities'
import urls from '../../helpers/urls'
import { setDateOfBirth, createOrUpdateProfile } from '../../reducers/profile'
import DatePickerLatest from '../common/DatePicker'
import Loading from '../common/LoadingOverlay'
import SmartSelectField from '../InputFields/SmartSelectField'
import {
    required,
    string
} from '../../lib/validationAndWarning'


const maritstatus = (value) => ["widowed", "divorced", "awaiting divorce", "separated"].includes(value);

const StepTwoProfileBasicDetails = ({
    authLocationHistory: { isLoaded, history },
    createOrUpdateProfile,
    formValues,
    handleSubmit
}) => {

    const createStepHandler = async event => {
        const data = {
            method: history.method,
            formName: 'basic_information',
        }
        
        await createOrUpdateProfile(data, ({ status, profileId }) => {
            if(status === "OK" && history.method === "new") {
                navigate(urls.stepTwo.to.new(profileId))
            }
        });
    }

    if (!isLoaded) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <form onSubmit={handleSubmit(createStepHandler)}>
            <div className="row">
                <div className="col-xl-12">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-face" /> Profile basic
                                information
                            </h3>
                        </div>

                        <div className="content with-padding padding-bottom-10">
                            <div className="row">
                                <Field
                                    id="profile_created_by"
                                    name="profile_created_by"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={profileCreatedBy}
                                    help={true}
                                    info=" Please select your relationship with the person looking to get married."
                                    validate={[required]}
                                />

                                <Field
                                    id="first_name"
                                    name="first_name"
                                    type="text"
                                    component={inputField}
                                    label="First Name"
                                    className="with-border"
                                    placeholder="Enter your first name"
                                    validate={[required,string]}
                                    help={true}
                                    info="First name is required only for administrative and other official purposes, it won't be displayed anywhere"
                                />

                                <Field
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    component={inputField}
                                    label="Last Name"
                                    className="with-border"
                                    placeholder="Enter your last name"
                                    help={true}
                                    info="Last name is required only for administrative and other official purposes, it won't be displayed anywhere"
                                    validate={[required,string]}
                                />

                                <Field
                                    id="dob"
                                    name="dob"
                                    type="text"
                                    component={DatePickerLatest}
                                    label="Date of birth"
                                    className="with-border"
                                    placeholder="Select your date of birth"
                                    help={true}
                                    info="You can only register a profile if the age is above 18"
                                    validate={[required]}
                                />

                                <Field
                                    id="gender"
                                    name="gender"
                                    type="radio"
                                    component={SmartRadioSelect}
                                    label="Gender"
                                    className="account-type-radio"
                                    options={[
                                        {
                                            value: 'male',
                                            label: 'Male',
                                            icon: 'icon-line-awesome-male',
                                        },
                                        {
                                            value: 'female',
                                            label: 'Female',
                                            icon: 'icon-line-awesome-female',
                                        }
                                    ]}
                                    validate={[required]}
                                />

                                <Field
                                    id="religion"
                                    name="religion"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={religion}
                                    validate={[required]}
                                />

                                {formValues.religion === "hindu" && <Field
                                    name="chevvai_dosham"
                                    type="radio"
                                    component={SmartSelectField}
                                    label="Chevvai Dosham"
                                    className="account-type-radio"
                                    dataSource={yesNoWithDontKnow}
                                />}

                                <Field
                                    id="mother_tongue"
                                    name="mother_tongue"
                                    type="text"
                                    component={SmartSelectField}
                                    label="Mother Tongue"
                                    className="with-border"
                                    placeholder="Select your Mother Tongue"
                                    dataSource={motherTongue}
                                    validate={[required]}
                                />

                                <Field
                                    name="primary_language"
                                    type="text"
                                    component={SmartSelectField}
                                    label="Mother Tongue"
                                    className="with-border"
                                    placeholder="Select your primary language"
                                    dataSource={motherTongue}
                                    validate={[required]}
                                />

                                <Field
                                    id="caste"
                                    name="caste"
                                    type="text"
                                    component={SmartSelectField}
                                    label="Caste"
                                    className="with-border"
                                    placeholder="Select your Caste"
                                    dataSource={community}
                                    help={true}
                                    info="Caste is not mandatory"
                                    validate={[required]}
                                />

                                <Field
                                    name="marital_status"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    placeholder="Select your Meritial Status"
                                    dataSource={maritialStatus}
                                    validate={[required]}
                                />

                                {maritstatus(formValues.marital_status) && <Field
                                    name="children"
                                    type="text"
                                    component={SmartSelectField}
                                    className="account-type-radio"
                                    dataSource={yesOrNo}
                                />}
                                
                                {formValues.children === "yes" && <Field
                                    id="number_of_children"
                                    name="number_of_children"
                                    type="number"
                                    component={SmartSelectField}
                                    label="Number of children"
                                    className="with-border"
                                    placeholder="Select number of children"
                                    dataSource={numberOfSiblings}
                                />}

                                <Field
                                    id="living_city"
                                    name="living_city"
                                    type="text"
                                    component={inputField}
                                    label="Living City"
                                    className="with-border"
                                    placeholder="Enter your city"
                                    validate={[required,string]}
                                />

                                <Field
                                    id="living_state"
                                    name="living_state"
                                    type="text"
                                    component={inputField}
                                    label="Living State"
                                    className="with-border"
                                    placeholder="Enter your state"
                                    validate={[required,string]}
                                />

                                <Field
                                    name="living_country"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={Countries}
                                    validate={[required]}
                                />
                
                                <Field
                                    name="residence_status"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={residenceStatus}
                                    validate={[required]}
                                />

                                <Field
                                    name="nationality"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={nationalities}
                                    validate={[required]}
                                />

                                <Field
                                    name="country_of_birth"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={Countries}
                                    validate={[required]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <button
                        type="submit"
                        className="button ripple-effect big right margin-top-30"
                    >
                        {history.method === 'new' ? (
                            <>
                                Next <i className="icon-feather-arrow-right-circle" />{' '}
                            </>
                        ) : (
                                <>
                                    Update <i className="icon-feather-arrow-right-circle" />{' '}
                                </>
                            )}
                    </button>
                </div>
            </div>
        </form>
    )
}


const InitializeFromStateForm = reduxForm({
    form: 'basic_information',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true
})(StepTwoProfileBasicDetails)

const selector = formValueSelector('basic_information') 

const mapStateToProps = state => ({
    initialValues: state.profile.profileInitialData.basic_informations,
    authLocationHistory: state.controls.authLocationHistory,
    formValues: selector(state, 'religion', 'marital_status', 'children') 
})

const mapDispatchToProps = {
    setDateOfBirth,
    createOrUpdateProfile,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
