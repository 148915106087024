import React from 'react'
import { Link } from '@reach/router'

const StartNew = ({
    path,
    text
}) => {
    return (
        <center>
            <div className="col-xl-12">
                <Link to={path} className="button ripple-effect big margin-top-30 margin-bottom-20 ">
                    <i className="icon-material-outline-add-circle-outline margin-right-10"></i>
                    {text}
                </Link>
            </div>
        </center>
    )
}

export default StartNew
