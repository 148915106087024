import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import { uploadFile } from '../../../reducers/upload'
import PrivacyHandler from '../../../components/common/PrivacyHandler'
import {
    removeHoroscope,
    getProfileHoroscopes,
} from '../../../reducers/horoscope'
import Loading from '../../../components/common/Loading'

const readFile = file => {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const UploadHoroscope = props => {
    const {
        id,
        reference,
        uploadFile,
        horoscope,
        removeHoroscope,
        getProfileHoroscopes,
        horoscopeImages,
        loading
    } = props

    useEffect(() => {
        getProfileHoroscopes(id);
    }, [])

    const handleChange = async e => {
        const file = e.target.files[0]
        const fileData = await readFile(file)
        if (file.size > 2097152) {
            alert('Your file is more the 2MB please select less the 2MB file')
        } else {
            uploadFile({ id, reference, horoscope_id: horoscope.id }, fileData)
        }
    }

    if(loading) {
        return(
            <center>
                <Loading/>
            </center>
        );
    }

    return (
        <Fragment>
            <PrivacyHandler
                privacyData={{
                    id,
                    privacyType: 'horoscope_privacy',
                    label: 'Horoscope Privacy',
                }}
            />

            <Divider variant="middle" style={{ margin: '15px 0 30px 0' }} />
            <div className="row">
                <div className="col-xl-12">
                    <div className="submit-field">
                        <div className="uploadButton margin-top-0">
                            <input
                                className="uploadButton-input"
                                type="file"
                                accept="image/*"
                                id="upload"
                                multiple={false}
                                onChange={handleChange}
                            />
                            <label
                                className="uploadButton-button ripple-effect"
                                htmlFor="upload"
                            >
                                Upload Files
                            </label>
                            <span className="uploadButton-file-name">
                                Maximum file size: 2 MB
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Divider variant="middle" style={{ margin: '0 0 30px 0' }} />
            <div className="row">
                    {horoscopeImages.horoscopes.map((image, index) => 
                        (
                            <div key={index} className="col-xl-3 col-md-6">
                                <div className="blog-compact-item-container">
                                    <div className="blog-compact-item">
                                        <img src={image.imageUrl} alt={index} />
                                        <span className="blog-item-tag">{image.status}</span>
                                        <div className="blog-compact-item-content">
                                            <p>
                                                <button
                                                    style={{ backgroundColor: '#dc3139 !important' }}
                                                    className="mark-as-read ico red"
                                                    onClick={() =>
                                                        removeHoroscope(horoscopeImages.horoscopes.length, image)
                                                    }
                                                >
                                                    <i className="icon-feather-trash-2"></i>
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    horoscopeImages: state.horoscope.profileHoroscope,
    loading: state.horoscope.loading,
})

const mapDispatchToProps = {
    uploadFile,
    removeHoroscope,
    getProfileHoroscopes,
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadHoroscope)
