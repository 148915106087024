import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { navigate } from '@reach/router';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const tabValues = {
    'details': 0,
    'photos': 1,
    'horoscope': 2,
    'interests': 3,
    'access-requests': 4
}

export default function ScrollableTabsButtonForce({ tabProperties, id, tab }) {
    const [value, setValue] = useState(tabValues[tab]);

    const handleChange = (event, newValue) => {
        const tabValues = {
            0: 'details',
            1: 'photos',
            2: 'horoscope',
            3: 'interests',
            4: 'access-requests'
        }
        navigate(`/app/dashboard/profile/${id}/view/${tabValues[newValue]}`)
        setValue(newValue);
    };

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs
                    TabIndicatorProps={{style: {background:'#730d51', color: '#730d51'}}}
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example"
                >
                    {tabProperties.map(({ name, icon }, index) => {
                        return <Tab key={index} label={name} icon={icon} {...a11yProps(index)} />
                    })}
                </Tabs>
            </AppBar>

            {tabProperties.map((property, index) => {
                return (
                    <TabPanel value={value} index={index}>
                        {property.component}
                    </TabPanel>
                )
            })}
        </div>
    );
}


// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };