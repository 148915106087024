import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from '@reach/router'
import { connect } from 'react-redux'
import '../assets/styles/sass/style.scss'
import Footer from '../components/common/Footer'
import Header from '../components/UserDashboard/Common/Header'
import SideBar from '../components/UserDashboard/Common/SideBar'
import Notifiacation from '../components/common/Notifiacation'
import LoadingOverlay from '../components/common/LoadingOverlay'
import { setLocationHistory } from '../reducers/controls'

// function usePrevious(value) {
//     const ref = useRef();
//     useEffect(() => {
//       ref.current = value;
//     });
//     return ref.current;
// }

const DashboardLayout = props => {
    const {
        id = null,
        path = null,
        method = null,
        reference = null,
        step = null,
        details = null,
        tab = null,
        uri,
        children,
        showLoading,
        auth: { currentUser },
        handleSetLocationHistory,
        handleCheckAuthToken
    } = props;

    // const prevCountRef = usePrevious(id);
    
     useEffect(() => {
        handleSetLocationHistory({
            id,
            path,
            method,
            reference,
            step,
            details
        })
    }, [uri])

    return (
        <div>
            <div id="wrapper">
                <Notifiacation />
                <LoadingOverlay isActive={showLoading} />
                <Header {...props} />
                <div className="dashboard-container">
                    {currentUser.isPhoneVerified && currentUser.isEmailVerified && (
                        <SideBar {...props} />
                    )}
                    <div className="dashboard-content-container" data-simplebar="init">
                        <div
                            className="simplebar-track vertical"
                            style={{ visibility: 'visible' }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{ visibility: 'visible', top: 0, height: 1225 }}
                            />
                        </div>
                        <div
                            className="simplebar-track horizontal"
                            style={{ visibility: 'visible' }}
                        >
                            <div
                                className="simplebar-scrollbar"
                                style={{ visibility: 'visible', left: 0, width: 25 }}
                            />
                        </div>
                        <div
                            className="simplebar-scroll-content"
                            style={{ paddingRight: 15 }}
                        >
                            <div
                                className="simplebar-content"
                                // style={{ paddingBottom: 15, marginRight: '-15px' }}
                            >
                                <div className="dashboard-content-inner">{children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="margin-top-50"></div> */}
            {/* <Footer /> */}
        </div>
    )
}

const ValidateLayout = props => {
    const {
        auth: { loading, isAuthenticated },
    } = props

    if (loading) {
        return <center>Loading...</center>
    }

    return isAuthenticated ? (
        <DashboardLayout {...props} />
    ) : (
            <Redirect from="" to="/app/login" noThrow />
        )
}

DashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

const mapStateToProps = state => ({
    showLoading: state.controls.showLoading,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => {
    return {
        handleSetLocationHistory: history => dispatch(setLocationHistory(history))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateLayout)
