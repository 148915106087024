import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { setProfileEditValue } from '../../../reducers/profile'
import Modal from '../../../components/common/Modal'
import { setModalVisibility } from '../../../reducers/controls'
import StepFourFamilyInformations from '../../../components/Registration/StepFourFamilyInformations'
import { fetchCountry } from '../../../lib/resolver'


const ModelWindow = () => {
    return(
        <Modal modalOwner="profileFamilyInformations">
            <div className="mfp-content">
                <div
                    id="small-dialog-2"
                    className="zoom-anim-dialog dialog-with-tabs dialog-extended"
                >
                    <StepFourFamilyInformations />
                </div>
            </div>
        </Modal>
    )
}

const BasicInformations = ({
    profile_steps,
    family_information,
    setModalVisibility,
    setProfileEditValue,
}) => {

    if (profile_steps.family_information === 'false') {
        return (
            <Fragment>
                <ModelWindow/>
                <div className="col-xl-6">
                    <div className="dashboard-box child-box-in-row">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-note-add" /> Family Information
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <button
                                className="popup-with-zoom-anim button full-width button-sliding-icon"
                                onClick={event => {
                                    event.preventDefault()
                                    setModalVisibility({
                                        isOpen: true,
                                        modalTriggerOwner: 'profileFamilyInformations',
                                    })
                                    setProfileEditValue({
                                        data: family_information,
                                        key: 'family_information',
                                    })
                                }}
                            >
                                Add Family Informations <i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    const {
        about_family,
        family_living_country,
        family_status,
        family_value,
        father_full_name,
        father_native_place,
        father_status,
        married_male_siblings,
        married_female_siblings,
        mother_full_name,
        mother_native_place,
        mother_status,
        unmarried_male_siblings,
        unmarried_female_siblings
    } = family_information

    return (
        <Fragment>
            <ModelWindow/>
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-fingerprint" /> Family Information
                        </h3>
                        <button
                            className="mark-as-read ico dark"
                            data-tippy-placement="left"
                            data-tippy
                            data-original-title="Mark all as read"
                            onClick={event => {
                                event.preventDefault()
                                setModalVisibility({
                                    isOpen: true,
                                    modalTriggerOwner: 'profileFamilyInformations',
                                })
                                setProfileEditValue({
                                    data: family_information,
                                    key: 'family_information',
                                })
                            }}
                        >
                            <i className="icon-feather-edit" />
                        </button>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list remove-padding">
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>
                                        What is your father's name ?
                                    </strong>
                                    <strong>{father_full_name}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your mother's name ? </strong>
                                    <strong>
                                        {mother_full_name}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where is your father from?</strong>
                                    <strong>{father_native_place}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where is your mother from?</strong>
                                    <strong>{mother_native_place}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your father's status ?</strong>
                                    <strong>{father_status}</strong>
                                </span>
                            </li>
                            
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your mothers's status ?</strong>
                                    <strong>{mother_status}</strong>
                                </span>
                            </li>

                            { unmarried_male_siblings !== "null" && (
                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>How many unmarried male siblings do you have ?</strong>
                                        <strong>{unmarried_male_siblings}</strong>
                                    </span>
                                </li>
                            )}
                            
                            { married_female_siblings !== "null" && (
                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>How many unmarried female siblings do you have ?</strong>
                                        <strong>{unmarried_female_siblings}</strong>
                                    </span>
                                </li>    
                            )}

                            {married_male_siblings !== "null" && (
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>How many married male siblings do you have ?</strong>
                                    <strong>{married_male_siblings}</strong>
                                </span>
                            </li>
                            )}

                            {married_female_siblings !== "null" && (
                                <li>
                                    <span className="notification-icon">
                                        <i className="icon-material-outline-info" />
                                    </span>
                                    <span className="notification-text strong-left-right">
                                        <strong>How many married female siblings do you have ?</strong>
                                        <strong>{married_female_siblings}</strong>
                                    </span>
                                </li>
                            )}
                            
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>Where is your family ?</strong>
                                    <strong>{fetchCountry(family_living_country).name}</strong>
                                </span>
                            </li>

                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>what is your family value ?</strong>
                                    <strong>{family_value}</strong>
                                </span>
                            </li>

                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>what is your family status ?</strong>
                                    <strong>{family_status}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>About your family.</strong>
                                    <strong className="truncate-text truncate-250">{about_family}</strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
})

const mapDispatchToProps = {
    setProfileEditValue,
    setModalVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformations)
