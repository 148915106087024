import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../InputFields/Input'
import { setIsDeleteConfirmed } from '../../reducers/controls'
import { required } from '../../lib/validationAndWarning'

const ConfirmationDialog = ({
    label,
    placeHolder,
    data,
    action,
    dataKey,
    isDeleteConfirmedHandler,
    handleSubmit,
    dispatch
}) => {
    
    const submitHandler = () => {
        isDeleteConfirmedHandler();
        dispatch(action(data));
    }

    return (
        <form>
            <div id="small-dialog-2" className="zoom-anim-dialog dialog-with-tabs">
                <div className="sign-in-form">
                    <ul className="popup-tabs-nav" style={{ pointerEvents: 'none' }}></ul>
                    <div className="popup-tabs-container">
                        <div className="popup-tab-content">
                            <div className="welcome-text">
                                <h3>Delete Confirmation</h3>
                                <span>
                                    Would you like to delete this profile
                                    <a href="#">{data[dataKey]}</a> permanent, If you delete once
                                    you can't get it back
                                </span>
                            </div>

                            <div className="row">
                                <Field
                                    id="confirmationDetails"
                                    name="confirmationDetails"
                                    type="text"
                                    component={inputField}
                                    label={label}
                                    className="with-border"
                                    placeholder={placeHolder}
                                    column="col-xl-12"
                                    validate={[required]}
                                />
                            </div>

                            <button
                                className="button full-width button-sliding-icon ripple-effect"
                                type="submit"
                                form="leave-review-form"
                                onClick={handleSubmit(submitHandler)}
                            >
                                Delete
                                <i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'deleteConfirmationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(ConfirmationDialog)

const mapStateToProps = state => ({
    initialValues: null
})

const mapDispatchToProps = dispatch => ({
    isDeleteConfirmedHandler: () => dispatch(setIsDeleteConfirmed(true)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InitializeFromStateForm)
