import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { setProfileEditValue } from '../../../reducers/profile'
import Modal from '../../../components/common/Modal'
import { setModalVisibility } from '../../../reducers/controls'
import StepTwoEducationAndProfession from '../../../components/Registration/StepTwoEducationAndProfession'

const ModelWindow = () => {
    return(
        <Modal modalOwner="educationAndProfession">
            <div className="mfp-content">
                <div
                    id="small-dialog-2"
                    className="zoom-anim-dialog dialog-with-tabs dialog-extended"
                >
                    <StepTwoEducationAndProfession />
                </div>
            </div>
        </Modal>
    )
}

const BasicInformation = ({
    profile_steps,
    education_and_profession,
    setModalVisibility,
    setProfileEditValue,
}) => {


    if (profile_steps.education_and_profession === 'false') {
        return (
            <Fragment>
                <ModelWindow/>
                <div className="col-xl-6">
                    <div className="dashboard-box child-box-in-row">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-note-add" /> Education And Profession
                            </h3>
                        </div>
                        <div className="content with-padding">
                            <button
                                className="popup-with-zoom-anim button full-width button-sliding-icon"
                                onClick={event => {
                                    event.preventDefault()
                                    setModalVisibility({
                                        isOpen: true,
                                        modalTriggerOwner: 'educationAndProfession',
                                    })
                                    setProfileEditValue({
                                        data: education_and_profession,
                                        key: 'education_and_profession',
                                    })
                                }}
                            >
                                Education And Profession <i className="icon-material-outline-arrow-right-alt" />
                            </button>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    const {
        annual_income,
        education_field,
        education_level,
        income_currency,
        occupation
    } = education_and_profession

    return (
        <Fragment>
            <ModelWindow/>
            <div className="col-xl-6">
                <div className="dashboard-box">
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-fingerprint" /> Education And Profession
                        </h3>
                        <button
                            className="mark-as-read ico dark"
                            data-tippy-placement="left"
                            data-tippy
                            data-original-title="Mark all as read"
                            onClick={event => {
                                event.preventDefault()
                                setModalVisibility({
                                    isOpen: true,
                                    modalTriggerOwner: 'educationAndProfession',
                                })
                                setProfileEditValue({
                                    data: education_and_profession,
                                    key: 'education_and_profession',
                                })
                            }}
                        >
                            <i className="icon-feather-edit" />
                        </button>
                    </div>
                    <div className="content">
                        <ul className="dashboard-box-list remove-padding">
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>
                                        What is your education level ?
                                    </strong>
                                    <strong>{education_level}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your education field ? </strong>
                                    <strong>
                                        {education_field}
                                    </strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>what is your occupation?</strong>
                                    <strong>{occupation}</strong>
                                </span>
                            </li>
                            <li>
                                <span className="notification-icon">
                                    <i className="icon-material-outline-info" />
                                </span>
                                <span className="notification-text strong-left-right">
                                    <strong>What is your income per annum?</strong>
                                    <strong>{income_currency} {annual_income}</strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    profile: state.profile,
})

const mapDispatchToProps = {
    setProfileEditValue,
    setModalVisibility,
}

export default connect(mapStateToProps, mapDispatchToProps)(BasicInformation)
