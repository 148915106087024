import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { mapAuthToProps } from '../../lib/encorders'
import underConstruction from '../../assets/images/under-construction.png'

const Dashboard = ({ auth: { name } }) => {
    return (
        <Fragment>
            <center>
                <div className="dashboard-headline">
                    <img src={underConstruction} alt="under construction" width="250" height="auto"/>
                    <h3>Hi, {name}!</h3>
                    <span>Your Chat options will be available soon </span>
                </div>
            </center>
            {/* <div className="fun-facts-container">
                <div className="fun-fact" data-fun-fact-color="#efa80f">
                    <div className="fun-fact-text">
                        <span>Receiver Interest</span>
                        <h4>28</h4>
                    </div>
                    <div
                        className="fun-fact-icon"
                        style={{ backgroundColor: 'rgba(239, 168, 15, 0.07)' }}
                    >
                        <i
                            className="icon-material-outline-rate-review"
                            style={{ color: 'rgb(239, 168, 15)' }}
                        />
                    </div>
                </div>
                <div className="fun-fact" data-fun-fact-color="#2a41e6">
                    <div className="fun-fact-text">
                        <span>This Month Views</span>
                        <h4>987</h4>
                    </div>
                    <div
                        className="fun-fact-icon"
                        style={{ backgroundColor: 'rgba(42, 65, 230, 0.07)' }}
                    >
                        <i
                            className="icon-feather-trending-up"
                            style={{ color: 'rgb(42, 65, 230)' }}
                        />
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    auth: mapAuthToProps(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
