import React, { Fragment } from 'react'
import Loading from '../../components/common/Loading'
import moment from 'moment'
import CountryWithFlag from '../../components/common/CountryWithFlag'
import Modal from '../../components/common/Modal'
import ConfirmationDialog from '../../components/common/ConfirmationDialogWithText'
import { setModalVisibility } from '../../reducers/controls'
import PhotoGalleryPrivate from '../../components/common/PhotoGalleryPrivate'
import BasicInformation from './ProfileSteps/BasicInformations'
import EducationAndProfession from './ProfileSteps/EducationAndProfession'
import FamilyInformation from './ProfileSteps/FamilyInformation'
import LifestyleAndAppearance from './ProfileSteps/LifestyleAndAppearance'
import MaterialUITab from '../../components/common/Tab'
import Photos from './ProfilePhotos/Photos'
import UploadHoroscope from './Horoscope/UploadHoroscope'
import { fetchCountry } from '../../lib/resolver'
import ReceivedAndSentInterest from './Interests/ReceivedAndSentInterest'
import AccessRequest from './Requests/AccessRequest'
import toChangeCase from '../../utils/prototype'
import _ from 'lodash'

// Icons
import {
    Info,
    PhotoLibrary,
    Star,
    Favorite,
    NotificationsActive
} from '@material-ui/icons';
import { useUserProfile } from '../../hooks/useHooks'
import iconFinder from '../../lib/iconFinder'

export default (props) => {
    const { id, uri } = props;
    const { dispatch, loading, userProfile, deleteProfile } = useUserProfile(id, uri);
    
    if (_.isEmpty(userProfile) || loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    };

    return (
        <Fragment>
            <Modal modalOwner="deleteUserProfile">
                <ConfirmationDialog
                    data={userProfile}
                    dataKey="reference"
                    label="Profile reference number"
                    placeHolder="Enter the profile reference number"
                    action={deleteProfile}
                    dispatch={dispatch}
                />
            </Modal>

            <div
                className="single-page-header"
                data-background-image="images/single-job.jpg"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                        
                                    <PhotoGalleryPrivate
                                        profile={userProfile}
                                        profilePhotoUrl={userProfile.avatar}
                                    />
                                    <div className="header-details">
                                        <h3>
                                            {userProfile.basic_information.first_name}{' '}
                                            {userProfile.basic_information.last_name} | {userProfile.reference}
                                            <span>
                                                Created by{' '}
                                                {userProfile.basic_information.profile_created_by}{' '}
                                                {moment(userProfile.created_at).fromNow()}
                                            </span>
                                        </h3>
                                        <h5>About the Profile</h5>
                                        <ul>
                                            <li>
                                                <i className={iconFinder(userProfile.basic_information.gender)} />{' '}
                                                {userProfile.basic_information.gender.toChangeCase('upper-first')}
                                            </li>
                                            <li>
                                                <i className="icon-material-outline-business" />{' '}
                                                {userProfile.basic_information.living_city}
                                            </li>
                                            <li>
                                                <CountryWithFlag {...userProfile.basic_information} />
                                                {fetchCountry(userProfile.basic_information.living_country).name}
                                            </li>
                                            <li
                                                style={{ cursor: 'pointer' }}
                                                onClick={event => {
                                                    event.preventDefault()
                                                    dispatch(
                                                        setModalVisibility({
                                                            isOpen: true,
                                                            modalTriggerOwner: "deleteUserProfile"
                                                        })
                                                    );
                                                }}
                                            >
                                                <i className="icon-feather-trash-2" />
                                                Delete
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <span className={`badge ${userProfile.status_properties.badge}`}>
                                                    <i className={userProfile.status_properties.icon}/>  {userProfile.status_properties.message}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                               {(userProfile.education_and_profession !== null && userProfile.education_and_profession.annual_income !== 'null') &&
                                <div className="right-side">
                                    <div className="salary-box">
                                        <div className="salary-type">Annual Salary</div>
                                        <div className="salary-amount">
                                            {userProfile.education_and_profession.annual_income} | {userProfile.education_and_profession.income_currency}
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-ms-12">
                <MaterialUITab
                    {...props}
                    tabProperties={[
                        {
                            component:  <div className="profile-info-wrapper">
                                            <BasicInformation {...userProfile} />
                                            <LifestyleAndAppearance {...userProfile}/>
                                            <EducationAndProfession {...userProfile}/>
                                            <FamilyInformation {...userProfile}/>
                                        </div>,
                            name: "Profile Information",
                            icon: <Info/>
                        },
                        {
                            component:  <Photos {...userProfile}/>,
                            name: "Photos",
                            icon: <PhotoLibrary/>
                        },
                        {
                            component:  <UploadHoroscope {...userProfile}/>,
                            name: "Horoscope",
                            icon: <Star/>
                        },
                        {
                            component:  <ReceivedAndSentInterest {...userProfile}/>,
                            name: "Interests",
                            icon: <Favorite/>
                        },
                        {
                            component:  <AccessRequest {...userProfile}/>,
                            name: "Access Requests",
                            icon: <NotificationsActive/>
                        }
                    ]}
                />
            </div>
        </Fragment>
    )
};

