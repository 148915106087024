import React, { useState, Fragment } from 'react'
import { currentAge } from '../lib/calculations'
import moment from 'moment'
import CountryWithFlag from '../components/common/CountryWithFlag'
import ShortListButton from '../components/common/ShortListButton'
import Loading from '../components/common/Loading'
import { connect } from 'react-redux'
import Modal from '../components/common/Modal'
import PhotoGallery from '../components/common/PhotoGallery'
import { fetchCountry, currentUserResolver } from '../lib/resolver'
import Personal from '../components/Profile/Personal'
import LifestyleAndAppearance from '../components/Profile/LifestyleAndAppearance'
import EducationAndCareer from '../components/Profile/EducationAndCareer'
import { getProfilePhoneNumber } from '../reducers/profile'
import ContactNumber from '../components/common/ContactNumber'
import { notification } from '../lib/notify'
import { getProfileHoroscopes } from '../reducers/horoscope'
import FamilyInformation from '../components/Profile/FamilyInformation'
import PopUpNotification from '../components/common/PopUpNotification'
import UserProfilesForModel from './UserDashboard/UserProfilesForModel'
import { useRequestAndInterest, useUserProfile } from '../hooks/useHooks'
import iconFinder from '../lib/iconFinder'

const Profile = (props) => {
    const {
        id,
        reference,
        selectedProfile,
        getProfilePhoneNumber,
        getProfileHoroscopes,
        currentUser,
        uri
    } = props;
    const [parent, setParent] = useState('SEND_INTEREST');
    const { loading } = useUserProfile(id, uri);    
    const { selectReceiverProfileHandler } = useRequestAndInterest();

    if (loading) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    if (loading || Object.entries(selectedProfile).length === 0) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <div>
            <Modal modalOwner="userDoesntHaveAnyProfile">
                <PopUpNotification
                    title="You don't have any profiles."
                    message="Please create a profile to send interest."
                    redirectTo="/app/dashboard/profiles"
                />
            </Modal>

            <Modal modalOwner="shortList">
                <UserProfilesForModel parent={parent}/>
            </Modal>

            <Modal modalOwner="getProfilecontactNumber">
                <ContactNumber {...selectedProfile} />
            </Modal>

            <div
                className="single-page-header"
                data-background-image="images/single-job.jpg"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                    <div className="freelancer-avatar">
                                        <PhotoGallery
                                            profile={selectedProfile}
                                            profilePhotoUrl={
                                                selectedProfile.profile_photo !== 'null'
                                                    ? selectedProfile.profile_photo
                                                    : selectedProfile.avatar
                                            }
                                        />
                                    </div>
                                    <div className="header-details">
                                        <h3>
                                            {reference}
                                            <span>
                                                Profile Creaated by{' '}
                                                {selectedProfile.basic_information.profile_created_by}{' '}
                                                {moment(selectedProfile.created_at).fromNow()}
                                            </span>
                                        </h3>
                                        <h5>About the Profile</h5>
                                        <ul>
                                            <li>
                                                <i className="icon-line-awesome-calendar-minus-o" />{' '}
                                                {currentAge(selectedProfile.basic_information.dob)}
                                            </li>
                                            <li>
                                            <i className={iconFinder(selectedProfile.basic_information.gender )} /> {selectedProfile.basic_information.gender }
                                            </li>
                                            <li>
                                                <i className="icon-material-outline-business" />{' '}
                                                {selectedProfile.basic_information.living_city}
                                            </li>

                                            <li>
                                                <CountryWithFlag
                                                    {...selectedProfile.basic_information}
                                                />
                                                {
                                                    fetchCountry(
                                                        selectedProfile.basic_information.living_country
                                                    ).name
                                                }
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <span className="badge info">
                                                    {' '}
                                                    <i className="icon-feather-phone" /> Phone Number
                                                    Available{' '}
                                                </span>
                                            </li>
                                            <li>
                                                <span className="badge info">
                                                    <i className="icon-feather-image" />{' '}
                                                    {selectedProfile.number_of_photos}{' '}
                                                    {selectedProfile.number_of_photos > 1
                                                        ? 'Photos'
                                                        : 'Photo'}{' '}
                                                Available{' '}
                                                </span>
                                            </li>
                                            {selectedProfile.is_horoscope_added === 'true' && (
                                                <li>
                                                    <span className="badge info">
                                                        <i className="icon-material-baseline-star-border" />
                                                        Horoscope Available
                                                    </span>
                                                </li>
                                            )}
                                            <li>
                                                <span
                                                    className={`badge ${selectedProfile.status_properties.badge}`}
                                                >
                                                    <i
                                                        className={selectedProfile.status_properties.icon}
                                                    />{' '}
                                                    {selectedProfile.status_properties.message}
                                                </span>
                                            </li>
                                        </ul>
                                        <ul>
                                            {selectedProfile.is_contact_already_received && (
                                                <li>
                                                    <span className="badge danger">
                                                        <i className="icon-material-outline-info" />
                                                            You have already received this contact number
                                                        </span>
                                                </li>
                                            )}
                                        </ul>
                                        <ul className="margin-top-10">
                                            {(currentUser.sub !== selectedProfile.user.id) && (selectedProfile.photo_privacy !== 'PUBLIC') && 
                                                <Fragment>
                                                    {
                                                        !selectedProfile.has_access_permission &&
                                                        <li>
                                                            <button className="button"
                                                                onClick={event => {
                                                                    event.preventDefault()
                                                                    setParent('SEND_REQUEST_TO_ACCESS');
                                                                    selectReceiverProfileHandler(selectedProfile, 'PHOTO_VIEW')
                                                                }}
                                                            >
                                                                <i className="icon-material-outline-compare-arrows" />
                                                                Send Requesting to Access Photos & Horoscope
                                                            </button>
                                                        </li>
                                                    }
                                                </Fragment>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="right-side">
                                    <div className="salary-box">
                                        <div className="salary-type">Annual Salary</div>
                                        <div className="salary-amount">
                                            {selectedProfile.basic_information.annual_income}-
                                            {selectedProfile.basic_information.income_currency}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {currentUser.sub !== selectedProfile.user.id && (
                    <Fragment>
                        <hr
                            className="MuiDivider-root MuiDivider-middle"
                            style={{ margin: '15px 0px 30px' }}
                        ></hr>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3">
                                    <button
                                            className="button big full-width "
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setParent('SEND_INTEREST');
                                                selectReceiverProfileHandler(selectedProfile, null)
                                            }}
                                        >
                                            Send Interest <i className="icon-feather-send" />
                                    </button>
                                </div>

                                <div className="col-xl-3 col-lg-3">
                                    <ShortListButton
                                        isLargeButton={true}
                                        profile={selectedProfile}
                                    />
                                </div>

                                <div className="col-xl-3 col-lg-3">
                                    <button
                                        className="button big full-width "
                                        onClick={event => {
                                            event.preventDefault()

                                            if (!selectedProfile.is_contact_already_received) {
                                                getProfilePhoneNumber(selectedProfile)
                                            } else {
                                                notification({
                                                    type: 'warning',
                                                    message:
                                                        'You have already received this contact number.',
                                                })
                                            }
                                        }}
                                    >
                                        Get the Contact Number{' '}
                                        <i className="icon-feather-phone"></i>
                                    </button>
                                </div>

                                <div className="col-xl-3 col-lg-3">
                                    <button
                                        className="button big full-width"
                                        onClick={event => {
                                            event.preventDefault()
                                            getProfileHoroscopes(selectedProfile.id)
                                        }}
                                    >
                                        Download Horoscope{' '}
                                        <i className="icon-feather-file-text"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}

                <hr
                    className="MuiDivider-root MuiDivider-middle"
                    style={{ margin: '15px 0px 30px' }}
                ></hr>
                <div className="container">
                    <div className="row">
                        <Personal {...selectedProfile} />
                        <LifestyleAndAppearance {...selectedProfile} />
                        <EducationAndCareer {...selectedProfile} />
                        {selectedProfile.profile_steps.family_information === 'true' && (
                            <FamilyInformation {...selectedProfile} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    loading: state.profile.loading,
    selectedProfile: state.profile.userProfile,
    currentUser: currentUserResolver(state.auth.currentUser),
})

const mapDispatcherToProps = {
    getProfilePhoneNumber,
    getProfileHoroscopes,
}
export default connect(mapStateToProps, mapDispatcherToProps)(Profile)
