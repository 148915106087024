import React, { Fragment } from 'react'
import moment from 'moment';
import { connect } from 'react-redux'
import { fetchCountry } from '../../../lib/resolver';
import CountryWithFlag from '../../../components/common/CountryWithFlag';
import { deleteInterest } from '../../../reducers/interest';
import { Link } from '@reach/router';
import iconFinder from '../../../lib/iconFinder';

const ReceivedAndSentInterest = ({ sent_interests,  received_interests, deleteInterest}) => {

    return (
        <Fragment>
            {/* Interest */}
            <div className="row">
            <div className="col-xl-6">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-feather-bell" />
                                Received Interests
                            </h3>
                        </div>
                        <div className="content overflow-y-500">
                            <ul className="dashboard-box-list">
                                {received_interests.map((receivedInterest, index) => {
                                    const {
                                        created_at,
                                        profile: {
                                            basic_information: {
                                                gender,
                                                living_city,
                                                living_country
                                            },
                                            reference,
                                            profile_photo,
                                            avatar,
                                            id
                                        }
                                    } = receivedInterest
                                    return (
                                        <li key={index}>
                                            <div className="job-listing">
                                                <div className="job-listing-details">
                                                    <img
                                                        src={profile_photo !== "null" ? profile_photo : avatar}
                                                        alt={reference}
                                                        className="avatar-circle size-75"
                                                    />
                                                    <div className="job-listing-description">
                                                        <h3 className="job-listing-title">
                                                            <Link to={`/app/profile/${id}/${reference}`}>{reference}</Link>
                                                        </h3>
                                                        <div className="job-listing-footer">
                                                            <ul>
                                                                <li>
                                                                    <i className={iconFinder(gender)} />{' '}
                                                                    {gender}
                                                                </li>
                                                                <li>
                                                                    <i className="icon-material-outline-business" />{' '}
                                                                    {living_city}
                                                                </li>
                                                                <li>
                                                                    <CountryWithFlag {...receivedInterest.profile.basic_information} />
                                                                    {fetchCountry(living_country).name}
                                                                </li>
                                                                <li>
                                                                    <i className="icon-material-outline-access-time" />
                                                                    {moment(created_at).fromNow()}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buttons-to-right single-right-button">
                                                <button
                                                    className="button red ripple-effect ico"
                                                    data-tippy-placement="left"
                                                    data-tippy
                                                    data-original-title="Remove"
                                                    onClick={() => {
                                                        deleteInterest(receivedInterest)
                                                    }}
                                                >
                                                    <i className="icon-feather-trash-2" />
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-feather-bell" />
                                Sent Interests
                            </h3>
                        </div>
                        <div className="content overflow-y-500">
                            <ul className="dashboard-box-list">
                                {sent_interests.map((sentInterest, index) => {
                                    const {
                                        created_at,
                                        profile: {
                                            basic_information: {
                                                gender,
                                                living_city,
                                                living_country
                                            },
                                            reference,
                                            profile_photo,
                                            avatar,
                                            id
                                        }
                                    } = sentInterest
                                    return (
                                        <li key={index}>
                                            <div className="job-listing">
                                                <div className="job-listing-details">
                                                    <img
                                                        src={profile_photo !== "null" ? profile_photo : avatar}
                                                        alt={reference}
                                                        className="avatar-circle size-75"
                                                    />
                                                    <div className="job-listing-description">
                                                        <h3 className="job-listing-title">
                                                            <Link to={`/app/profile/${id}/${reference}`}>{reference}</Link>
                                                        </h3>
                                                        <div className="job-listing-footer">
                                                            <ul>
                                                                <li>
                                                                    <i className={iconFinder(gender)} />{' '}
                                                                    {gender}
                                                                </li>
                                                                <li>
                                                                    <i className="icon-material-outline-business" />{' '}
                                                                    {living_city}
                                                                </li>
                                                                <li>
                                                                    <CountryWithFlag {...sentInterest.profile.basic_information} />
                                                                    {fetchCountry(living_country).name}
                                                                </li>
                                                                <li>
                                                                    <i className="icon-material-outline-access-time" />
                                                                    {moment(created_at).fromNow()}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buttons-to-right single-right-button">
                                                <button
                                                    className="button red ripple-effect ico"
                                                    data-tippy-placement="left"
                                                    data-tippy
                                                    data-original-title="Remove"
                                                    onClick={() => {
                                                        deleteInterest(sentInterest)
                                                    }}
                                                >
                                                    <i className="icon-feather-trash-2" />
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


const mapDispatchToProps = {
    deleteInterest
}

export default connect(null, mapDispatchToProps)(ReceivedAndSentInterest)
