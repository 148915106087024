import React from 'react'
import { Link, navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import SmartSelectField from '../InputFields/SmartSelectField'
import {
    educationLevel,
    educationField,
    incomeCurrency,
    annualIncome,
    occupation,
} from '../../helpers/SelectOptions'

import urls from '../../helpers/urls'
import { createOrUpdateProfile } from '../../reducers/profile'
import Loading from '../common/LoadingOverlay'
import {
    required,
    string
} from '../../lib/validationAndWarning'


const StepTwoEducationAndProfession = ({
    authLocationHistory: { isLoaded, history },
    createOrUpdateProfile,
    handleSubmit

}) => {
    const createStepHandler = async event => {
        const data = {
            method: history.method,
            formName: 'education_and_profession'
        }

        await createOrUpdateProfile(data, ({ status, profileId }) => {
            if(status === "OK" && history.method === "new") {
                navigate(urls.stepThree.to.new(profileId))
            }
        });
    }

    if (!isLoaded) {
        return (
            <center>
                <Loading />
            </center>
        )
    }

    return (
        <form onSubmit={handleSubmit(createStepHandler)}>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="dashboard-box margin-top-0">
                            <div className="headline">
                                <h3>
                                    <i className="icon-material-outline-book" /> Education And
                                    Career
                                        </h3>
                            </div>
                            <div className="content with-padding padding-bottom-10">
                                <div className="row">
                                    <Field
                                        name="education_level"
                                        type="text"
                                        component={SmartSelectField}
                                        className="with-border"
                                        dataSource={educationLevel}
                                        validate={[required]}
                                    />

                                    <Field
                                        name="education_field"
                                        type="text"
                                        component={SmartSelectField}
                                        className="with-border"
                                        dataSource={educationField}
                                        validate={[required]}
                                    />

                                    <Field
                                        name="occupation"
                                        type="text"
                                        component={SmartSelectField}
                                        label="Occupation"
                                        className="with-border"
                                        dataSource={occupation}
                                        validate={[required]}
                                    />

                                    <Field
                                        name="income_currency"
                                        type="text"
                                        component={SmartSelectField}
                                        className="with-border"
                                        dataSource={incomeCurrency}
                                    />

                                    <Field
                                        name="annual_income"
                                        type="text"
                                        component={SmartSelectField}
                                        className="with-border"
                                        dataSource={annualIncome}
                                    /> 
    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                         <button
                            type="submit"
                            className="button ripple-effect big right margin-top-30"
                        >
                            {history.method === 'new' ? (
                                <>
                                    Next <i className="icon-feather-arrow-right-circle" />{' '}
                                </>
                            ) : (
                                    <>
                                        Update <i className="icon-feather-arrow-right-circle" />{' '}
                                    </>
                                )}
                        </button>
                    </div>
                </div>
        </form>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'education_and_profession',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(StepTwoEducationAndProfession)

const mapStateToProps = state => ({
    initialValues: state.profile.profileInitialData.education_and_profession,
    authLocationHistory: state.controls.authLocationHistory,
})

const mapDispatchToProps = {
    createOrUpdateProfile
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)