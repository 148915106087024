import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from '../InputFields/Input'
import TextArea from '../InputFields/TextArea'
import {
    familyStatus,
    familyValue,
    numberOfSiblings,
} from '../../helpers/SelectOptions'
import urls from '../../helpers/urls'
import { createOrUpdateProfile } from '../../reducers/profile'
import { Link, navigate } from '@reach/router'
import Loading from '../common/LoadingOverlay'
import Countries from '../../helpers/Countries'
import SmartSelectField from '../InputFields/SmartSelectField'
import {
    required,
    string
} from '../../lib/validationAndWarning'


const StepFiveFamilyInformations = ({
    id,
    authLocationHistory: { isLoaded, history },
    initialValues,
    createOrUpdateProfile,
    handleSubmit,
    submitting
}) => {
    const createStepHandler = async event => {
        const data = {
            method: history.method,
            formName: 'family_information'
        }
        await createOrUpdateProfile(data, ({ status, profileId }) => {
            if(status === "OK" && history.method === "new") {
                navigate(urls.success(profileId))
            }
        });
    }

    if (!isLoaded) {
        return (
            <center>
                <Loading />
            </center>
        )
    } 
    
    return (
        <form onSubmit={handleSubmit(createStepHandler)}>
            <div className="row">
                <div className="col-xl-12">
                    <div className="dashboard-box margin-top-0">
                        <div className="headline">
                            <h3>
                                <i className="icon-material-outline-face" /> Family Informations
                            </h3>
                        </div>
                        <div className="content with-padding padding-bottom-10">
                            <div className="row">
                                <Field
                                    name="father_full_name"
                                    type="text"
                                    component={inputField}
                                    label="Enter your father's name"
                                    className="with-border"
                                    placeholder="Enter your father's name"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="mother_full_name"
                                    type="text"
                                    component={inputField}
                                    label="Enter your mothers's name"
                                    className="with-border"
                                    placeholder="Enter your mothers's name"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="father_status"
                                    type="text"
                                    component={inputField}
                                    label="Father's status"
                                    className="with-border"
                                    placeholder="Enter father's status"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="mother_status"
                                    type="text"
                                    component={inputField}
                                    label="Mother's status"
                                    className="with-border"
                                    placeholder="Enter mother's status"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="father_native_place"
                                    type="text"
                                    component={inputField}
                                    label="Father's native place"
                                    className="with-border"
                                    placeholder="Enter father's native place"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="mother_native_place"
                                    type="text"
                                    component={inputField}
                                    label="Mother's native place"
                                    className="with-border"
                                    placeholder="Enter mother's native place"
                                    validate={[required, string]}
                                />

                                <Field
                                    name="unmarried_male_siblings"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={numberOfSiblings}
                                    validate={[required]}
                                />

                                <Field
                                    name="unmarried_female_siblings"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={numberOfSiblings}
                                    validate={[required]}
                                />

                                <Field
                                    name="married_male_siblings"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={numberOfSiblings}
                                    validate={[required]}
                                />

                                <Field
                                    name="married_female_siblings"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={numberOfSiblings}
                                    validate={[required]}
                                />

                                <Field
                                    name="family_living_country"
                                    type="text"
                                    component={SmartSelectField}
                                    dataSource={Countries}
                                    className="with-border"
                                    validate={[required]}
                                />

                                <Field
                                    name="family_status"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={familyStatus}
                                    validate={[required]}
                                />

                                <Field
                                    name="family_value"
                                    type="text"
                                    component={SmartSelectField}
                                    className="with-border"
                                    dataSource={familyValue}
                                    validate={[required]}
                                />

                                <Field
                                    name="about_family"
                                    type="text"
                                    component={TextArea}
                                    className="with-border"
                                    placeholder="Enter somthing about your family"
                                    validate={[required]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">

                        <div className="float-left">
                        {history.method === 'new' &&
                            <Link
                                to={urls.success(id)}
                                className="button ripple-effect big left margin-top-30 margin-right-10"
                            >
                                Skip <i className="icon-line-awesome-angle-double-right" />
                                
                            </Link>
                            }
                        </div>
                        <div className="float-right">
                            <button
                                type="submit"
                                disabled={submitting}
                                className="button ripple-effect big right margin-top-30"
                            >
                            {history.method === 'new' ? (
                                <>
                                    Next <i className="icon-feather-arrow-right-circle" />{' '}
                                </>
                            ) : (
                                    <>
                                        Update <i className="icon-feather-arrow-right-circle" />{' '}
                                    </>
                                )}
                            </button>
                        </div>
                </div>
            </div>
        </form>
    );
};

const InitializeFromStateForm = reduxForm({
    form: 'family_information',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(StepFiveFamilyInformations)

const mapStateToProps = state => ({
    initialValues: state.profile.profileInitialData.family_information,
    authLocationHistory: state.controls.authLocationHistory,
})

const mapDispatchToProps = {
    createOrUpdateProfile
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
