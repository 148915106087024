import React, {useEffect , useState} from 'react'
import { connect } from 'react-redux'
import CheckBox from '../../../components/InputFields/CheckBox'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { required } from '../../../lib/validationAndWarning'
import ButtonSelect from '../../../components/InputFields/ButtonSelect'
import Loading from '../../../components/common/LoadingOverlay'
import { notification } from '../../../lib/notify'
import Payment from './Payment'
import { Link } from '@reach/router'

const BuyNewPlan = ({ formValues: { selectedPlan }, allPlans }) => {
    const [loading, setLoading] = useState(true);
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});

    const getSelectedPlan = async () => {
        await setSelectedPlanDetails(allPlans.find(data => data.value === selectedPlan));
        setLoading(false);
    }

    useEffect(() => {
        getSelectedPlan();
    }, [selectedPlan])


    const onClickCallBackHandler = () => {
        if(typeof selectedPlan !== 'undefined') {
            notification({
                type: 'success',
                message: `You have selected ${selectedPlanDetails.name} plan. Would you like to pay for it.`
            });
        }
    };

    if(loading) {
        return(
            <center>
                <Loading/>
            </center>
        );
    }
    
    return (
        <div className="row">
            <div className="col-xl-8 col-lg-8 content-right-offset">
                <div className="pricing-plans-container">
                    {allPlans.map((plan, index) => {
                        if (plan.value !== 'FREE') {
                            return (
                                <div key={index} className="pricing-plan recommended">

                                    <h3>{plan.name}</h3>
                                    <p className="margin-top-10">{plan.description}</p>
                                    <p className="margin-top-10"></p>
                                    <div className="pricing-plan-label billed-monthly-label">
                                        <strong>{plan.price_description}</strong>
                                    </div>
                                    <div className="pricing-plan-features">
                                        <strong>{plan.name} Plan</strong>
                                        <ul className="list-2 margin-left-25">
                                            {plan.capabilities.map((data, index) => (
                                                <li key={index}>{data.description}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    <Field 
                                        name="selectedPlan"
                                        label="Select a plan"
                                        component={ButtonSelect} 
                                        assignedValue={plan.value} 
                                        onClickCallBack={onClickCallBackHandler}
                                    />
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 margin-top-0 margin-bottom-60">
                <div className="boxed-widget summary margin-top-0">
                    <div className="boxed-widget-headline">
                        <h3>Summary</h3>
                    </div>
                    <div className="boxed-widget-inner">
                        <ul>
                            <li>
                               {selectedPlanDetails.name} <span>€{Number(selectedPlanDetails.plan_price).toFixed(2)}</span>
                            </li>

                            <li className="total-costs">
                                Final Price <span>€{Number(selectedPlanDetails.plan_price).toFixed(2)}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="checkbox margin-top-30">
                    <label htmlFor="two-step">
                        <Field
                            name="termsAndConditions"
                            type="checkbox"
                            label="Terms and conditions"
                            component={CheckBox}
                            className="checkbox-icon"
                        />
                        <span style={{ marginLeft: 30}}>
                            I agree to the <Link to="/app/terms-and-conditions"> Terms and Conditions </Link> and the <Link to="/app/privacy"> Privacy Policy </Link>
                        </span>
                    </label>
                </div>

                <div>
                    <Payment selectedPlan={selectedPlanDetails}/>
                </div>
            </div>
        </div>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'updatePlanForm'
})(BuyNewPlan)

const formInitialValue = {
    termsAndConditions: false,
    selectedPlan: 'PAY_AS_YOU_GO'
}

const selector = formValueSelector('updatePlanForm') 

const mapStateToProps = state => ({
    initialValues: formInitialValue,
    formValues: selector(state, 'termsAndConditions', 'selectedPlan') 
})

const mapDispatchToProps = {

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)