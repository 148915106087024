import React  from 'react'
import { connect } from 'react-redux';
import StepOneProfileBasicDetails from '../../components/Registration/StepOneProfileBasicDetails'
import StepTwoEducationAndProfession from '../../components/Registration/StepTwoEducationAndProfession'
import StepThreeLifeStyleInformations from '../../components/Registration/StepThreeLifeStyleInformations'
import StepFourFamilyInformations from '../../components/Registration/StepFourFamilyInformations'
import SuccessMessage from '../../components/common/SuccessMessage'
import { editProfile } from '../../reducers/profile'

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

const ProfileRegistration = (props) => {

    const { step } = props;

    const currentStep = () => {
        switch (step) {
            case 'step-one':
                return <StepOneProfileBasicDetails {...props} />
            case 'step-two':
                return <StepTwoEducationAndProfession {...props} />;
            case 'step-three':
                return <StepThreeLifeStyleInformations {...props} />;
            case 'step-four':
                return <StepFourFamilyInformations {...props} />;
            case 'success':
                return <SuccessMessage />;
            default:
                return <StepOneProfileBasicDetails />
        }
    }

    return (
        <div className="container">
            {/* <div className="dashboard-headline">
                <h3>{action.capitalize()} Profile</h3>
                <span className="margin-top-7">
                    {details.split('-').join(' ').capitalize()}
                </span>
            </div> */}
            {currentStep()}
        </div>
    )
}

export default ProfileRegistration;