import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import inputField from "../../../components/InputFields/Input"
import {
    required,
    matchPassword
} from '../../../lib/validationAndWarning'
import { resetPassword } from '../../../reducers/auth'

const PasswordAndSecurity = ({ handleSubmit, resetPassword }) => {
    return (
        <form onSubmit={handleSubmit(resetPassword)}>
            <div className="col-xl-12">
                <div id="test1" className="dashboard-box">
                    {/* Headline */}
                    <div className="headline">
                        <h3>
                            <i className="icon-material-outline-lock" /> Password &amp;
                                Security
                        </h3>
                    </div>
                    <div className="content with-padding">
                        <div className="row">
                            <Field
                                id="oldPassword"
                                name="oldPassword"
                                type="password"
                                component={inputField}
                                label="Old Password"
                                className="with-border"
                                placeholder="Type your old password"
                                validate={[required]}
                            />

                            <Field
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                component={inputField}
                                label="New Password"
                                className="with-border"
                                placeholder="Type your new password"
                                validate={[required]}
                            />

                            <Field
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                component={inputField}
                                label="Confirm Password"
                                className="with-border"
                                placeholder="Retype your new password"
                                validate={[required, matchPassword]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-12 ">
                <div className="">
                    <button type="submit" className="button ripple-effect big margin-top-30">
                        Change the password
                    </button>
                </div>
            </div>
        </form>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'resetPasswordForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(PasswordAndSecurity)

const mapStateToProps = state => ({
    email: state.email,
})

const mapDispatchToProps = {
    resetPassword
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
