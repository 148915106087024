import React, { Fragment } from 'react'
import ContactUs from '../components/LandingPage/ContactUs'
import Meta from '../components/common/Meta'
export default (props) => {

    return (
        <Fragment>
            <Meta
                title={'Contact Us - Lankanwedding.com'}
            />
            <div className="container" style={{ marginTop: 100}}>
                <ContactUs/>
            </div>
        </Fragment>
    )
}

