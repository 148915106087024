import React from 'react'
import { navigate } from '@reach/router';
import { setModalVisibility } from '../../reducers/controls';
import { connect } from 'react-redux';

const PopUpNotification = ({ title, message, redirectTo , closeDialog}) => {
    return (
        <div id="sign-in-dialog" className="zoom-anim-dialog  dialog-with-tabs">
            <div className="sign-in-form">
                <div className="popup-tabs-container">
                    <div className="popup-tab-content" id="tab" style={{}}>
                        <div className="welcome-text">
                            <h3>{title}</h3>
                        </div>
                        {message}
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                closeDialog();
                                navigate(redirectTo)
                            }}
                            className="button full-width button-sliding-icon ripple-effect"
                            type="submit"
                            form="add-note"
                        >
                            Add Note <i className="icon-material-outline-arrow-right-alt" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}



const mapDispatchToProps = (dispatch) =>{
    return {
        closeDialog: () => {
            dispatch(
                setModalVisibility({
                    isOpen: false,
                    modalTriggerOwner: "userDoesntHaveAnyProfile"
                })
            )
        }
    }
}

export default connect(null, mapDispatchToProps)(PopUpNotification)
