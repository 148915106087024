import React, { Fragment } from 'react'
import { fetchCountry } from '../../lib/resolver'
import toChangeCase from '../../utils/prototype'

const Personal = ({ basic_information }) => {
    return (
        <Fragment>
            <div className="col-xl-4 col-lg-4">
                <div className="sidebar-widget">
                    <div className="job-overview">
                        <div className="job-overview-headline">Personal</div>
                        <div className="job-overview-inner">
                            <ul>
                                <li>
                                    <i className="material-icons">language</i>
                                    <span>Mother Tongue</span>
                                    <h5>{basic_information.mother_tongue.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">language</i>
                                    <span>Primary Language</span>
                                    <h5>{basic_information.primary_language.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">house</i>
                                    <span>Nationality</span>
                                    <h5>{basic_information.nationality.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">home_work</i>
                                    <span>Religion</span>
                                    <h5>{basic_information.religion.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">person_pin_circle</i>
                                    <span>Country of Birth</span>
                                    <h5>
                                        {
                                            fetchCountry(
                                                basic_information.country_of_birth
                                            ).name
                                        }
                                    </h5>
                                </li>
                                <li>
                                    <i className="material-icons">edit_location</i>
                                    <span>Residence Status</span>
                                    <h5>{basic_information.residence_status.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">pin_drop</i>
                                    <span>Living City</span>
                                    <h5>{basic_information.living_city.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_ind</i>
                                    <span>Living State</span>
                                    <h5>{basic_information.living_state.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">favorite</i>
                                    <span>Meritial Status</span>
                                    <h5>{basic_information.marital_status.toChangeCase('upper-each')}</h5>
                                </li>
                                {basic_information.marital_status !== 'unmarried' && 
                                    <Fragment>
                                        <li>
                                            <i className="material-icons">child_friendly</i>
                                            <span>Children</span>
                                            <h5>{basic_information.children.toChangeCase('upper-each')}</h5>
                                        </li>
                                        <li>
                                            <i className="material-icons">child_care</i>
                                            <span>Number fo Children</span>
                                            <h5>{basic_information.number_of_children}</h5>
                                        </li>
                                    </Fragment>
                                }
                                <li>
                                    <i className="material-icons">star_outline</i>
                                    <span>Chevvai dosham</span>
                                    <h5>{basic_information.chevvai_dosham.toChangeCase('upper-each')}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">assignment_ind</i>
                                    <span>Caste</span>
                                    <h5>{basic_information.caste.toChangeCase('upper-each')}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Personal
