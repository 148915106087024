import React, { Fragment } from 'react';

const LifestyleAndAppearance = ({ lifestyle_and_appearance }) => {
    return (
        <Fragment>
            <div className="col-xl-4 col-lg-4">
                <div className="sidebar-widget">
                    <div className="job-overview">
                        <div className="job-overview-headline">
                            Lifestyle & Appearance
                                    </div>
                        <div className="job-overview-inner">
                            <ul>
                                <li>
                                    <i className="material-icons">restaurant</i>
                                    <span>Food Habit</span>
                                    <h5>{lifestyle_and_appearance.food_habit}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">smoking_rooms</i>
                                    <span>Smoking Habit</span>
                                    <h5>{lifestyle_and_appearance.smoking_habit}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">local_bar</i>
                                    <span>Drinking Habit</span>
                                    <h5>{lifestyle_and_appearance.drinking_habit}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">accessibility_new</i>
                                    <span>Body type</span>
                                    <h5>{lifestyle_and_appearance.body_type}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">height</i>
                                    <span>Height</span>
                                    <h5>{lifestyle_and_appearance.height}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">invert_colors</i>
                                    <span>Skin Tone</span>
                                    <h5>{lifestyle_and_appearance.skin_tone}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">accessible</i>
                                    <span>Physical Disability</span>
                                    <h5>{lifestyle_and_appearance.physical_disablity ? 'Yes' : 'No'}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LifestyleAndAppearance;