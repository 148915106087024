import React from 'react';
import SignIn from '../components/Auth/SignIn';

const Login = () => {
    return (
        <div>
            <SignIn/>
        </div>
    );
};

export default Login;