import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import SmartRadioSelectPure from '../InputFields/SmartRadioSelectPure'
import { updatePrivacy } from '../../reducers/privacy'

const privacyHandler = ({ privacyData: { id, privacyType , label}, updatePrivacy}) => {
    return (
        <div>
            <Field
                name={privacyType}
                type="radio"
                component={SmartRadioSelectPure}
                label={label}
                className="account-type-radio"
                isOnclick={true}
                options={[
                    {
                        value: 'PUBLIC',
                        label: 'Public',
                        icon: 'icon-feather-globe',
                    },
                    {
                        value: 'ONLY_ACCEPTED_PROFILES',
                        label: 'Only Accepted Profiles',
                        icon: 'icon-feather-users',
                    },
                    {
                        value: 'ONLY_PAID_PROFILES',
                        label: 'Only Paid Profiles',
                        icon: 'icon-feather-users',
                    },
                    {
                        value: 'ONLY_ME',
                        label: 'Only Me',
                        icon: 'icon-feather-user',
                    },
                ]}
            />

            <div align="right">
                <button
                    className="popup-with-zoom-anim button button-sliding-icon"
                    onClick={() => updatePrivacy({ id, privacyType })}
                >
                    Save Changes <i class="icon-feather-save"></i>
                </button>
            </div>
        </div>
    )
}

const InitializeFromStateForm = reduxForm({
    form: 'data_privacy',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
})(privacyHandler)


const mapStateToProps = state => ({
    initialValues: state.profile.userProfile,
})

const mapDispatchToProps = {
    updatePrivacy
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitializeFromStateForm)
