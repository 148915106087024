import React from 'react'
import { Field } from 'redux-form';

export const SmartSelect = (props) => {
    const {
        input: { name },
        label,
        type,
        className,
        options,
        help,
        info
    } = props;
    return (
        <div className="col-xl-4">
            <div className="submit-field">
                <h5>
                    {label}
                    {help && (
                        <span className="tool" data-tip={`${info}`}>
                            <i className="help-icon" data-tippy-placement="right" />
                        </span>
                    )}
                </h5>
                <div className="account-type">
                    {options.map((radioData, index) => {
                        return (
                            <div key={index}>
                                  <Field
                                    id={radioData.value}
                                    component="input"
                                    name={name}
                                    type={type}
                                    value={radioData.value}
                                    className={className}
                                />
                                <label htmlFor={radioData.value} className="ripple-effect-dark">
                                    <i className={radioData.icon} /> {radioData.label}
                                </label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default SmartSelect
