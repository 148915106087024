import React, { Fragment } from 'react'

const EducationAndCareer = ({ education_and_profession }) => {
    return (
        <Fragment>
            <div className="col-xl-4 col-lg-4">
                <div className="sidebar-widget">
                    <div className="job-overview">
                        <div className="job-overview-headline">Education and career</div>
                        <div className="job-overview-inner">
                            <ul>
                                <li>
                                    <i className="material-icons">school</i>
                                    <span>Education Field</span>
                                    <h5>{education_and_profession.education_field}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">receipt</i>
                                    <span>Education Level</span>
                                    <h5>{education_and_profession.education_level}</h5>
                                </li>
                                <li>
                                    <i className="material-icons">work</i>
                                    <span>Occupation</span>
                                    <h5>{education_and_profession.occupation}</h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EducationAndCareer
