import React, { useState, useCallback, Fragment } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getProfilePhotos } from "../../reducers/photo";
import { connect } from "react-redux";

    
const PhotoGallery = ({ getProfilePhotos, profile, profilePhotos , fix}) =>   {
    
    const initialPhotos = [
        {
          src: profile.secure_profile_photo !== "null" ? profile.secure_profile_photo  : profile.avatar,
          width: 4,
          height: 3
        }
    ];

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <Fragment>
            <Gallery photos={initialPhotos} onClick={async (eve, values) => {
                    await getProfilePhotos(profile);

                    setTimeout(() => {
                        openLightbox(eve, values);
                    }, 200)
            }} />

            {profilePhotos.photos.length !== 0 &&
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={profilePhotos.photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            }
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    profilePhotos: state.photo.allProfilePhotos
})

const mapDispatchToProps = {
    getProfilePhotos
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PhotoGallery)